import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Typography, Grid, Button} from '@material-ui/core';
import background_promo_dark from './../../../components/img/background_promo_dark.svg';
import background_promo_light from './../../../components/img/background_promo_light.svg';
import FadeIn from 'react-fade-in';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRadius: 20,
    padding: 40,
    marginTop: 5,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    boxShadow: '#7979793b 0px 0px 150px',
  },
  wrapperBackground: {
    zIndex: -1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: theme.name === 'darkTheme' ? darkBg : whiteBg,
    borderRadius: 20,
    animation: '$bgAnimation 30s ease infinite',
    marginBottom: 10,
    marginTop: 5,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
  },
  circle: {
    position: 'relative',
    height: 18,
    //marginRight: 10,
    display: 'block',
    margin: '0 auto',
  },
  promoImg: {
    height: 320,
  },
  '@keyframes bgAnimation': {
    '0%': {
      backgroundSize: '80%',
      backgroundColor: theme.name === 'darkTheme' ? '#111' : '#FFF',
    },
    '50%': {
      backgroundSize: '100%',
      backgroundColor: theme.name === 'darkTheme' ? '#111' : '#FFF',
    },
    '100%': {
      backgroundSize: '80%',
      backgroundColor: theme.name === 'darkTheme' ? '#111' : '#FFF',
    },
  },
}));

const darkBg = `linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, #111 350px), url(${background_promo_dark}) top`;
const whiteBg = `linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, #FFF 350px), url(${background_promo_light}) top`;
const circleImg = `https://setupad.com/wp-content/themes/setupad/assets/img/landing/check-circle-out-frame.svg`;

const points = [
  {
    title: 'IAB TCF v2.0 Compliance',
    description: 'Align with industry standards for data privacy.',
  },
  {
    title: 'Customizable Design',
    description: "Sleek and flexible design to match your website's look and feel.",
  },
  {
    title: 'Free for Setupad Clients',
    description: 'Enjoy all the features at no extra cost.',
  },
  {
    title: 'Multiple Language Support',
    description: 'Cater to a global audience with ease.',
  },
  {
    title: 'User-Friendly Interface',
    description: 'An intuitive and easy-to-understand solution for you and your users.',
  },
  {
    title: 'Enhanced Data Storage',
    description:
      'Most data is stored in Local Storage instead of cookies for better performance and compliance.',
  },
  {
    title: 'Empower Your Users',
    description:
      "Setupad's CMP allows end-users to easily choose between IAB Purposes and Vendors before submitting their consent, providing them with control and transparency.",
  },
];

export default function Promo({domain, setConfigurationOpen}) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid
      className={classes.wrapper}
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      style={{position: 'relative'}}
    >
      <div className={classes.wrapperBackground} />
      <Grid item xs={8}>
        <Typography variant="h5" gutterBottom style={{fontWeight: 'bold'}}>
          Ensure Privacy and Compliance with Setupad CMP
        </Typography>
        <Typography variant="body" display="block" gutterBottom style={{marginBottom: 15}}>
          Setupad's Consent Management Platform (CMP) is Google-approved and fully compliant with
          IAB's TCF v2.0, ensuring that user consent is collected simply and transparently.
        </Typography>

        {points.map((point, index) => {
          let delay = 200 * (1 + index);
          return (
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={1} key={index}>
                <FadeIn delay={delay}>
                  <img
                    className={classes.circle}
                    height={26}
                    width={26}
                    alt={'check-circle'}
                    src={circleImg}
                  />
                </FadeIn>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body"
                  gutterBottom
                  display={'inline'}
                  style={{fontWeight: 'bold'}}
                >
                  {point.title}
                </Typography>
                <Typography variant={'body2'} display={'block'} color={'textSecondary'}>
                  {point.description}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12} style={{paddingTop: 25}}>
          <Button
            variant={'contained'}
            color={'primary'}
            style={{
              marginRight: 10,
            }}
            startIcon={<SettingsIcon />}
            onClick={() => setConfigurationOpen(true)}
          >
            Configure CMP for {domain}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <img
          className={classes.promoImg}
          src={
            'https://setupad.com/wp-content/themes/setupad/assets/img/landing/gdpr-illustration.png'
          }
          alt="Setupad CMP"
        />
      </Grid>
    </Grid>
  );
}
