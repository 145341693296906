import React from 'react';
import ReactGA from 'react-ga4';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {Alert} from '@material-ui/lab';
import {Tooltip, CircularProgress} from '@material-ui/core';
import {CustomEntries} from './adstxt/CustomEntries';
import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import useStore from '../../store/store';

const useStyles = makeStyles(theme => {
  return {
    tooltip: {
      maxWidth: 'none',
      padding: theme.spacing(2),
    },
    countWrapper: {
      background: theme.palette.background.default,
      borderRadius: 4,
      padding: theme.spacing(0.8),
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      textAlign: 'center',
      marginRight: 5,
    },
    domainFavoriteIcon: {
      height: 20,
      width: 20,
      position: 'relative',
      backgroundColor: 'white',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '80%',
      borderRadius: 4,
      display: 'inline-block',
      marginRight: 8,
    },
  };
});

export function AdsListItemSelfServe({domain, checkedDomain, classes, open, setOpen}) {
  const currentAdsTXT = checkedDomain?.currentAdsTXT?.rows ?? null;
  const styles = useStyles();
  const selectedAdsTXTDomain = useStore(state => state.selectedAdsTXTDomain);
  const {redirectStatus} = checkedDomain ?? {};

  return (
    <>
      <ListItem
        disableRipple
        style={{borderBottom: '1px solid #7979793b', minHeight: 65}}
        button
        selected={selectedAdsTXTDomain === domain.id}
      >
        <ListItemIcon>
          <div
            className={styles.domainFavoriteIcon}
            style={{
              backgroundImage: `url(https://www.google.com/s2/favicons?domain=https://${domain.title}&sz=256)`,
            }}
          />
        </ListItemIcon>

        <ListItemText
          primary={
            <>
              {domain.title}/ads.txt
              {checkedDomain
                ? checkedDomain?.currentAdsTXT?.success
                  ? ''
                  : ` - ads.txt not found`
                : ''}
              {currentAdsTXT && (
                <>
                  {' '}
                  - <span className={styles.countWrapper}>{currentAdsTXT?.length}</span>
                  {currentAdsTXT?.length === 1 ? 'line' : 'lines'} found
                </>
              )}
            </>
          }
        />
        {checkedDomain && (
          <>
            <Tooltip arrow interactive title={redirectStatus.tooltip}>
              <Alert
                style={{padding: '0px 16px', marginRight: 10}}
                icon={redirectStatus.icon}
                severity={redirectStatus.alert}
              >
                <Typography>{redirectStatus.message}</Typography>
              </Alert>
            </Tooltip>
          </>
        )}

        {checkedDomain && (
          <CustomEntries
            domain={domain}
            customEntries={checkedDomain?.requiredAdstXT?.customEntries}
            isSelfServe={true}
            currentAdsTXT={currentAdsTXT}
            redirectStatus={redirectStatus}
          />
        )}

        <IconButton
          onClick={
            currentAdsTXT
              ? () => {
                  // Todo: this closes custom entries backdrop
                  setOpen(open === domain.id ? null : domain.id);
                  ReactGA.event({
                    category: 'Ads.txt',
                    action: 'click',
                    label: `expand checked domain`,
                    value: domain.id,
                  });
                }
              : null
          }
        >
          {open === domain ? (
            <ExpandLess />
          ) : currentAdsTXT ? (
            <ExpandMore />
          ) : checkedDomain ? (
            <div style={{width: 20}} />
          ) : (
            <CircularProgress size={22} />
          )}
        </IconButton>
      </ListItem>
      {checkedDomain ? (
        <Collapse
          in={open === domain.id}
          timeout={0}
          classes={{
            container: classes.collapseContainer,
          }}
        >
          {
            // In accordion
          }
          <Grid container>
            <Grid container style={{fontFamily: 'monospace'}} spacing={1} justify="flex-end">
              {
                // Line numbers
              }
              <Grid item xs={1}>
                <Paper elevation={0}>
                  {currentAdsTXT
                    ?.filter(line => line !== '')
                    .map((line, i) => {
                      return (
                        <Grid
                          key={i}
                          item
                          xs={12}
                          style={{
                            textAlign: 'right',
                            marginRight: 5,
                            marginTop: 2,
                          }}
                        >
                          <Typography variant={'caption'}>{i + 1}</Typography>
                        </Grid>
                      );
                    })}
                </Paper>
              </Grid>
              {
                // Domain names
              }
              <Grid item xs={11}>
                {currentAdsTXT
                  ?.filter(line => line !== '')
                  .map((line, i) => {
                    return (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        style={{
                          marginLeft: 5,
                          marginTop: 2,
                        }}
                      >
                        <Typography noWrap variant={'caption'}>
                          {line}
                        </Typography>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      ) : null}
    </>
  );
}
