import React, {useEffect, useState, useCallback} from 'react';
import AmCharts from '@amcharts/amcharts3-react/index';
import {useTheme} from '@material-ui/core/styles';
import moment from 'moment';
import millify from 'millify';
import useConsumedCreditsStart from '../../hooks/useConsumedCreditsStart';
const AccountsDetailsTable = React.memo(props => {
  const theme = useTheme();
  const [chart, setChart] = useState(null);
  const consumedCreditsStart = useConsumedCreditsStart();

  const drawSelfServeGuideLine = useCallback(() => {
    if (props.hideGuideLine) {
      return;
    }
    if (consumedCreditsStart?.success && chart) {
      // Before adding guides, remove all existing guides.
      // There's some bug in AmCharts where it doesn't remove all guides on the first try, so calling twice.
      chart.chart.categoryAxis.guides.map(guide => chart.chart.categoryAxis.removeGuide(guide));
      chart.chart.categoryAxis.guides.map(guide => chart.chart.categoryAxis.removeGuide(guide));

      // If there's no startDate, then it means the account has not started using credits yet, push to end of the guide a
      if (!consumedCreditsStart.startDate) {
        consumedCreditsStart.startDate = moment().add(3, 'days');
      }

      let labelGuide = new AmCharts.Guide();
      labelGuide.date = moment
        .utc(consumedCreditsStart.startDate)
        .tz(consumedCreditsStart.timeZone)
        .format('YYYY-MM-DD');
      labelGuide.fillAlpha = 0;
      labelGuide.lineAlpha = 0;
      labelGuide.lineAlpha = 0;
      labelGuide.boldLabel = true;
      labelGuide.color = theme.palette.text.primary;
      labelGuide.label = 'Setupad code launch';
      labelGuide.position = 'top';
      labelGuide.inside = true;
      labelGuide.labelRotation = 90;
      chart.chart.categoryAxis.addGuide(labelGuide);

      let guide = new AmCharts.Guide();
      guide.date = moment
        .utc(chart.chart.chartData[0].category)
        .tz(consumedCreditsStart.timeZone)
        .format('YYYY-MM-DD');
      guide.toDate = moment
        .utc(consumedCreditsStart.startDate)
        .tz(consumedCreditsStart.timeZone)
        .format('YYYY-MM-DD');
      guide.fillAlpha = 1;
      guide.fillColor = theme.palette.graphColors.historicalBackground;
      guide.lineAlpha = 1;
      guide.boldLabel = true;
      guide.dashLength = 5;
      guide.color = theme.palette.text.primary;
      guide.lineColor = theme.palette.graphColors.themeLightGray;

      chart.chart.categoryAxis.addGuide(guide);

      chart.chart.validateData();
    }
  }, [consumedCreditsStart, chart, theme]);

  useEffect(() => drawSelfServeGuideLine(), [consumedCreditsStart, chart, drawSelfServeGuideLine]);

  const eurMetrics = ['Rev', 'Rev Refresh', 'eCPM', 'rCPM', 'uRPM', 'sRPM', 'pvRPM'];

  const percentMetrics = ['Viewability', 'refreshRate', 'CTR'];

  const colorMap = {
    Rev: 'revenue',
    'Rev Refresh': 'refreshRevenue',
    eCPM: 'eCPM',
    rCPM: 'rCPM',
    CTR: 'CTR',
    uRPM: 'uRPM',
    sRPM: 'sRPM',
    pvRPM: 'pvRPM',
    Viewability: 'viewability',
  };

  const metricsOrder = [
    'All Imp',
    'Paid Imp',
    'fillRate',
    'eCPM',
    'rCPM',
    'Rev',
    'Rev Refresh',
    'refreshRate',
    'eCPM Refresh',
    'Viewability',
    'CTR',
    'sRPM',
    'uRPM',
    'pvRPM',
  ];

  const getMaxRefreshRateValue = Math.max.apply(
    Math,
    props.publisherStats.map(function(o) {
      return o.refreshRate;
    })
  );

  const balloonFunction = (item, graph) => {
    let html =
      '<table style="border-radius: 4px; padding: 8px; background: rgba(0,0,0,.7); border: none!important; color: #c7c7c7; text-align: left; font-size: 13px;  line-height: 15px; -webkit-font-smoothing: auto; font-family: Inter; width: 100%; margin-top: 7px;">';
    html +=
      '<tr style="font-weight: bold; font-size: 11px;"><td>' +
      moment(item.dataContext['date']).format('YYYY MMMM D') +
      '</td></tr>';

    metricsOrder.map(metric => {
      let metricInGraph = graph.chart.graphs.filter(m => m.valueField === metric)[0];

      // Add Fill rate to tooltip even though its not in the graph
      if (
        metric === 'fillRate' &&
        props.activeMetrics.includes('fillRate') &&
        item.dataContext['fillRate']
      ) {
        html +=
          `<tr><td style="padding-top: 5px; padding-right: 15px;">Fill rate </td></td>` +
          `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(
            item.dataContext['fillRate']
          )}%</td></tr>`;
        return;
      }

      // Add Fill rate to tooltip even though its not in the graph
      if (
        metric === 'eCPM Refresh' &&
        props.activeMetrics.includes('eCPM Refresh') &&
        item.dataContext['eCPM Refresh']
      ) {
        html +=
          `<tr><td style="padding-top: 5px; padding-right: 15px;">Refresh eCPM</td></td>` +
          `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(
            item.dataContext['eCPM Refresh']
          )}€</td></tr>`;
        return;
      }

      if (metricInGraph && !metricInGraph.hidden) {
        html +=
          `<tr><td style="padding-top: 5px; padding-right: 15px; ${
            colorMap.hasOwnProperty(metric)
              ? `color: ${theme.palette.graphColors[colorMap[metric]]}`
              : ``
          }">${metricInGraph.legendValueText} ${
            metric === 'pvRPM' ? `(${millify(item.dataContext['pageviews'])})` : ``
          } ${metric === 'uRPM' ? `(${millify(item.dataContext['users'])})` : ``} ${
            metric === 'sRPM' ? `(${millify(item.dataContext['visits'])})` : ``
          } </td></td>` +
          `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(
            item.dataContext[metric]
          )} ${
            eurMetrics.includes(metric) ? `€` : percentMetrics.includes(metric) ? `%` : ``
          }</td></tr>`;

        // if (metric === 'uRPM') {
        //     html += `<tr><td style="padding-top: 5px; padding-right: 15px; ${colorMap.hasOwnProperty(metric) ? `color: ${theme.palette.graphColors[colorMap[metric]]}` : ``}">Users</td></td>` +
        //         `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(item.dataContext['users'])}</td></tr>`
        // }
        //
        // if (metric === 'sRPM') {
        //     html += `<tr><td style="padding-top: 5px; padding-right: 15px; ${colorMap.hasOwnProperty(metric) ? `color: ${theme.palette.graphColors[colorMap[metric]]}` : ``}">Sessions</td></td>` +
        //         `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(item.dataContext['visits'])}</td></tr>`
        // }
        //
        // if (metric === 'pvRPM') {
        //     html += `<tr><td style="padding-top: 5px; padding-right: 15px; ${colorMap.hasOwnProperty(metric) ? `color: ${theme.palette.graphColors[colorMap[metric]]}` : ``}">Pageviews</td></td>` +
        //         `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(item.dataContext['pageviews'])}</td></tr>`
        // }
      }
    });

    html += '</table>';

    return html;
  };

  const balloonSettings = {
    adjustBorderColor: false,
    borderColor: '#e75d3c000',
    fillColor: '#e75d3c000',
    fillAlpha: 0,
    shadowAlpha: 0,
    color: '#fff',
    cornerRadius: 4,
    horizontalPadding: 10,
    verticalPadding: 10,
  };

  const getValueAxis = () => {
    let valueAxis = [
      {
        id: 'v0',
        // title: [].push('Ad Requests').join,
        titleBold: false,
        titleColor: theme.palette.graphColors.themeGray,
        minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
      },
      {
        id: 'v1',
        title: /Rev|Rev Refresh/.test(props.activeMetrics) ? 'Revenue' : '€',
        titleBold: false,
        gridAlpha: 0,
        titleColor: /Rev|Rev Refresh/.test(props.activeMetrics)
          ? theme.palette.graphColors.revenue
          : theme.palette.graphColors.themeGray,
        minVerticalGap: 50,
        color: /Rev|Rev Refresh/.test(props.activeMetrics)
          ? theme.palette.graphColors.revenue
          : theme.palette.graphColors.themeGray,
        axisColor: /Rev|Rev Refresh/.test(props.activeMetrics)
          ? theme.palette.graphColors.revenue
          : theme.palette.graphColors.themeGray,
        offset: /eCPM|rCPM/.test(props.activeMetrics) ? 70 : 0,
        minimum: 0,
        position: 'right',
      },
      {
        id: 'v2',
        title: 'eCPM',
        titleBold: false,
        titleColor: theme.palette.graphColors.eCPM,
        minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.eCPM,
        axisColor: theme.palette.graphColors.eCPM,
        minimum: 0,
        position: 'right',
      },
      {
        id: 'v25',
        // title: 'ADX CTR',
        titleBold: false,
        titleColor: theme.palette.graphColors.CTR,
        minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
        labelsEnabled: false,
      },
      {
        id: 'v4',
        title: '%',
        titleBold: false,
        titleColor: theme.palette.graphColors.themeGray,
        // minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
        // maximum: /refreshRate|Viewability/.test(props.activeMetrics) ? (/CTR/.test(props.activeMetrics) ? 100 : undefined) : undefined,
        maximum: /refreshRate|Viewability/.test(props.activeMetrics)
          ? getMaxRefreshRateValue > 100 && props.activeMetrics.includes('refreshRate')
            ? getMaxRefreshRateValue
            : 100
          : undefined,
        position: 'left',
        offset: /Paid Imp|All Imp/.test(props.activeMetrics) ? 80 : 0,
        labelsEnabled: true,
      },
      {
        id: 'v6',
        //  title: 'Refresh Rate',
        titleBold: false,
        titleColor: theme.palette.graphColors.themeGray,
        minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
        position: 'right',
        offset: 220,
        labelsEnabled: false,
      },
    ];

    if (props.analyticsAdded) {
      valueAxis.push({
        id: 'v5',
        title: 'Euro',
        titleBold: false,
        titleColor: theme.palette.graphColors.pageviews,
        minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.pageviews,
        axisColor: theme.palette.graphColors.pageviews,
        position: 'left',
        offset: 90,
        labelsEnabled: true,
      });
    }

    return valueAxis;
  };

  const getGraphs = () => {
    let graphs = [
      {
        id: 'g0',
        valueAxis: 'v0',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        fillAlphas: 0,
        columnWidth: 0.8,
        lineAlpha: 1,
        lineThickness: 1,
        type: 'column',
        clustered: false,
        legendValueText: 'Requests',
        legendPeriodValueText: 'Requests',
        valueField: 'All Imp',
        hidden: !props.activeMetrics.includes('All Imp'),
        includeHidden: true,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g12',
        type: 'column',
        valueAxis: 'v0',
        clustered: false,
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        lineAlpha: 1,
        lineThickness: 1,
        columnWidth: 0.8,
        legendValueText: 'Impressions',
        legendPeriodValueText: 'Impressions',
        valueField: 'Paid Imp',
        hidden: !props.activeMetrics.includes('Paid Imp'),
        includeHidden: true,
        fillAlphas: 1,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g2',
        type: 'smoothedLine',
        valueAxis: 'v2',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.eCPM,
        lineThickness: 3,
        legendValueText: 'eCPM',
        legendPeriodValueText: 'eCPM',
        valueField: 'eCPM',
        hidden: !props.activeMetrics.includes('eCPM'),
        fillAlphas: theme.palette.type === 'dark' ? 0.2 : 0,
        fillColors: [theme.palette.graphColors.eCPM, 'rgba(0,0,0,0)'],
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g1',
        type: 'smoothedLine',
        valueAxis: 'v1',
        baseAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.revenue,
        lineThickness: 3,
        legendValueText: 'Revenue',
        legendPeriodValueText: 'Revenue',
        valueField: 'Rev',
        hidden: !props.activeMetrics.includes('Rev'),
        fillAlphas: theme.palette.type === 'dark' ? 0.5 : 0,
        fillColors: [theme.palette.graphColors.revenue, 'rgba(0,0,0,0)'],
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
    ];

    if (props.login.refresh_enabled) {
      graphs.push(
        {
          id: 'g20',
          valueAxis: 'v4',
          bullet: 'none',
          useLineColorForBulletBorder: true,
          lineColor: theme.palette.graphColors.themeAlmostWhite,
          columnWidth: 1,
          lineAlphas: 1,
          lineThickness: 3,
          type: 'smoothedLine',
          legendValueText: 'Smart Refresh Rate',
          legendPeriodValueText: 'Smart Refresh Rate',
          valueField: 'refreshRate',
          hidden: !props.activeMetrics.includes('refreshRate'),
          // fillAlphas: theme.palette.type === 'dark' ? 0.2 : 0.1,
          // fillColors: [theme.palette.graphColors.themeAlmostWhite, "rgba(0,0,0,0)"],
          dashLength: 4,
          balloon: balloonSettings,
          balloonFunction: balloonFunction,
        },
        // {
        //     id: 'g4',
        //     type: "smoothedLine",
        //     valueAxis: 'v2',
        //     bullet: 'none',
        //     useLineColorForBulletBorder: true,
        //     lineColor: theme.palette.graphColors.refreshECPM,
        //     lineThickness: 3,
        //     legendValueText: 'Refresh eCPM',
        //     legendPeriodValueText: 'Refresh eCPM',
        //     valueField: 'eCPM Refresh',
        //     hidden: !props.activeMetrics.includes('eCPM Refresh'),
        //     fillAlphas: 0,
        //     balloon: balloonSettings,
        //     balloonFunction: balloonFunction,
        // },
        // {
        //     id: 'g5',
        //     type: "smoothedLine",
        //     dashLength: 4,
        //     valueAxis: 'v4',
        //     bullet: 'none',
        //     useLineColorForBulletBorder: true,
        //     lineColor: theme.palette.graphColors.refreshRCPM,
        //     lineThickness: 3,
        //     legendValueText: 'Refresh rCPM',
        //     legendPeriodValueText: 'Refresh rCPM',
        //     valueField: 'eCPM Refresh',
        //     fillAlphas: 0,
        //     hidden: !props.activeMetrics.includes('eCPM Refresh'),
        //     balloon: balloonSettings,
        //     balloonFunction: balloonFunction,
        // },
        {
          id: 'g25',
          type: 'smoothedLine',
          valueAxis: 'v1',
          baseAxis: 'v1',
          bullet: 'none',
          useLineColorForBulletBorder: true,
          lineColor: theme.palette.graphColors.refreshRevenue,
          lineThickness: 3,
          //dashLength: 4,
          legendValueText: 'Refresh Revenue',
          legendPeriodValueText: 'Refresh Revenue',
          valueField: 'Rev Refresh',
          fillAlphas: 0,
          hidden: !props.activeMetrics.includes('Rev Refresh'),
          balloon: balloonSettings,
          balloonFunction: balloonFunction,
        }
      );
    }

    if (props.login.rCPM_enabled) {
      graphs.push({
        id: 'g3',
        type: 'smoothedLine',
        //dashLength: 4,
        valueAxis: 'v2',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.rCPM,
        lineThickness: 3,
        legendValueText: 'rCPM',
        legendPeriodValueText: 'rCPM',
        valueField: 'rCPM',
        hidden: !props.activeMetrics.includes('rCPM'),
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      });
    }

    if (props.login.ctr_enabled) {
      graphs.push({
        id: 'g6',
        type: 'smoothedLine',
        valueAxis: 'v4',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.CTR,
        lineThickness: 3,
        dashLength: 5,
        legendValueText: 'CTR',
        legendPeriodValueText: 'CTR',
        valueField: 'CTR',
        fillAlphas: 0,
        hidden: !props.activeMetrics.includes('CTR'),
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      });
    }

    if (props.login.viewability_enabled) {
      graphs.push({
        id: 'g7',
        type: 'smoothedLine',
        valueAxis: 'v4',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.viewability,
        lineThickness: 3,
        legendValueText: 'Viewability',
        legendPeriodValueText: 'Viewability',
        valueField: 'Viewability',
        hidden: !props.activeMetrics.includes('Viewability'),
        dashLength: 5,
        // fillAlphas: theme.palette.type === 'dark' ? 0.4 : 0,
        // fillColors: [theme.palette.graphColors.viewability, "rgba(0,0,0,0)"],
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
        // useLineColorForBulletBorder: true,
        // lineColor: theme.palette.graphColors.themeAlmostWhite,
        // lineThickness: 1,
        // legendValueText: 'Viewability',
        // legendPeriodValueText: 'Viewability',
        // valueField: 'Viewability',
        // pattern: {
        //     "url": "https://client.setupad.com/pattern.png",
        //     "width": 4,
        //     "height": 4
        // },
        // fillAlphas: .5,
        // hidden: !props.activeMetrics.includes('Viewability'),
        // balloon: balloonSettings,
        // balloonFunction: balloonFunction,
      });
    }

    if (props.analyticsAdded) {
      if (props.analyticsProperty) {
        graphs.push({
          id: 'g9',
          valueAxis: 'v1',
          bullet: 'none',
          useLineColorForBulletBorder: true,
          lineColor: theme.palette.graphColors.uRPM,
          lineAlpha: 1,
          lineThickness: 3,
          type: 'smoothedLine',
          legendValueText: 'uRPM',
          legendPeriodValueText: 'uRPM',
          valueField: 'uRPM',
          hidden: !props.activeMetrics.includes('uRPM'),
          balloon: balloonSettings,
          balloonFunction: balloonFunction,
        });
      }

      graphs.push({
        id: 'g11',
        valueAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.sRPM,
        lineAlpha: 1,
        lineThickness: 3,
        type: 'smoothedLine',
        legendValueText: 'sRPM',
        legendPeriodValueText: 'sRPM',
        valueField: 'sRPM',
        fillAlphas: 0,
        hidden: !props.activeMetrics.includes('sRPM'),
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      });

      graphs.push({
        id: 'g8',
        valueAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.pvRPM,
        columnWidth: 1,
        lineAlpha: 1,
        lineThickness: 3,
        type: 'smoothedLine',
        clustered: false,
        legendValueText: 'pvRPM',
        legendPeriodValueText: 'pvRPM',
        valueField: 'pvRPM',
        fillAlphas: 0,
        hidden: !props.activeMetrics.includes('pvRPM'),
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      });
    }

    return graphs;
  };

  return (
    <div style={{maxWidth: '98%'}}>
      <AmCharts.React
        ref={props.chartRef}
        style={{
          height: '420px',
          // display: 'flex'
          width: '100%',
          transition: 'none!important',
        }}
        options={{
          columnSpacing: 120,
          zoomOutOnDataUpdate: true,
          type: 'serial',
          theme: 'light',
          marginTop: 15,
          marginRight: 15,
          responsive: {
            enabled: true,
          },
          legend: {
            enabled: false,
            combineLegend: true,
            color: theme.palette.graphColors.themeGray,
            markerType: 'circle',
            divId: 'legend',
            valueAlign: 'left',
            valueWidth: 100,
          },
          dataProvider: props.publisherStats,
          categoryField: 'date',
          categoryAxis: {
            // autoGridCount: false,
            // gridCount: 9999,
            parseDates: true,
            gridAlpha: 0,
            // gridColor: theme.palette.graphColors.themeLightGrayOpaque,
            color: theme.palette.graphColors.themeGray,
            axisColor: theme.palette.graphColors.themeGray,
          },
          valueAxes: getValueAxis(),
          graphs: getGraphs(),
          chartCursor: {
            oneBalloonOnly: true,
            cursorColor: theme.palette.graphColors.themeGray,
            color: 'rgba(0,0,0,0)',
            categoryBalloonColor: 'rgba(0,0,0,0)',
            categoryBalloonDateFormat: 'YYYY-MM-DD',
            categoryBalloonAlpha: 1,
            cursorAlpha: 1,
            valueLineEnabled: true,
            valueLineBalloonEnabled: true,
            valueLineAlpha: 1,
            zoomable: false,
          },
          chartScrollbar: {
            enabled: false,
            backgroundAlpha: 0,
            dragIcon: 'dragIconRectSmall',
            graph: 'g1',
            graphFillAlpha: 0,
            graphLineAlpha: 0,
            scrollbarHeight: 30,
            color: theme.palette.primary.main,
            graphLineColor: theme.palette.graphColors.themeAlmostWhite,
            selectedGraphFillColor: theme.palette.graphColors.themeAlmostWhite,
            selectedGraphFillAlpha: 0,
            graphType: 'step',
            selectedBackgroundAlpha: 0.1,
            selectedBackgroundColor: theme.palette.graphColors.themeAlmostWhite,
          },
          dataDateFormat: 'YYYY-MM-DD',
          creditsPosition: 'bottom-right',
          zoomOutButton: {
            backgroundColor: '#e0e0e0',
            backgroundAlpha: 0,
          },
          zoomOutText: '',
          listeners: [
            {
              event: 'zoomed',
              method: event => {
                props.handleZoom(event);
              },
            },
            {
              event: 'rendered',
              method: () => {
                if (props.chartRef && props.chartRef.current && props.chartRef.current.state) {
                  props.chartRef.current.state.chart.zoomToIndexes(0, props.publisherStats.length);
                }
              },
            },
            {
              event: 'changed',
              method: function(e) {
                /**
                 * Log cursor's last known position
                 */

                e.chart.lastCursorPosition = e.index;
              },
            },
            {
              event: 'init',
              method: function(e) {
                // Draw a guide line for Self-Serve accounts
                setChart(e);
                // Experimental domain history
                // if (props.domainHistory && props.domainHistory?.success !== 'false') {
                //   props.domainHistory
                //     //.filter(data => data.entityValue === 'domain')
                //     .map(data => {
                //       let guide = new AmCharts.Guide();
                //       guide.date = data.timestamp;
                //       guide.lineAlpha = 1;
                //       guide.boldLabel = true;
                //       guide.dashLength = 7;
                //       guide.color = theme.palette.graphColors.themeGray;
                //       guide.lineColor = theme.palette.graphColors.themeLightGray;
                //       guide.label = `${data.attGroupName} - ${data.attName}`;
                //       guide.position = 'top';
                //       guide.inside = true;
                //       guide.labelRotation = 90;
                //       e.chart.categoryAxis.addGuide(guide);
                //       e.chart.validateData();
                //     });
                // }

                /**
                 * Pre-zoom
                 */
                //  e.chart.zoomToIndexes( e.chart.dataProvider.length - 40, e.chart.dataProvider.length - 1 );

                /**
                 * Add click event on the plot area
                 */
                // e.chart.chartDiv.addEventListener('click', () => {
                //   // we track cursor's last known position by "changed" event
                //   if (e.chart.lastCursorPosition !== undefined) {
                //     // get date of the last known cursor position
                //     var date =
                //       e.chart.dataProvider[e.chart.lastCursorPosition][e.chart.categoryField];

                //     // require user to enter annotation text
                //     var text = window.prompt('Enter annotation', '');

                //     // create a new guide
                //     var guide = new AmCharts.Guide();
                //     guide.date = date;
                //     guide.lineAlpha = 1;
                //     guide.boldLabel = true;
                //     guide.dashLength = 7;
                //     guide.color = theme.palette.graphColors.themeLightGray;
                //     guide.lineColor = theme.palette.graphColors.themeLightGray;
                //     guide.label = text;
                //     //   guide.balloonText = text;
                //     guide.position = 'top';
                //     guide.inside = true;
                //     guide.labelRotation = 90;
                //     e.chart.categoryAxis.addGuide(guide);
                //     e.chart.validateData();
                //   }
                // });
              },
            },
          ],
        }}
      />
    </div>
  );
});

export default React.memo(AccountsDetailsTable);
