const newTicketData = {
  requestType: {
    icon:
      'https://stpd.atlassian.net/rest/api/2/universal_avatar/view/type/SD_REQTYPE/avatar/10550?size=large',
    title: 'Insert tags',
    description: 'Insert tags into a page',
    helperText: 'Provide tags to be inserted, site, login credentials, requirements',
    id: '31',
    issueType: {
      id: 10015,
      name: 'Support: Task',
      icon:
        'https://stpd.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10321?size=medium',
    },
  },
  domainName: null,
  title: '',
  summary: '',
  files: [],
};

export const ticketsSlice = set => ({
  tickets: null,
  newTicketPanelOpen: false,
  ticketSubmitLoading: false,
  ticketSubmitError: null,
  newTicketData,
  monthlyTicketLimit: 4,
  expandedTicketId: null,

  resetNewTicketData: () => set({newTicketData: newTicketData}),
});
