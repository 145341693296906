import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import lscache from 'lscache';
import Logo from './../components/img/setupad_client_final.svg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles/index';
import {APICall} from '../api/api';
import FadeIn from 'react-fade-in';
import useStore from '../store/store';

const Quote = require('inspirational-quotes');

const useStyles = makeStyles(theme => ({
  loader: {
    position: 'absolute',
    zIndex: 99999,
    top: '0',
    width: '100%',
    left: 0,
  },
  additionalButtons: {
    margin: theme.spacing(1, 0, 1),
  },
  fabProgress: {
    position: 'absolute',
    top: -5,
    left: -5,
    zIndex: 1,
  },
}));

function LoginWB(props) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState(Quote.getQuote());
  const setState = useStore(state => state.setState);

  useEffect(() => {
    lscache.remove('auth');
    setState('auth', null);

    setTimeout(() => {
      //Fake timeout for a time to read quote, yolo!
      onLoad();
    }, 500);
  }, []);

  let onLoad = async () => {
    try {
      let response = await APICall({
        endpoint: 'getAuth',
        params: props.match.params.accountId,
        options: {
          credentials: 'include',
        },
      });
      if (response.token) {
        // Use Local Storage to store auth data
        lscache.set('auth', {...response, wb_auth: true});
        props.postAuth();
        props.history.push('/home');
      } else {
        setError(response.error ? response.error : 'Login to Workbench first.');
        setQuote(null);
      }
      setLoading(false);
    } catch (error) {
      setError(`Error: ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LinearProgress variant="query" className={classes.loader} position={'fixed'} />}

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '80vh'}}
      >
        <Container component="main" maxWidth="xs" minHeight="100%" style={{padding: 50}}>
          <div>
            <img
              src={
                !lscache.get('theme') || lscache.get('theme') === 'dark'
                  ? 'https://setupad.com/wp-content/themes/setupad/assets/img/new_company_logo.svg'
                  : Logo
              }
              style={{width: '100%'}}
            />

            {quote && (
              <FadeIn>
                <Box mt={6}>
                  <Typography variant="h5" align={'center'}>
                    {quote.text}
                  </Typography>
                  <br />
                  <Typography variant={'h4'} color={'textSecondary'} align={'right'}>
                    ❞
                  </Typography>
                  <Typography align="right">{quote.author}</Typography>
                </Box>
              </FadeIn>
            )}
            {error && (
              <Box mt={6}>
                <Typography variant="h5" align="center">
                  {error}
                </Typography>
              </Box>
            )}
          </div>
        </Container>
      </Grid>
    </>
  );
}

export default LoginWB;
