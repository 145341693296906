import {useEffect, useCallback} from 'react';
import moment from 'moment';
import useStore from '../store/store';
import {APICall} from '../api/api';

const useRealtimeData = () => {
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);
  const selection = useStore(state => state.selection);
  const realtimeDataRaw = useStore(state => state.realtimeDataRaw);
  const realtimeData = useStore(state => state.realtimeData);
  const timezone = useStore(state => state.timezone);

  // Fetches realtime raw data from API
  const fetchRealtimeData = useCallback(async () => {
    try {
      // Reset raw and structured data
      setState('realtimeDataRaw', null);
      setState('realtimeData', null);

      let realtimeDataRequest = await APICall({
        endpoint: 'getRealtimeData',
        options: {
          method: 'POST',
          body: JSON.stringify({
            selection: selection,
          }),
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });
      setState('realtimeDataRaw', realtimeDataRequest);
    } catch (error) {
      setState('realtimeData', {
        success: false,
        error: error.message,
      });
    }
  }, [auth, setState, selection]);

  // Structures raw data into structured data by timezone
  const structureRawData = useCallback(async () => {
    // For the current frontend, we need to have date hour index and separation by today and yesterday

    // Add missing fields (isYesterday, isToday, hour), validate, fix data
    const realtimeDataByTimezone = realtimeDataRaw.data.map(row => {
      const datetimeInTimezone = moment.utc(row.datetime).tz(timezone);
      const currentTimeInTimezone = moment().tz(timezone);
      return {
        ...row,
        isToday: datetimeInTimezone.isSame(currentTimeInTimezone, 'day'),
        isYesterday: datetimeInTimezone.isSame(currentTimeInTimezone.subtract(1, 'day'), 'day'),
        hour: datetimeInTimezone.hour(),
        dateRange: `${moment
          .utc(row.datetime)
          .tz(timezone)
          .format('HH.mm')} - ${moment
          .utc(row.datetime)
          .tz(timezone)
          .add('1', 'hour')
          .format('HH.mm')}`,
        rev: Math.round(row.rev * 100) / 100,
        viewability: row.viewability ? Math.round(row.viewability * 100) / 100 : 0,
        ctr: row.ctr ? Math.round(row.ctr * 100) / 100 : 0,
        imp: row.imp ? parseInt(row.imp) : 0,
      };
    });

    // Group data by today and yesterday in the selected timezone
    const groupedData = {
      today: realtimeDataByTimezone.filter(row => row.isToday),
      yesterday: realtimeDataByTimezone.filter(row => row.isYesterday),
    };

    if (groupedData.yesterday.length) {
      // Determine the difference between the last data point and the current time in the selected timezone
      const lastDataPoint = realtimeDataRaw.data[realtimeDataRaw.data.length - 1];
      const dataDelayedByHours = moment
        .duration(
          moment()
            .tz(timezone)
            .diff(moment.utc(lastDataPoint.datetime).tz(timezone))
        )
        .asHours();

      setState('realtimeData', {...groupedData, dataDelayedByHours});
    } else {
      setState('realtimeData', {
        success: false,
        error: 'No data collected',
      });
    }
  }, [realtimeDataRaw, timezone, setState]);

  // Fetch raw data in UTC when selection or auth changes
  useEffect(() => {
    fetchRealtimeData();
  }, [auth, selection, fetchRealtimeData]);

  // On raw data change, convert to timezone
  useEffect(() => {
    // If raw data is set successfully and is not empty, convert to structured data
    if (realtimeDataRaw?.success && realtimeDataRaw?.data?.length > 0) {
      structureRawData();
    } else {
      // Else check if data is loaded at all
      if (realtimeDataRaw) {
        setState('realtimeData', {
          success: false,
          error: realtimeDataRaw?.error ? 'Server error, try again later' : 'No data collected',
        });
      }
    }
  }, [timezone, realtimeDataRaw, setState, structureRawData]);

  return {realtimeData};
};

export default useRealtimeData;
