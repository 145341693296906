const indicatorStyles = theme => ({
  revIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    backgroundColor: theme.palette.graphColors.revenue,
  },
  revRefreshIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    borderTop: `3px solid ${theme.palette.graphColors.refreshRevenue}`,
  },
  refreshRateIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    borderTop: `3px dashed ${theme.palette.graphColors.themeAlmostWhite}`,
  },
  CTRIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    borderTop: `3px dashed ${theme.palette.graphColors.CTR}`,
  },
  RCPMIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    borderTop: `3px solid ${theme.palette.graphColors.rCPM}`,
  },
  uRPMIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    backgroundColor: theme.palette.graphColors.uRPM,
  },
  eCPMIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    backgroundColor: theme.palette.graphColors.eCPM,
  },
  AllImpIndicitor: {
    bottom: 0,
    width: '100%',
    height: 3,
    position: 'absolute',
    border: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    borderBottom: 'none',
  },
  PaidImpIndicitor: {
    bottom: 0,
    width: '100%',
    height: 3,
    position: 'absolute',
    border: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    borderBottom: 'none',
    background: theme.palette.graphColors.themeDarkGray,
  },
  pvRPMIndicator: {
    bottom: 0,
    width: '100%',
    height: 3,
    position: 'absolute',
    border: `1px solid ${theme.palette.graphColors.pvRPM}`,
    borderBottom: 'none',
    background: theme.palette.graphColors.pvRPM,
  },
  sRPMIndicator: {
    bottom: 0,
    width: '100%',
    height: 3,
    position: 'absolute',
    border: `1px solid ${theme.palette.graphColors.sRPM}`,
    borderBottom: 'none',
    background: theme.palette.graphColors.sRPM,
  },
  RefreshRequestsImpIndicitor: {
    top: 50,
    right: 30,
    width: 25,
    height: 31,
    position: 'absolute',
    border: `1px solid ${theme.palette.graphColors.themeAlmostWhite}`,
    borderBottom: 'none',
    background: theme.palette.graphColors.themeAlmostWhiteOpaque,
  },
  viewabilityIndicitor: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    borderTop: `3px dashed ${theme.palette.graphColors.viewability}`,
  },
});

export default indicatorStyles;
