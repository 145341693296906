import React from 'react';
import AmCharts from '@amcharts/amcharts3-react/index';
import {useTheme} from '@material-ui/core/styles';
import moment from 'moment/moment';
import millify from 'millify/dist/millify';

const MobileSarkline = ({valueKey, publisherStats, publisherStatsComparison}) => {
  const theme = useTheme();
  const chartRef = React.useRef();
  // console.log('MobileSarkline valueKey', valueKey);

  let data = !publisherStatsComparison
    ? publisherStats
    : [...publisherStatsComparison, ...publisherStats];

  const generateGuides = e => {
    let comparisonDatesGuide = new AmCharts.Guide({});
    comparisonDatesGuide.date = publisherStatsComparison[0].date;
    comparisonDatesGuide.toDate = publisherStats[0].date;
    comparisonDatesGuide.position = 'top';
    comparisonDatesGuide.boldLabel = true;
    comparisonDatesGuide.above = true;
    comparisonDatesGuide.inside = false;
    comparisonDatesGuide.fontSize = 8;
    comparisonDatesGuide.lineAlpha = 0;
    comparisonDatesGuide.lineColor = theme.palette.background.paper;
    comparisonDatesGuide.fillAlpha = 0;
    comparisonDatesGuide.color = theme.palette.graphColors.themeLightGray;
    comparisonDatesGuide.label = `${publisherStatsComparison[0].date} to ${
      publisherStatsComparison[publisherStatsComparison.length - 1].date
    }`;
    e.chart.categoryAxis.addGuide(comparisonDatesGuide);

    let currentDatesGuide = new AmCharts.Guide();
    currentDatesGuide.date = publisherStats[0].date;
    currentDatesGuide.toDate = publisherStats[publisherStats.length - 1].date;
    currentDatesGuide.position = 'top';
    currentDatesGuide.boldLabel = true;
    currentDatesGuide.above = false;
    currentDatesGuide.inside = false;
    currentDatesGuide.fontSize = 8;
    currentDatesGuide.dashLength = 0;
    currentDatesGuide.lineAlpha = 1;
    currentDatesGuide.lineColor = theme.palette.background.paper;
    currentDatesGuide.fillAlpha = 0;
    currentDatesGuide.color = theme.palette.graphColors.themeLightGray;
    currentDatesGuide.label = `${publisherStats[0].date} to ${
      publisherStats[publisherStats.length - 1].date
    }`;
    e.chart.categoryAxis.addGuide(currentDatesGuide);

    let separatorGuide = new AmCharts.Guide();
    separatorGuide.date = publisherStats[0].date;
    separatorGuide.lineAlpha = 1;
    separatorGuide.lineThickness = 1;
    separatorGuide.boldLabel = true;
    separatorGuide.above = true;
    separatorGuide.dashLength = 7;
    separatorGuide.color = theme.palette.graphColors.themeLightGray;
    separatorGuide.lineColor = theme.palette.graphColors.themeLightGray;
    // separatorGuide.label = `Comparison`;
    separatorGuide.position = 'top';
    separatorGuide.labelRotation = 90;
    e.chart.categoryAxis.addGuide(separatorGuide);
  };

  const balloonSettings = {
    adjustBorderColor: false,
    borderColor: '#e75d3c000',
    fillColor: '#e75d3c000',
    fillAlpha: 0,
    shadowAlpha: 0,
    color: '#fff',
    cornerRadius: 4,
    horizontalPadding: 10,
    verticalPadding: 10,
  };

  const eurMetrics = ['Rev', 'Rev Refresh', 'eCPM', 'rCPM', 'uRPM', 'sRPM', 'pvRPM'];

  const percentMetrics = ['Viewability', 'refreshRate', 'CTR'];

  const metricsOrder = [
    'All Imp',
    'Paid Imp',
    'fillRate',
    'eCPM',
    'rCPM',
    'Rev',
    'Rev Refresh',
    'refreshRate',
    'eCPM Refresh',
    'Viewability',
    'CTR',
    'sRPM',
    'uRPM',
    'pvRPM',
  ];

  const colorMap = {
    Rev: 'revenue',
    'Rev Refresh': 'refreshRevenue',
    eCPM: 'eCPM',
    rCPM: 'rCPM',
    CTR: 'CTR',
    uRPM: 'uRPM',
    sRPM: 'sRPM',
    pvRPM: 'pvRPM',
    Viewability: 'viewability',
  };

  const balloonFunction = (item, graph) => {
    let html =
      '<table style="border-radius: 4px; padding: 8px; background: rgba(0,0,0,.7); border: none!important; color: #c7c7c7; text-align: left; font-size: 13px;  line-height: 15px; -webkit-font-smoothing: auto; font-family: Inter; width: 100%; margin-top: 7px;">';
    html +=
      '<tr style="font-weight: bold; font-size: 11px;"><td>' +
      moment(item.dataContext['date']).format('YYYY MMMM D') +
      '</td></tr>';

    metricsOrder.map(metric => {
      let metricInGraph = graph.chart.graphs.filter(m => m.valueField === metric)[0];

      // Add Fill rate to tooltip even though its not in the graph
      if (metric === 'fillRate' && item.dataContext['fillRate'] && valueKey === 'fillRate') {
        html +=
          `<tr><td style="padding-top: 5px; padding-right: 15px;">Fill rate </td></td>` +
          `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(
            item.dataContext['fillRate']
          )}%</td></tr>`;
        return;
      }

      if (metricInGraph && !metricInGraph.hidden) {
        html +=
          `<tr><td style="padding-top: 5px; padding-right: 15px; ${
            colorMap.hasOwnProperty(metric)
              ? `color: ${theme.palette.graphColors[colorMap[metric]]}`
              : ``
          }">${metricInGraph.legendValueText} ${
            metric === 'pvRPM' ? `(${millify(item.dataContext['pageviews'])})` : ``
          } ${metric === 'uRPM' ? `(${millify(item.dataContext['users'])})` : ``} ${
            metric === 'sRPM' ? `(${millify(item.dataContext['visits'])})` : ``
          } </td></td>` +
          `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(
            item.dataContext[metric]
          )} ${
            eurMetrics.includes(metric) ? `€` : percentMetrics.includes(metric) ? `%` : ``
          }</td></tr>`;
      }
    });

    html += '</table>';

    return html;
  };

  const getGraphs = () => {
    let graphs = [
      {
        id: 'g0',
        valueAxis: 'v0',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        fillAlphas: 0,
        columnWidth: 1,
        lineAlpha: 1,
        lineThickness: 1,
        type: 'column',
        clustered: false,
        legendValueText: 'Requests',
        legendPeriodValueText: 'Requests',
        valueField: 'All Imp',
        hidden: valueKey !== 'All Imp',
        includeHidden: true,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g12',
        type: 'column',
        valueAxis: 'v0',
        clustered: false,
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        lineAlpha: 1,
        lineThickness: 1,
        columnWidth: 1,
        legendValueText: 'Impressions',
        legendPeriodValueText: 'Impressions',
        valueField: 'Paid Imp',
        hidden: valueKey !== 'Paid Imp',
        includeHidden: true,
        fillAlphas: 1,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g2',
        type: 'smoothedLine',
        valueAxis: 'v2',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.eCPM,
        lineThickness: 3,
        legendValueText: 'eCPM',
        legendPeriodValueText: 'eCPM',
        valueField: 'eCPM',
        hidden: valueKey !== 'eCPM',
        fillAlphas: theme.palette.type === 'dark' ? 0.2 : 0,
        fillColors: [theme.palette.graphColors.eCPM, 'rgba(0,0,0,0)'],
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g1',
        type: 'smoothedLine',
        valueAxis: 'v1',
        baseAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.revenue,
        lineThickness: 3,
        legendValueText: 'Revenue',
        legendPeriodValueText: 'Revenue',
        valueField: 'Rev',
        hidden: valueKey !== 'Rev',
        fillAlphas: theme.palette.type === 'dark' ? 0.5 : 0,
        fillColors: [theme.palette.graphColors.revenue, 'rgba(0,0,0,0)'],
        // balloonText: '[[category]]: [[value]] €',
        //  balloonColor: theme.palette.graphColors.themeDarkGray
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g20',
        valueAxis: 'v4',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeAlmostWhite,
        columnWidth: 1,
        lineAlphas: 1,
        lineThickness: 3,
        type: 'smoothedLine',
        legendValueText: 'Smart Refresh Rate',
        legendPeriodValueText: 'Smart Refresh Rate',
        valueField: 'refreshRate',
        hidden: valueKey !== 'refreshRate',
        // fillAlphas: theme.palette.type === 'dark' ? 0.2 : 0.1,
        // fillColors: [theme.palette.graphColors.themeAlmostWhite, "rgba(0,0,0,0)"],
        dashLength: 4,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g25',
        type: 'smoothedLine',
        valueAxis: 'v1',
        baseAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.refreshRevenue,
        lineThickness: 3,
        //dashLength: 4,
        legendValueText: 'Refresh Revenue',
        legendPeriodValueText: 'Refresh Revenue',
        valueField: 'Rev Refresh',
        fillAlphas: 0,
        hidden: valueKey !== 'Rev Refresh',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g3',
        type: 'smoothedLine',
        //dashLength: 4,
        valueAxis: 'v2',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.rCPM,
        lineThickness: 3,
        legendValueText: 'rCPM',
        legendPeriodValueText: 'rCPM',
        valueField: 'rCPM',
        hidden: valueKey !== 'rCPM',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g6',
        type: 'smoothedLine',
        valueAxis: 'v4',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.CTR,
        lineThickness: 3,
        dashLength: 5,
        legendValueText: 'CTR',
        legendPeriodValueText: 'CTR',
        valueField: 'CTR',
        fillAlphas: 0,
        hidden: valueKey !== 'CTR',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g7',
        type: 'smoothedLine',
        valueAxis: 'v4',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.viewability,
        lineThickness: 3,
        legendValueText: 'Viewability',
        legendPeriodValueText: 'Viewability',
        valueField: 'Viewability',
        hidden: valueKey !== 'Viewability',
        dashLength: 5,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g9',
        valueAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.uRPM,
        lineAlpha: 1,
        lineThickness: 3,
        type: 'smoothedLine',
        legendValueText: 'uRPM',
        legendPeriodValueText: 'uRPM',
        valueField: 'uRPM',
        hidden: valueKey !== 'uRPM',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g11',
        valueAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.sRPM,
        lineAlpha: 1,
        lineThickness: 3,
        type: 'smoothedLine',
        legendValueText: 'sRPM',
        legendPeriodValueText: 'sRPM',
        valueField: 'sRPM',
        fillAlphas: 0,
        hidden: valueKey !== 'sRPM',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g8',
        valueAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.pvRPM,
        columnWidth: 1,
        lineAlpha: 1,
        lineThickness: 3,
        type: 'smoothedLine',
        clustered: false,
        legendValueText: 'pvRPM',
        legendPeriodValueText: 'pvRPM',
        valueField: 'pvRPM',
        fillAlphas: 0,
        hidden: valueKey !== 'pvRPM',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g30',
        valueAxis: 'v4',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeAlmostWhite,
        columnWidth: 1,
        lineAlphas: 1,
        lineThickness: 3,
        type: 'smoothedLine',
        valueField: 'fillRate',
        hidden: valueKey !== 'fillRate',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
        // fillAlphas: theme.palette.type === 'dark' ? 0.2 : 0.1,
        // fillColors: [theme.palette.graphColors.themeAlmostWhite, "rgba(0,0,0,0)"],
      },
    ];

    return graphs;
  };

  return (
    <>
      <AmCharts.React
        ref={chartRef}
        style={{
          height: '200px',
          width: '100%',
          transition: 'none!important',
        }}
        options={{
          tapToActivate: false,
          chartCursor: {
            cursorAlpha: 0.5,
            //cursorColor: theme.palette.graphColors.themeGray,
            cursorColor: colorMap.hasOwnProperty(valueKey)
              ? theme.palette.graphColors[colorMap[valueKey]]
              : theme.palette.graphColors.themeGray,
            categoryBalloonEnabled: false,
            pan: false,
            zoomable: false,
          },
          columnSpacing: 0,
          zoomOutOnDataUpdate: true,
          type: 'serial',
          theme: 'light',
          marginTop: 15,
          marginRight: 15,
          responsive: {
            enabled: true,
          },
          legend: {
            enabled: false,
          },
          dataProvider: data,
          categoryField: 'date',
          categoryAxis: {
            parseDates: true,
            gridAlpha: 0,
            color: theme.palette.graphColors.themeGray,
            axisColor: theme.palette.graphColors.themeGray,
          },
          valueAxes: [
            {
              id: 'v0',
              titleBold: false,
              titleColor: theme.palette.graphColors.themeGray,
              minVerticalGap: 50,
              gridAlpha: 0,
              color: theme.palette.graphColors.themeGray,
              axisColor: theme.palette.graphColors.themeGray,
              minimum: 0,
            },
          ],
          graphs: getGraphs(),
          chartScrollbar: {
            enabled: false,
          },
          dataDateFormat: 'YYYY-MM-DD',
          creditsPosition: 'bottom-right',
          zoomOutButton: {
            backgroundColor: '#e0e0e0',
            backgroundAlpha: 0,
          },
          zoomOutText: '',
          listeners: [
            {
              event: 'init',
              method: e => {
                if (publisherStatsComparison && publisherStatsComparison.length > 1) {
                  generateGuides(e);
                }
              },
            },
            {
              event: 'rendered',
              method: e => {
                if (publisherStatsComparison && publisherStatsComparison.length > 1) {
                  generateGuides(e);
                }
              },
            },
          ],
        }}
      />
    </>
  );
};

export default MobileSarkline;
