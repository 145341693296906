const getBackgroundColorByStatus = status => {
  switch (status) {
    case 'New':
      return 'sRPM';
    case 'Done':
      return 'revenue';
    case 'Waiting on customer':
      return 'rCPM';
    case 'Reopened':
      return 'rCPM';
    case 'In Progress':
      return 'rCPM';
    default:
      return 'themeLightGrayOpaque';
  }
};

module.exports = getBackgroundColorByStatus;
