import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import millify from 'millify/dist/millify';
import InfoIcon from '@material-ui/icons/Info';
import Trend from './Trend';
import MobileSarkline from './MobileSarkline';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core/styles/index';
import usePublishers from '../../hooks/usePublishers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    display: `inline-block`,
    fontSize: 12,
  },
  paperWrapper: {
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  revIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    backgroundColor: theme.palette.graphColors.revenue,
  },
}));

export const MetricsDisplay = React.memo(props => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('Revenue');
  const {publishersHasOwnGam} = usePublishers();

  const {
    login,
    performanceEnabled,
    graphType,
    analyticsLoaded,
    selection,
    calculated,
    calculatedComparison,
    assignedGoogleProperties,
    period,
    comparisonPeriod,
    handleGraphTypeChange,
    publisherStats,
    publisherStatsComparison,
  } = props;

  const metrics = {
    // Monetization
    1: [
      {
        valueKey: 'Rev',
        current: calculated.rev,
        previous: calculatedComparison ? calculatedComparison.rev : undefined,
        tooltip: `Total ${publishersHasOwnGam ? 'gross' : 'net'} revenue.`,
        icon: false,
        primary: `${calculated.rev}€`,
        secondary: 'Revenue',
      },
      {
        valueKey: 'All Imp',
        current: calculated.imp,
        previous: calculatedComparison ? calculatedComparison.imp : undefined,
        tooltip: 'Number of ad server requests. Requests to start the auction.',
        icon: false,
        primary: `${millify(calculated.imp)}`,
        secondary: 'Requests',
      },
      {
        valueKey: 'Paid Imp',
        current: calculated.paidImp,
        previous: calculatedComparison ? calculatedComparison.paidImp : undefined,
        tooltip: 'The number of paid ad impressions delivered as a result of successful auctions.',
        icon: false,
        primary: `${millify(calculated.paidImp)}`,
        secondary: 'Impressions',
      },
      {
        valueKey: 'eCPM',
        current: (calculated.rev / calculated.paidImp) * 1000,
        previous: calculatedComparison
          ? (calculatedComparison.rev / calculatedComparison.paidImp) * 1000
          : undefined,
        tooltip: `Effective Cost Per Thousand Impressions." The average
        ${publishersHasOwnGam ? 'gross' : ''} revenue earned per 1000 ad
        impressions.`,
        icon: false,
        primary: `${
          calculated.imp != 0 && calculated.paidImp != 0
            ? ((calculated.rev / calculated.paidImp) * 1000).toFixed(2)
            : 0
        }€`,
        secondary: 'eCPM',
      },
      {
        valueKey: 'fillRate',
        current:
          calculated.paidImp != 0 && calculated.imp != 0
            ? ((calculated.paidImp * 100) / calculated.imp).toFixed(2)
            : 0,
        previous: calculatedComparison
          ? ((calculatedComparison.paidImp * 100) / calculatedComparison.imp).toFixed(2)
          : undefined,
        tooltip:
          'The percentage of ad requests that resulted in paid ad impressions. Calculated as (Impressions / Requests) * 100.',
        icon: false,
        primary: `${
          calculated.paidImp != 0 && calculated.imp != 0
            ? ((calculated.paidImp * 100) / calculated.imp).toFixed(2)
            : 0
        }%`,
        secondary: 'Fill rate',
      },
    ],
    // Performance
    2: [
      {
        valueKey: 'Rev Refresh',
        current: calculated.refreshRev,
        previous: calculatedComparison ? calculatedComparison.refreshRev : undefined,
        disabled: !login.refresh_enabled === 1,
        tooltip: `${publishersHasOwnGam ? 'Gross revenue' : 'Revenue'} from Viewable
         Bid Optimization, an algorithm that refreshes ads only when
         beneficial for publishers to maintain a high average eCPM.`,
        icon: false,
        primary: `${millify(calculated?.refreshRev)}€`,
        secondary: 'Refresh Revenue',
      },
      {
        valueKey: 'refreshRate',
        current: calculated.refreshRate,
        previous: calculatedComparison ? calculatedComparison.refreshRate : undefined,
        disabled: login.refresh_enabled !== 1,
        tooltip:
          'The rate at which ads are refreshed by the Viewable Bid Optimization algorithm, ensuring refreshes occur only when beneficial to maintain a high average eCPM.',
        icon: false,
        primary: `${
          !isFinite(calculated.refreshRate) || isNaN(calculated.refreshRate)
            ? 'N/A'
            : `${login.refresh_enabled === 1 ? calculated.refreshRate.toFixed(2) : 0}%`
        }`,
        secondary: 'Smart Refresh Rate',
      },
      {
        valueKey: 'Viewability',
        current: calculated.viewability,
        previous: calculatedComparison ? calculatedComparison.viewability : undefined,
        disabled: !login.viewability_enabled === 1,
        tooltip:
          "The percentage of ads that were visible in the browser for at least 1 continuous second and displayed at least 50% of the ad's pixels. Measured by Google across Google Ad Exchange impressions.",
        icon: false,
        primary: `${isNaN(calculated?.viewability) ? 'N/A' : `${calculated?.viewability}%`}`,
        secondary: 'Viewability',
      },
      {
        valueKey: 'rCPM',
        current: (calculated.rev / calculated.imp) * 1000,
        previous: calculatedComparison
          ? (calculatedComparison.rev / calculatedComparison.imp) * 1000
          : undefined,
        disabled: !login.rCPM_enabled === 1,
        tooltip: `"Request CPM" or "Revenue CPM." The average{' '}
         ${publishersHasOwnGam ? 'gross' : ''} revenue per 1000 ad requests,
         including those not filled. The difference between rCPM and eCPM is
         equivalent to fill rate.`,
        primary: `${
          calculated?.rev != 0 && calculated?.imp != 0
            ? ((calculated?.rev / calculated?.imp) * 1000).toFixed(2)
            : 0
        }€`,
        secondary: 'rCPM',
      },
      {
        valueKey: 'CTR',
        current: calculated.CTR,
        previous: calculatedComparison ? calculatedComparison.CTR : undefined,
        disabled: !login.ctr_enabled === 1,
        tooltip:
          'The percentage of ad impressions that resulted in a user click. Measured by Google across Google Ad Exchange impressions.',
        icon: false,
        primary: `${isNaN(calculated.CTR) ? 'N/A' : `${calculated.CTR}%`}`,
        secondary: 'CTR',
      },
    ],
    // Audience
    3: [
      {
        valueKey: 'uRPM',
        disabled: selection.value.placement,
        tooltip:
          "Revenue per 1000 users. The number of users is obtained from Google Analytics data. uRPM is available on domain level only since it's non-cumulative statistics.",
        icon: false,
        primary: props.assignedGoogleProperties.find(
          property => property.domainId == selection.value.domain
        )
          ? `${(calculated.rev / (calculated.users / 1000)).toFixed(2)}€`
          : `Non-cumulative data`,
        secondary: `uRPM ${
          assignedGoogleProperties.find(property => property.domainId == selection.value.domain)
            ? `(${millify(calculated?.users)} Users)`
            : ''
        }`,
      },
      {
        valueKey: 'sRPM',
        disabled: selection.value.placement,
        tooltip:
          'Revenue per 1000 sessions. The number of sessions is obtained from connected Google Analytics data. sRPM is available on domain and account levels.',
        icon: false,
        primary: `${(calculated?.rev / (calculated?.visits / 1000)).toFixed(2)}€`,
        secondary: `sRPM (${millify(calculated?.visits)} Sessions)`,
      },
      {
        valueKey: 'pvRPM',
        disabled: selection.value.placement,
        tooltip:
          'Revenue per 1000 pageviews. The number of pageviews is obtained from connected Google Analytics data. pvRPM is available on domain and account levels.',
        icon: false,
        primary: `${(calculated?.rev / (calculated?.pageviews / 1000)).toFixed(2)}€`,
        secondary: `pvRPM (${millify(calculated?.pageviews)} Pageviews)`,
      },
      {
        valueKey: 'sessionsPerUser',
        disabled: selection.value.placement,
        tooltip:
          "Number of sessions per user. Average number of sessions made by users. Obtained from connected Google Analytics data. Available on domain level only since it's non-cumulative statistics.",
        icon: false,
        primary: props.assignedGoogleProperties.find(
          property => property.domainId == selection.value.domain
        )
          ? millify(calculated?.sessionsPerUser)
          : `Non-cumulative data`,
        secondary: `Sessions / User`,
      },
      {
        valueKey: 'pageviewsPerSession',
        disabled: selection.value.placement,
        tooltip:
          'The number of pages per session. The average number of pages viewed during a session. Obtained from connected Google Analytics data. Available on domain and account levels.',
        icon: false,
        primary: millify(calculated?.pageviewsPerSession),
        secondary: `Pages / Session`,
      },
    ],
  };

  const listItem = ({
    valueKey,
    current,
    previous,
    tooltip,
    icon,
    primary,
    secondary,
    disabled = false,
  }) => {
    if (disabled) return;

    return (
      <Paper className={classes.paperWrapper}>
        <ListItem
          dense
          onClick={() =>
            setExpanded(prev =>
              prev === secondary ||
              primary === `Non-cumulative data` ||
              valueKey === 'pageviewsPerSession' ||
              valueKey === 'sessionsPerUser'
                ? null
                : secondary
            )
          }
        >
          <IconButton
            disabled={
              primary === `Non-cumulative data` ||
              valueKey === 'pageviewsPerSession' ||
              valueKey === 'sessionsPerUser'
            }
          >
            {expanded !== secondary ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
          <ListItemText
            primary={
              <Typography variant={'h6'}>
                {primary}
                {previous && <Trend enabled={true} current={current} previous={previous} />}
              </Typography>
            }
            secondary={secondary}
          />
          <Tooltip title={tooltip} enterTouchDelay={60}>
            <IconButton>
              <InfoIcon
                style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                fontSize={'small'}
              />
            </IconButton>
          </Tooltip>
        </ListItem>
        <Collapse in={expanded === secondary} timeout="auto" unmountOnExit>
          <ListItem>
            {expanded === secondary && (
              <MobileSarkline
                valueKey={valueKey}
                publisherStats={publisherStats}
                publisherStatsComparison={publisherStatsComparison}
              />
            )}
          </ListItem>
        </Collapse>
      </Paper>
    );
  };

  const getGraphTypeWidth = () => {
    if (analyticsLoaded && performanceEnabled) {
      return 4;
    } else if (!analyticsLoaded && !performanceEnabled) {
      return 12;
    } else {
      return 6;
    }
  };

  return (
    <>
      <Grid
        className={classes.root}
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={getGraphTypeWidth()}>
          <Button
            onClick={() => handleGraphTypeChange(1)}
            fullWidth
            variant={'text'}
            color={graphType === 1 ? 'primary' : 'inherit'}
            className={classes.button}
          >
            Monetization
          </Button>
        </Grid>

        {performanceEnabled && (
          <Grid item xs={getGraphTypeWidth()}>
            <Button
              onClick={() => handleGraphTypeChange(2)}
              fullWidth
              variant={'text'}
              color={graphType === 2 ? 'primary' : 'inherit'}
              className={classes.button}
            >
              Performance
            </Button>
          </Grid>
        )}

        {analyticsLoaded && (
          <Grid item xs={getGraphTypeWidth()}>
            <Button
              onClick={() => handleGraphTypeChange(3)}
              fullWidth
              variant={'text'}
              color={graphType === 3 ? 'primary' : 'inherit'}
              className={classes.button}
              disabled={selection.value.placement}
            >
              Audience
            </Button>
          </Grid>
        )}
      </Grid>

      {metrics[graphType].map((metric, i) => listItem(metric, i))}

      {selection.value.placement && graphType === 3 && (
        <Paper className={classes.paperWrapper}>
          <ListItem dense>
            <ListItemText primary={'Available only on a Domain and Account Total levels'} />
          </ListItem>
        </Paper>
      )}
    </>
  );
});

export default MetricsDisplay;
