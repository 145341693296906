import React, {useEffect} from 'react';
import useStore from '../store/store';
import {APICall} from '../api/api';
import {Link, LinkOff} from '@material-ui/icons';

const getRedirectStatus = (accountId, accountType, currentAdsTXTurl, domain) => {
  // We check redirect URL based on account type, now it differs for self-serve and managed accounts
  let baseRedirectUrl = `https://wb.setupad.com/api/getAdsTXT`;
  let requiredRedirectURL = null;
  if (accountType >= 5 && accountType > 8) {
    requiredRedirectURL = `${baseRedirectUrl}/${accountId}/selfserve/${domain.id}`;
  } else {
    requiredRedirectURL = `${baseRedirectUrl}/${accountId}/file/${domain.id}`;
  }

  // Different account types has different button text for editing ads.txt
  let editAdsTXTButtonText = 'Edit custom entries';
  if (accountType >= 5 && accountType > 8) {
    editAdsTXTButtonText = `Edit ads.txt`;
  }

  let redirectStatus = false;
  // Check redirect status - detect if it's redirected to correct url
  if (
    currentAdsTXTurl?.includes(requiredRedirectURL) ||
    // Demo account id - show redirect as working correctly
    accountId === 1219
  ) {
    redirectStatus = {
      success: true,
      message: 'Redirected to Setupad',
      messageInCustomEntries:
        'Redirect is enabled and these lines will be included in your ads.txt file.',
      alert: 'success',
      icon: <Link />,
      tooltip: `Redirect is set up correctly and ${domain.title}/ads.txt redirects to ads.txt hosted by Setupad.`,
    };
  } else if (currentAdsTXTurl?.includes(baseRedirectUrl)) {
    redirectStatus = {
      success: false,
      message: 'Incorrect redirect to Setupad',
      messageInCustomEntries:
        'Redirect is not enabled or is incorrect, these lines will NOT be included in your ads.txt file.',
      alert: 'error',
      icon: <LinkOff />,
      tooltip: `${domain.title}/ads.txt redirects to ads.txt hosted by Setupad, but the redirect URL is incorrect. Please, check the instructions below to find the correct URL. Each domain has its own URL, so we can add your Ads.txt entries.`,
    };
  } else {
    // Default redirect status
    redirectStatus = {
      success: false,
      message: 'Redirect not detected',
      messageInCustomEntries:
        'Redirect is not enabled or is incorrect, these lines will NOT be included in your ads.txt file.',
      alert: 'warning',
      icon: <Link />,
      tooltip: `Please fill in the Ads.txt by clicking "${editAdsTXTButtonText}" button and follow instructions below to set-up Ads.txt redirect`,
    };
  }

  return redirectStatus;
};

const useDomains = () => {
  const auth = useStore(state => state.auth);
  const onboardingSteps = useStore(state => state.onboardingSteps);
  const checked = useStore(state => state.checked);
  const domains = useStore(state => state.domains);
  const setState = useStore(state => state.setState);
  const isSelfServe = auth?.type >= 5 && auth?.type < 8;

  useEffect(() => {
    // Don't update domains and therefore don't fetch new ads.txt status if there are no new domains
    let currentDomains = domains?.map(domain => domain.id);
    let updatedDomains = auth?.menu?.menu?.[1]?.children?.map(item => item?.path[2]);
    if (JSON.stringify(currentDomains) === JSON.stringify(updatedDomains)) return;

    let newDomains = [];
    if (auth?.menu?.menu) {
      newDomains = auth.menu.menu[1]?.children
        ?.filter(item => item.data?.menu?.title)
        .reduce((allDomains, item) => {
          allDomains.push({
            title: item.data.menu.title,
            id: item.path[2],
            accountId: auth.accountId,
            publisherId: parseInt(item.path[1]),
            siteId: item.siteid,
          });
          return allDomains;
        }, []);
    }

    setState('domains', [
      ...new Map(newDomains?.map(domain => [domain['title'], domain])).values(),
    ]);
    setState('checked', []);
  }, [auth, setState]);

  useEffect(() => {
    const checkAdsTxt = async () => {
      for (const domain of domains) {
        await new Promise(async next => {
          let adsTXTdata;

          try {
            adsTXTdata = await APICall({
              endpoint: 'checkAdsTxt',
              params:
                auth.username !== 'demo'
                  ? `${auth.accountId}/${domain.title.replace('https://', '')}/${domain.id}`
                  : `141/needpix.com/${domain.id}`,
              options: {
                method: 'get',
                headers: {
                  Authorization: `Bearer ${auth.token}`,
                  'Cache-Control': 'max-age=0, no-cache',
                  'Content-Type': 'application/json',
                },
                importance: 'low',
              },
            });
          } catch (e) {
            adsTXTdata = {success: false, error: e.message};
          }

          // If at least one domain has all no ads.txt missing, and if onboarding step is currently ads.txt, then mark it as completed
          if (adsTXTdata.missingAdsTXT?.length === 0 && onboardingSteps[1]?.active) {
            if (!isSelfServe) {
              setState('completedStep', 1);
            }
          }

          setState('checked', [
            ...useStore.getState().checked,
            {
              ...adsTXTdata,
              domain: domain.title.replace('https://', ''),
              id: domain.id,
              redirectStatus: getRedirectStatus(
                auth.accountId,
                auth.type,
                adsTXTdata.currentAdsTXT?.url,
                domain
              ),
            },
          ]);

          next();
        });
      }
    };

    if (auth?.menu && !checked.length && domains.length) {
      checkAdsTxt();
    }
  }, [checked, domains]);

  // Additional use effect for checked domains, required to autocomplete Self-serve onboarding step
  useEffect(() => {
    if (
      isSelfServe &&
      checked.length &&
      domains.length &&
      domains.length === checked.filter(domain => domain.redirectStatus?.success).length
    ) {
      setState('completedStep', 2);
    }
  }, [checked, onboardingSteps, isSelfServe, domains, setState]);

  return {checked, domains};
};

export default useDomains;
