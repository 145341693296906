import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import SyncIcon from '@material-ui/icons/Sync';
import ReactGA from 'react-ga4';
import FadeIn from 'react-fade-in';
import useStore from '../store/store';
import {Tooltip} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {RedirectOptions} from '../components/subcomponents/adstxt/RedirectOptions';

import {AdsListItem} from '../components/subcomponents/AdsListItem';
import {AdsListItemSelfServe} from '../components/subcomponents/AdsListItemSelfServe';

const components = {
  regular: AdsListItem,
  selfserve: AdsListItemSelfServe,
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    borderRadius: '4px',
    border: '1px solid #7979793b',
  },
  paper: {
    padding: theme.spacing(3, 2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  collapseContainer: {
    width: '100%',
  },
  videoResponsive: {
    marginTop: 20,
    marginBottom: 20,
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  },
  videoResponsiveIframe: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
}));

export const AdsTXT = React.memo(({login, checked, domains}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(login.menu.error ? 'all' : null);
  const onboardingSteps = useStore(state => state.onboardingSteps);
  const visiblePopup = useStore(state => state.visiblePopup);
  const adsTxtDownloaded = useStore(state => state.adsTxtDownloaded);
  const setState = useStore(state => state.setState);
  const isSelfServe = login.type >= 5 && login.type < 8;
  const ListItem = components[isSelfServe ? 'selfserve' : 'regular'];

  const GridIContainer = props => <Grid container {...props} />;

  return (
    <Container component="main" maxWidth="xl" className={classes.viewWrapper}>
      <Typography variant={'h4'} gutterBottom>
        Ads.txt Management
      </Typography>
      <Typography gutterBottom>
        {login.type >= 5 &&
          login.type < 8 &&
          `We highly recommend using Setupad's ads.txt redirect. Set it up once and any changes you make will be automatically included in the redirected ads.txt file. Edit the ads.txt redirect by clicking edit ads.txt.`}
        {(login.type < 5 || login.type > 7) &&
          `Place the ads.txt file in your site's root folder to enable inventory selling to bidders via Setupad. We highly recommend using Setupad's ads.txt redirect. Once set up, any changes will automatically reflect in the redirected ads.txt file.`}
      </Typography>

      <Button
        variant={'outlined'}
        color={'primary'}
        href={'https://setupad.com/blog/ads-txt-guide-for-publishers/'}
        target={'_blank'}
        className={classes.button}
        onClick={() =>
          ReactGA.event({
            category: 'Ads.txt',
            action: 'click',
            label: `guide for publishers link`,
          })
        }
      >
        Learn more about ads.txt
      </Button>

      {checked.length === domains.length && login?.menu?.menu && login?.menu?.menu[1] && (
        <Tooltip
          open={
            visiblePopup === 'onboarding' &&
            !isSelfServe &&
            onboardingSteps[1].active &&
            !adsTxtDownloaded
          }
          arrow
          title={
            <div style={{padding: 20}}>
              <Typography variant={'body2'}>
                Once the Ads.txt file is placed in your site's root folder, click this button to
                confirm that your updated file has all the required lines.
              </Typography>
            </div>
          }
          placement="bottom"
        >
          <Button
            variant="contained"
            color={'primary'}
            className={classes.button}
            startIcon={<SyncIcon />}
            onClick={() => {
              setState('checked', []);
            }}
          >
            {checked.length === domains.length ? `Check again` : `Checking domains`}
          </Button>
        </Tooltip>
      )}
      <br />
      {/* Show ads.txt video embed if all domains are missing at least one ads.txt line*/}
      {login.type < 5 &&
        checked.length === domains.length &&
        checked.filter(d => d.missingAdsTXT.length > 0)?.length === domains.length && (
          <FadeIn>
            <div className={classes.videoResponsive}>
              <iframe
                className={classes.videoResponsiveIframe}
                src="https://www.youtube.com/embed/gqa_cKHddEE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </FadeIn>
        )}
      <br />

      <Grid container className={classes.wrapper} spacing={1} direction={'column'}>
        <List disablePadding component={GridIContainer}>
          {domains.map((domain, index) => (
            <ListItem
              index={index}
              accountId={login.accountId}
              domain={domain}
              checkedDomain={checked.find(checkedDomain => checkedDomain.id === domain.id)}
              classes={classes}
              open={open}
              setOpen={setOpen}
            />
          ))}
        </List>

        {login.type < 5 && (
          <Alert severity="warning">
            Each domain has unique IDs on the ads.txt file. Please download and implement the
            respective files.
          </Alert>
        )}
      </Grid>

      {domains?.length > 0 && (
        <RedirectOptions
          accountId={login.accountId}
          domains={domains}
          checked={checked}
          isSelfServe={login.type >= 5}
        />
      )}
    </Container>
  );
});
