import React, {useEffect, useRef, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import ReactGA from 'react-ga4';

import FadeIn from 'react-fade-in';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import {useTheme} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawer: {
    //  background: 'red'
  },
  mainWrapper: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    width: 340,
    margin: theme.spacing(5),
  },
  article: {
    width: '100%',
  },
  media: {
    height: 167,
  },
  mediaSmall: {
    height: 167,
    borderRadius: 20,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 6px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    backgroundPosition: `center !important`,
    backgroundSize: `cover !important`,
    transition: 'background-position 0.5s ease-in-out, box-shadow 0.2s ease-in-out',
  },
  card: {
    marginBottom: theme.spacing(2),
    background: 'none!important',
    '&:hover': {
      '&:hover $mediaSmall': {
        backgroundPosition: `top left!important`,
        boxShadow: 'inset rgba(0, 0, 0, 0.7) 0px 1px 4px, rgba(0, 0, 0, 0.4) 0px 1px 2px',
      },
      '&:hover $cardContent': {
        //  top: -15,
      },
    },
  },
  cardContent: {
    transition: 'top 0.5s ease-in-out',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    background: theme.palette.background.paper,
    fontWeight: `700!important`,
    zIndex: 2,
    margin: '0 12px',
    top: -30,
    position: 'relative',
    padding: 15,
    borderRadius: 20,
  },
}));

const LatestNews = props => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [news, setNews] = React.useState(false);
  const [error, setError] = React.useState(null);
  const theme = useTheme();

  const loadLatestNews = async () => {
    // Load all required articles at once
    let articles = await Promise.all([
      fetch(
        'https://setupad.com/wp-json/wp/v2/blog?per_page=6&_embed&exclude[0]=37309'
      ).then(response => response.json()),
      fetch('https://setupad.com/wp-json/wp/v2/webinars?per_page=6&_embed').then(response =>
        response.json()
      ),
    ]);

    // Flatten articles object
    articles = articles.flat();

    // Sort articles
    articles = articles.sort((a, b) => new Date(b.date) - new Date(a.date));

    setNews(articles);
    setLoading(false);
  };

  useEffect(() => {
    loadLatestNews();
  }, []);

  return (
    <>
      {news && (
        <>
          <FadeIn>
            <Grid
              direction="column"
              justify="center"
              alignItems="center"
              //   spacing={1}
              container
              className={classes.mainWrapper}
            >
              {news &&
                news.map((article, i) => {
                  if (i < 6) {
                    return (
                      <Grid item xs={10} className={classes.article}>
                        <Typography gutterBottom variant={'caption'} color={'textSecondary'}>
                          Published {moment(article.date).fromNow()}
                        </Typography>
                        <Tooltip
                          title={
                            <Typography variant={'body2'}>
                              <div dangerouslySetInnerHTML={{__html: article.excerpt.rendered}} />
                            </Typography>
                          }
                          placement="right"
                          arrow
                        >
                          <Card
                            elevation={0}
                            className={classes.card}
                            href={`${article.link}?utm_source=clientui&utm_medium=banner&utm_campaign=${article.slug}`}
                            target={'_blank'}
                          >
                            <CardActionArea
                              styles={{
                                focusHighlight: provided => ({
                                  ...provided,
                                  opacity: 0,
                                  '&:hover': {
                                    backgroundColor: 'red',
                                  },
                                }),
                              }}
                              href={`${article.link}?utm_source=clientui&utm_medium=banner&utm_campaign=${article.slug}`}
                              target={'_blank'}
                              onClick={() => {
                                ReactGA.event({
                                  category: 'Latest news article',
                                  action: 'click',
                                  label: article.title.rendered,
                                });
                              }}
                            >
                              <CardMedia
                                className={classes.mediaSmall}
                                image={article._embedded['wp:featuredmedia'][0].source_url}
                                title={article.title.rendered}
                              />
                              <CardContent className={classes.cardContent}>
                                <Typography
                                  gutterBottom
                                  variant="caption"
                                  component="h2"
                                  color={'textSecondary'}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        article.yoast_head_json.twitter_misc['Est. reading time'] +
                                        ' read',
                                    }}
                                  ></div>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="h2"
                                  style={{fontWeight: 600}}
                                >
                                  <div dangerouslySetInnerHTML={{__html: article.title.rendered}} />
                                </Typography>
                                {/* <Typography gutterBottom variant="caption" component="h2">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: article.excerpt.rendered,
                                    }}
                                  />
                                </Typography> */}
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Tooltip>
                      </Grid>
                    );
                  }
                })}

              {news && (
                <Grid item>
                  <Button
                    style={{marginBottom: 30}}
                    onClick={() =>
                      ReactGA.event({
                        category: 'Latest news article',
                        action: 'click',
                        label: 'Visit our blog',
                      })
                    }
                    variant="text"
                    size={'small'}
                    href={'https://setupad.com/blog/?source=client_ui'}
                    target={'_blank'}
                  >
                    Visit our blog
                  </Button>
                </Grid>
              )}
            </Grid>
          </FadeIn>
        </>
      )}
    </>
  );
};

export default LatestNews;
