import React, {createContext, useContext, useEffect, useState} from 'react';
import io from 'socket.io-client';
import useStore from '../store/store';
const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({children}) => {
  const [socket, setSocket] = useState(null);
  const auth = useStore(state => state.auth);

  useEffect(() => {
    if (auth) {
      const newSocket = io.connect(
        process.env.NODE_ENV === 'development' ? '' : 'https://wb.setupad.com',
        {
          secure: true,
          transports: ['polling', 'websocket'],
          reconnectionDelay: 500,
          reconnection: true,
          timeout: 5000,
          reconnectionAttempts: Infinity,
          autoConnect: true,
          withCredentials: true,
          query: auth
            ? {
                token: auth.token,
                accountId: auth.accountId,
                username: auth.username,
              }
            : undefined,
        }
      );

      setSocket(newSocket);

      return () => {
        newSocket.close();
      };
    }
  }, [setSocket, auth]);

  useEffect(() => {
    if (!socket || !socket.connected) return;
    socket.on('connect_error', err => {
      console.log(`Websocket connect_error due to ${err.message}`, err);
    });

    socket.on('error', error => {
      console.log('Websocket error', error);
    });

    return () => {
      if (socket) {
        socket.off('connect_error');
        socket.off('error');
      }
    };
  }, [socket]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
