import React, {useEffect, useState} from 'react';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {
  Grid,
  TextField,
  ListItem,
  ListItemText,
  Collapse,
  CircularProgress,
} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {mcmStatusDecoder} from './../../helpers/MCMstatusDecoder';
import {MCMsitesList} from './MCMsitesList';
import {NewMCM} from './NewMCM';
import {Guide} from './acceptTutorial/Guide';

export function MCMlistItem({publisher, mcmData, login, getPublishers}) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)} selected={open}>
        <ListItemText primary={`${publisher.publisherName} publisher`} />
        {!mcmData ? (
          <CircularProgress size={'1.3rem'} disableShrink />
        ) : open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItem>

      {mcmData && (
        <Collapse in={open} timeout={0} style={{width: '100%'}}>
          <Grid container spacing={2} style={{padding: 30}}>
            {/* No MCM found */}
            {!mcmData?.childPublisher && (
              <NewMCM publisher={publisher} login={login} getPublishers={getPublishers} />
            )}

            {/* MCM detected */}
            {mcmData.childPublisher && (
              <>
                <Grid item xs={4}>
                  <TextField disabled fullWidth id="email" label="Email" value={mcmData.email} />
                </Grid>

                <Grid item xs={4}>
                  <TextField disabled fullWidth id="name" label="Name" value={mcmData.name} />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="childNetworkCode"
                    label="Network Code"
                    value={
                      mcmData.childPublisher?.childNetworkCode
                        ? mcmData.childPublisher.childNetworkCode
                        : ``
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Alert
                    severity={mcmStatusDecoder[mcmData.childPublisher.accountStatus]?.severity}
                    style={{marginTop: 15}}
                    icon={mcmStatusDecoder[mcmData.childPublisher.accountStatus]?.icon}
                  >
                    <AlertTitle>
                      Status:{' '}
                      {mcmData.childPublisher.accountStatus.toLowerCase().replace(/_/g, ' ')}
                    </AlertTitle>
                    {mcmStatusDecoder[mcmData.childPublisher.accountStatus]?.text}
                  </Alert>
                </Grid>
                <MCMsitesList sites={mcmData.sites} />

                {mcmData.childPublisher.accountStatus === 'INVITED' && (
                  <Guide hasGAM={mcmData.childPublisher?.childNetworkCode} />
                )}
              </>
            )}
          </Grid>
        </Collapse>
      )}
    </>
  );
}
