import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {siteStatuses, getColorSiteStatus} from '../../helpers/MCMstatusDecoder';

export function MCMsitesList({sites}) {
  return (
    <Grid container>
      {!sites?.success && (
        <Grid item xs={12}>
          <Typography align={'center'} color={'error'}>
            {'Sites could not not be loaded'}
          </Typography>
        </Grid>
      )}

      {sites.success && sites?.data?.length > 0 && (
        <>
          <Grid item xs={12} style={{marginTop: 15}}>
            <Typography>Sites list:</Typography>
          </Grid>
          {sites.data.map(site => (
            <Grid
              container
              spacing={2}
              style={{paddingTop: 15}}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography>
                  <img
                    alt={site.url}
                    src={`https://www.google.com/s2/favicons?domain=${site.url}`}
                    style={{position: 'relative', top: 3, marginRight: 6}}
                  />
                  {site.url}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Alert
                  icon={siteStatuses[site.approvalStatus]?.icon}
                  severity={getColorSiteStatus(site.approvalStatus)}
                  style={{marginTop: 5}}
                >
                  {site.approvalStatus} {siteStatuses[site.approvalStatus]?.text}
                  {site.disapprovalReasons
                    ? `(${site.disapprovalReasons
                        .map(reason => reason.details?.toLowerCase())
                        .join(',')})`
                    : ''}
                </Alert>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
}
