import {loadStripe} from '@stripe/stripe-js';

let stripePromise;

const getStripe = () => {
    if (!stripePromise) {
        const stripeKey = process.env.REACT_APP_DEVELOPMENT === 'true' ? process.env.REACT_APP_PUBLIC_STRIPE_KEY_TEST : process.env.REACT_APP_PUBLIC_STRIPE_KEY;
        stripePromise = loadStripe(stripeKey || null);
    }

    return stripePromise;
};

export default getStripe;