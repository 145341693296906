import React from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, List, CircularProgress, IconButton, Tooltip} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import useStore from '../store/store';
import {Alert} from '@material-ui/lab';
import usePublishers from '../hooks/usePublishers';
import {AdManagerListItem} from '../components/subcomponents/AdManager/ListItem';
import {CreatePublisher} from '../components/subcomponents/AdManager/CreatePublisher';
import background_promo_dark from '../components/img/background_promo_dark.svg';
import background_promo_light from '../components/img/background_promo_light.svg';
import FadeIn from 'react-fade-in/lib/FadeIn';
import LogoDarkTheme from '../components/img/setupad-logo-UA-white.svg';
import LogoWhiteTheme from '../components/img/setupad-logo-UA-darkblue.svg';
import CloseIcon from '@material-ui/icons/Close';

const GridIContainer = props => <Grid container {...props} />;

const darkBg = `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0px, #110f12 350px), url(${background_promo_dark}) no-repeat top`;
const whiteBg = `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0px, #FFF 350px), url(${background_promo_light}) no-repeat top`;

const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.drawer + 1,
    background: theme.name === 'darkTheme' ? darkBg : whiteBg,
    color: theme.name === 'darkTheme' ? '#fff' : 'inherit',
    backgroundColor: theme.name === 'darkTheme' ? '#111!important' : 'white!important',
    animation: '$bgAnimation 100s ease infinite',
    transition: 'background 0.5s ease',
    overflowX: 'scroll',
    maxHeight: '100vh',
  },
  wrapper: {
    borderRadius: 4,
    flexGrow: 1,
    border: '1px solid #7979793b',
    marginBottom: 20,
  },
  wrapperForcedFullScreen: {
    boxShadow: theme.name === 'darkTheme' ? 'rgb(33, 181, 196) 0px 0px 150px' : 'null',
    borderRadius: 10,
    border: theme.name === 'darkTheme' ? 'none' : '1px solid #7979793b',
    flexGrow: 1,
    marginBottom: 20,
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
  bgWrapperDark: {
    background: darkBg,
    backgroundSize: `auto 544px`,
    animation: '$bgAnimation 100s ease infinite',
    transition: 'background 0.5s ease',
  },
  bgWrapperLight: {
    background: whiteBg,
    backgroundSize: `auto 544px`,
    backgroundColor: 'white!important',
    animation: '$bgAnimation 100s ease infinite',
    transition: 'background 0.5s ease',
  },
  '@keyframes bgAnimation': {
    '0%': {
      backgroundColor: '#111',
      backgroundSize: '1000px',
    },
    '50%': {
      backgroundColor: '#111',
      backgroundSize: '1200px',
    },
    '100%': {
      backgroundColor: '#111',
      backgroundSize: '1000px',
    },
  },
  loader: {display: 'block', position: 'absolute', top: '50vh', left: '55%'},
  setupadLogo: {
    height: 55,
    marginLeft: 8,
    position: 'relative',
    top: 14,
  },
}));

export const AdManager = React.memo(() => {
  const classes = useStyles();
  const auth = useStore(state => state.auth);
  const theme = useTheme();
  const {
    forcedSelfServeOnboarding,
    setForcedSelfServeOnboarding,
    publishersError,
    publishers,
  } = usePublishers();

  return (
    <>
      {auth && (publishers || publishersError) && (
        <Container
          component="main"
          maxWidth="xl"
          className={
            !forcedSelfServeOnboarding
              ? theme.palette.type === 'dark'
                ? classes.bgWrapperDark
                : classes.bgWrapperLight
              : classes.backdrop
          }
        >
          <FadeIn>
            <Container component="main" maxWidth="md" className={classes.viewWrapper}>
              {forcedSelfServeOnboarding && (
                <Tooltip title="Exit full screen">
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: 20,
                      right: 20,

                      border: '1px solid rgba(255,255,255,0.3)',
                    }}
                    onClick={() => {
                      setForcedSelfServeOnboarding(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Typography variant={'h4'} gutterBottom>
                {forcedSelfServeOnboarding && (
                  <img
                    src={theme.palette.type === 'dark' ? LogoDarkTheme : LogoWhiteTheme}
                    className={classes.setupadLogo}
                  />
                )}{' '}
                Prebid Self-Serve {forcedSelfServeOnboarding ? 'initialization' : 'configuration'}
              </Typography>
              <Typography variant={'subtitle'} display="block" gutterBottom>
                Connect your Google Ad Manager (GAM) account to Setupad Prebid self-serve
                effortlessly. Follow the onboarding steps, and the automatic 40-minute
                initialization process will prepare GAM to host Prebid connections seamlessly. Your
                current ad delivery will remain uninterrupted, and the setup will be inactive until
                the Prebid Panel configuration is complete and the codes are implemented on your
                website.
              </Typography>

              <br />

              <Grid container spacing={1} direction={'column'}>
                {publishers?.publishers?.map((publisher, i) => (
                  <List
                    disablePadding
                    className={
                      forcedSelfServeOnboarding ? classes.wrapperForcedFullScreen : classes.wrapper
                    }
                    component={GridIContainer}
                  >
                    <AdManagerListItem key={i} publisher={publisher} />
                  </List>
                ))}
              </Grid>

              {publishersError && (
                <Alert severity={'error'} style={{marginTop: 15}}>
                  {publishersError}
                </Alert>
              )}

              {publishers && <CreatePublisher />}
            </Container>
          </FadeIn>
        </Container>
      )}

      {!publishers && <CircularProgress size={64} noShrink className={classes.loader} />}
    </>
  );
});
