import React from 'react';
import {CircularProgress, Box, Grid, Typography, Tooltip} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReactGA from 'react-ga4';
import useStore from '../../../store/store';
import {mcmStatusDecoder, siteStatuses} from './../../helpers/MCMstatusDecoder';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => {
  return {
    tooltip: {
      maxWidth: 'none',
      // TODO: control max height
      padding: theme.spacing(2),
    },
    popper: {
      maxHeight: '90vh',
      overflowY: 'scroll',
    },
  };
});

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" style={{right: -4}}>
      <CircularProgress variant={'static'} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary" style={{fontSize: 10}}>
          {props.checked}/{props.total}
        </Typography>
      </Box>
    </Box>
  );
}

export const MCMquickStats = React.memo(props => {
  const classes = useStyles();
  const publishers = useStore(state => state.publishers);
  const MCMstatuses = useStore(state => state.MCMstatuses);

  const publishersLength = publishers?.publishers?.length;
  const mcmStatusesLength = Object.keys(MCMstatuses)?.length;

  const statusesCount = Object.keys(MCMstatuses)?.reduce(
    (overview, publisherId) => {
      const severity =
        mcmStatusDecoder[MCMstatuses[publisherId]?.data?.childPublisher?.accountStatus]?.severity;

      if (!MCMstatuses[publisherId]?.data?.childPublisher) {
        overview.noMCM++;
      }

      // Add site statuses
      let sites = MCMstatuses[publisherId]?.data?.sites;
      if (sites && sites.success) {
        sites.data.map(site => {
          if (siteStatuses[site.approvalStatus]?.severity === 'error') {
            overview.error++;
          }
          if (siteStatuses[site.approvalStatus]?.severity === 'warning') {
            overview.warning++;
          }
          if (siteStatuses[site.approvalStatus]?.severity === 'success') {
            overview.success++;
          }
        });
      }

      if (severity === 'error' || severity === 'warning') {
        overview.error++;
      } else if (severity === 'success') {
        overview.success++;
      }
      return overview;
    },
    {error: 0, warning: 0, success: 0, noMCM: 0}
  );

  if (publishersLength) {
    return (
      <div
        style={{
          position: 'absolute',
          top: publishersLength !== mcmStatusesLength ? 16 : 20,
          right: '17px',
        }}
      >
        <>
          <Tooltip
            onOpen={() => {
              ReactGA.event({
                category: 'MCM quick checker',
                action: 'click',
                label: 'open',
              });
            }}
            arrow
            classes={{tooltip: classes.tooltip, popperInteractive: classes.popper}}
            interactive
            title={
              <React.Fragment>
                <Grid container spacing={2} direction={'column'}>
                  {Object.keys(MCMstatuses)?.map(publisherId => {
                    let data = MCMstatuses[publisherId].data;
                    if (MCMstatuses[publisherId].success) {
                      return (
                        <>
                          <Grid item>
                            <Typography>
                              {mcmStatusDecoder[data.childPublisher.accountStatus]?.icon}{' '}
                              {MCMstatuses[publisherId].data.name} publisher:{' '}
                              {data.childPublisher.accountStatus.toLowerCase().replace(/_/g, ' ')}
                            </Typography>
                          </Grid>

                          {data.sites.success
                            ? data.sites.data
                                .filter(site => site.approvalStatus !== 'APPROVED')
                                .map((site, i) => {
                                  return (
                                    <Grid
                                      key={i}
                                      item
                                      xs={12}
                                      style={{
                                        marginLeft: 15,
                                        paddingLeft: 0,
                                        borderLeft: `1px dashed rgba(255,255,255, 0.2)`,
                                      }}
                                    >
                                      <Typography variant={'subtitle'} display={'block'}>
                                        <div
                                          style={{
                                            height: 18,
                                            width: 18,
                                            position: 'relative',
                                            top: 5,
                                            left: -9,
                                            backgroundImage: `url(https://www.google.com/s2/favicons?domain=https://${site.url}&sz=256)`,
                                            backgroundColor: 'white',
                                            backgroundPosition: 'center center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '80%',
                                            borderRadius: 4,
                                            display: 'inline-block',
                                            marginRight: 8,
                                          }}
                                        />
                                        {/* <img
                                        alt={site.url}
                                        src={`https://www.google.com/s2/favicons?domain=${site.url}`}
                                        style={{position: 'relative', top: 3, marginRight: 6}}
                                      /> */}
                                        {site.url}:{' '}
                                        {site.approvalStatus.toLowerCase().replace(/_/g, ' ')}
                                        {siteStatuses[site.approvalStatus]?.icon}
                                      </Typography>
                                    </Grid>
                                  );
                                })
                            : 'No sites'}
                        </>
                      );
                    } else {
                      return (
                        <Grid item>
                          <Typography variant={'body2'} display={'block'}>
                            {mcmStatusDecoder['CLOSED_POLICY_VIOLATION']?.icon}{' '}
                            {
                              publishers.publishers.find(
                                p => parseInt(p.publisherId) === parseInt(publisherId)
                              )?.publisherName
                            }{' '}
                            publisher: Setup Multiple Customer Management
                          </Typography>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </React.Fragment>
            }
          >
            <div>
              {publishersLength !== mcmStatusesLength && (
                <CircularProgressWithLabel
                  size={35}
                  value={(mcmStatusesLength / publishersLength) * 100}
                  checked={mcmStatusesLength}
                  total={publishersLength}
                  color="secondary"
                />
              )}
              {publishersLength === mcmStatusesLength &&
                (statusesCount.error > 0 || statusesCount.noMCM > 0) && (
                  <Badge
                    overlap={'circle'}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    color={'error'}
                    badgeContent={statusesCount.error + statusesCount.noMCM}
                  >
                    <ReportProblemIcon style={{color: 'red'}} />
                  </Badge>
                )}
              {publishersLength === mcmStatusesLength &&
                statusesCount.error === 0 &&
                statusesCount.noMCM === 0 && <CheckCircleIcon color={'secondary'} />}
            </div>
          </Tooltip>
        </>
      </div>
    );
  }
});
