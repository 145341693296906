import {useSnackbar} from 'notistack';

export default function useSnackbarSuccessOrError() {
    const snackbar = useSnackbar();

    const callSnackbarSuccessError = (isSuccess, successMessage, errorMessage) => {
        if (isSuccess) {
              snackbar.enqueueSnackbar({message: successMessage, variant: 'success'});
        } else {
            snackbar.enqueueSnackbar({
                message: errorMessage,
                variant: 'error',
            });
        }
    };

    return [callSnackbarSuccessError, snackbar.closeSnackbar];
}
