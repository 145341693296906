import React from 'react';
import ReactGA from 'react-ga4';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Alert} from '@material-ui/lab';
import {Tooltip, Button, CircularProgress} from '@material-ui/core';
import {CustomEntries} from './adstxt/CustomEntries';
import {IconButton} from '@material-ui/core';
import fileDownload from 'js-file-download';
import useStore from '../../store/store';

export function AdsListItem({domain, checkedDomain, classes, open, setOpen, accountId, index}) {
  const {missingAdsTXT} = checkedDomain || {};
  const isAddsMissing = missingAdsTXT?.length > 0;
  const setState = useStore(state => state.setState);
  const onboardingSteps = useStore(state => state.onboardingSteps);
  const visiblePopup = useStore(state => state.visiblePopup);
  const adsTxtDownloaded = useStore(state => state.adsTxtDownloaded);
  const {redirectStatus} = checkedDomain ?? {};

  return (
    <>
      <ListItem disableRipple style={{borderBottom: '1px solid #7979793b', minHeight: 65}} button>
        <ListItemIcon>
          {checkedDomain ? (
            isAddsMissing ? (
              <ReportProblemIcon style={{color: 'red'}} />
            ) : (
              <CheckCircleIcon color={'secondary'} />
            )
          ) : (
            ``
          )}
        </ListItemIcon>
        <ListItemText
          primary={`
                    ${domain.title}
                    ${
                      checkedDomain
                        ? checkedDomain?.currentAdsTXT?.success
                          ? ''
                          : ` - ads.txt not found`
                        : ''
                    }
                    ${
                      isAddsMissing
                        ? ` - ${missingAdsTXT?.length} ${
                            missingAdsTXT?.length === 1 ? 'line' : 'lines'
                          } missing`
                        : ''
                    }
                    `}
        />

        {checkedDomain && (
          <>
            <Tooltip arrow interactive title={redirectStatus.tooltip}>
              <Alert
                style={{padding: '0px 16px'}}
                icon={redirectStatus.icon}
                severity={redirectStatus.alert}
              >
                <Typography>{redirectStatus.message}</Typography>
              </Alert>
            </Tooltip>
            {accountId !== 1219 && (
              <CustomEntries
                domain={domain}
                customEntries={checkedDomain?.requiredAdstXT?.customEntries}
                redirectStatus={redirectStatus}
              />
            )}
          </>
        )}

        {checkedDomain && (
          <Tooltip
            open={
              visiblePopup === 'onboarding' &&
              onboardingSteps[1].active &&
              !adsTxtDownloaded &&
              index === 0
            }
            arrow
            title={
              <div style={{padding: 20}}>
                <Typography variant={'body2'}>
                  Download the Ads.txt file and place it in your site's root folder.
                </Typography>
              </div>
            }
            placement="bottom"
          >
            <Button
              variant="contained"
              color={'primary'}
              size={'small'}
              style={{marginLeft: 5}}
              onClick={() => {
                if (onboardingSteps[1].active) {
                  setState('adsTxtDownloaded', true);
                }
                fetch(`https://wb.setupad.com/api/getAdsTXT/${accountId}/download/${domain.id}`)
                  .then(res => res.blob())
                  .then(res => {
                    ReactGA.event({
                      category: 'Ads.txt',
                      action: 'click',
                      label: `download`,
                    });
                    fileDownload(res, `ads.txt`);
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }}
            >
              Download Ads.txt
            </Button>
          </Tooltip>
        )}

        <IconButton
          onClick={
            isAddsMissing
              ? () => {
                  // Todo: this closes custom entries backdrop
                  setOpen(open === domain.id ? null : domain.id);
                  ReactGA.event({
                    category: 'Ads.txt',
                    action: 'click',
                    label: `expand checked domain`,
                    value: domain.id,
                  });
                }
              : null
          }
        >
          {open === domain ? (
            <ExpandLess />
          ) : isAddsMissing && !redirectStatus?.success ? (
            <ExpandMore />
          ) : checkedDomain ? (
            <div style={{width: 20}} />
          ) : (
            <CircularProgress size={22} />
          )}
        </IconButton>
      </ListItem>
      {checkedDomain ? (
        <Collapse
          in={open === domain.id}
          timeout={0}
          classes={{
            container: classes.collapseContainer,
          }}
        >
          {
            // In accordion
          }
          <Grid container>
            <Grid container style={{fontFamily: 'monospace'}} spacing={1} justify="flex-end">
              {
                // Line numbers
              }
              <Grid item xs={1}>
                <Paper elevation={0}>
                  {missingAdsTXT
                    ?.filter(line => line !== '')
                    .map((line, i) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{
                            textAlign: 'right',
                            marginRight: 5,
                            marginTop: 2,
                          }}
                        >
                          <Typography variant={'caption'}>{i + 1}</Typography>
                        </Grid>
                      );
                    })}
                </Paper>
              </Grid>
              {
                // Domain names
              }
              <Grid item xs={11}>
                {missingAdsTXT
                  ?.filter(line => line !== '')
                  .map((line, i) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginLeft: 5,
                          marginTop: 2,
                        }}
                      >
                        <Typography noWrap variant={'caption'}>
                          {line}
                        </Typography>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      ) : null}
    </>
  );
}
