import React, {useCallback, useEffect, useState} from 'react';
import {Grid, Typography, LinearProgress} from '@material-ui/core';
import useStore from '../../../store/store';
import {APICall} from '../../../api/api';
import {Alert, AlertTitle} from '@material-ui/lab';

export function HistoricalDataStatus({publisher}) {
  const [error, setError] = useState(null);
  const [jobStatus, setJobStatus] = useState(null);
  const auth = useStore(state => state.auth);

  const getJobStatus = useCallback(async () => {
    try {
      setError(null);

      let orderStatus = await APICall({
        endpoint: 'getHistoricalDataStatus',
        params: `${publisher.historical_data_batch_id}`,
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        },
      });

      if (!orderStatus.success) {
        setError('Historical data status error');
        // Retry in 10 secs
        await new Promise(resolve => setTimeout(resolve, 10000));
        getJobStatus();
      } else {
        setJobStatus(orderStatus);
      }

      if (orderStatus.success && orderStatus.response?.message !== 'Not Found') {
        // fetch job status again
        // Get job data every 5 seconds, unless task is successfully done
        await new Promise(resolve => setTimeout(resolve, 5000));
        getJobStatus();
      }
    } catch (error) {
      setError('Historical data status error');
    }
  }, [publisher, auth.token]);

  useEffect(() => {
    if (publisher.historical_data_batch_id && !publisher?.historical_data_fetched) {
      getJobStatus();
    }
  }, [publisher, getJobStatus]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            {publisher.historical_data_batch_id && (
              <Typography
                variant={'h6'}
                align={'center'}
                gutterBottom
                style={{fontWeight: 300, marginBottom: 14}}
              >
                Historical data download progress:
                {/* {jobStatus.response.percentage}% */}
                {/* {JSON.stringify(jobStatus.response)} */}
              </Typography>
            )}
          </Grid>

          {publisher.historical_data_batch_id && (
            <>
              <Grid xs={12}>
                <LinearProgress
                  variant={jobStatus ? 'determinate' : 'indeterminate'}
                  value={jobStatus ? jobStatus.response.percentage : null}
                />
              </Grid>
            </>
          )}
        </Grid>

        {error && (
          <Alert severity="error">
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        )}
      </Grid>
    </>
  );
}
