import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {APICall} from '../../api/api';
import useStore from '../../store/store';

const FeedbackInOboarding = props => {
  const [feedbackText, setFeedbackText] = useState();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const setState = useStore(state => state.setState);
  const auth = useStore(state => state.auth);

  const handleChange = event => {
    setStatus(null);
    setFeedbackText(event.target.value);
  };

  const submitFeedback = async () => {
    let query = await APICall({
      endpoint: 'feedback',
      options: {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          feedbackText: feedbackText,
          pathname: window.location.pathname,
        }),
      },
    });

    if (query.success) {
      setStatus('sent');
    } else {
      setStatus('error');
    }
  };

  useEffect(() => {
    setState('disableTypingForAccountSelector', open ? true : false);
  }, [open]);

  const handleOpen = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <>
      <Box pt={2}>
        {!open && (
          <>
            <Typography variant={'subtitle'} color={'textSecondary'}>
              Need help?{' '}
            </Typography>
            <Typography
              variant={'subtitle'}
              color={'primary'}
              onClick={handleOpen}
              style={{textDecoration: 'underline', cursor: 'pointer'}}
            >
              Get in touch with us.
            </Typography>
          </>
        )}

        {open && (
          <>
            <TextField
              onChange={handleChange}
              value={feedbackText}
              autoFocus
              fullWidth
              margin={'dense'}
              label={'Send us a message'}
              variant={'outlined'}
              multiline
              rows={5}
              size={'small'}
              disabled={status === 'sent'}
            />

            <Box pt={2}>
              <Button
                disabled={status === 'sent'}
                variant={'contained'}
                color={'primary'}
                display={'block'}
                onClick={submitFeedback}
                style={{marginRight: 10}}
                size={'small'}
              >
                {status === 'sent' ? 'Message sent' : 'Send'}
              </Button>

              <Button
                variant={'outlined'}
                color={'primary'}
                display={'block'}
                onClick={handleOpen}
                size={'small'}
              >
                Close
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default FeedbackInOboarding;
