import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RealtimeTable from './RealtimeTable';
import FadeIn from 'react-fade-in/lib/FadeIn';
import {CircularProgress} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Trend from './Trend';
import Placeholder from './../misc/Placeholder';
import useRealtimeData from '../../hooks/useRealtimeData';
import {TimezonePicker} from './TimezonePicker';
import {Alert} from '@material-ui/lab';

const StyledTableCell = withStyles(theme => ({
  head: {
    background: theme.palette.graphColors.themeDarkGray,
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    fontWeight: 'bold',
    position: 'relative',
    padding: 8,
  },
  body: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  wrapper: {padding: '0 40px 40px 40px', width: '100%'},
  progress: {
    position: 'absolute',
    zIndex: 1,
  },
  pulse: {
    color: theme.palette.graphColors.revenue,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: `1px solid ${theme.palette.graphColors.revenue}`,
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4)',
      opacity: 0,
    },
  },
  customTooltip: {
    maxWidth: 'none',
    background: theme.palette.background.paper,
    boxShadow: `0 0 60px rgba(0,0,0,.2)`,
  },
  drawerPaper: {
    width: '100%',
  },
}));

const RealtimeDesktop = React.memo(props => {
  const classes = useStyles();
  const theme = useTheme();
  const {realtimeData} = useRealtimeData();

  return (
    <div className={classes.wrapper}>
      <>
        {(realtimeData?.error || !realtimeData?.yesterday?.length) && (
          <Container
            maxWidth="sm"
            style={{
              padding: 35,
              textAlign: 'left',
              position: 'relative',
            }}
          >
            <Placeholder />
            <Typography align={'center'}>{realtimeData?.error}</Typography>
          </Container>
        )}

        {!realtimeData && (
          <div style={{textAlign: 'center'}}>
            <FadeIn>
              <Typography variant={'caption'}>Loading hourly data</Typography>
              <br />
              <CircularProgress color="primary" />
            </FadeIn>
          </div>
        )}

        {!realtimeData?.error && realtimeData?.yesterday?.length > 0 && (
          <React.Fragment>
            <FadeIn>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={8}>
                  <Typography variant={'h6'} style={{marginBottom: 10}}>
                    Today vs Yesterday
                    <Tooltip
                      interactive
                      arrow
                      display={'inline'}
                      title={`Please note that the hourly result data may not be accurate, as it is subject to delays and updates. Please refer to the "Daily" section for the correct statistics, where the final data is available.`}
                    >
                      <InfoIcon
                        style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                        fontSize={'small'}
                      />
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TimezonePicker />
                </Grid>

                {realtimeData?.dataDelayedByHours > 3 && (
                  <Alert severity="warning" style={{marginBottom: 15}}>
                    The hourly data is currently being processed and is delayed by approximately{' '}
                    {parseInt(realtimeData.dataDelayedByHours)} hours.
                  </Alert>
                )}

                <RealtimeTable isMobile={props.isMobile} realtimeData={realtimeData} />

                {realtimeData.today.length > 0 && (
                  <TableContainer style={{borderRadius: 4, marginTop: 30}}>
                    <Table className={classes.table} component={Paper}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">Hour</StyledTableCell>
                          <Tooltip
                            arrow
                            interactive
                            title={`Daily revenue compared to same hour yesterday`}
                          >
                            <StyledTableCell
                              align="center"
                              style={{
                                borderBottom: `3px solid ${theme.palette.graphColors.revenue}`,
                              }}
                            >
                              Revenue
                              <InfoIcon
                                style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                                fontSize={'small'}
                              />
                            </StyledTableCell>
                          </Tooltip>

                          <StyledTableCell
                            align="center"
                            style={{
                              borderBottom: `3px solid ${theme.palette.graphColors.themeDarkGray}`,
                            }}
                          >
                            Impressions
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            style={{borderBottom: `3px solid ${theme.palette.graphColors.eCPM}`}}
                          >
                            eCPM
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            style={{
                              borderBottom: `3px dashed ${theme.palette.graphColors.viewability}`,
                            }}
                          >
                            Viewability
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            style={{borderBottom: `3px dashed ${theme.palette.graphColors.CTR}`}}
                          >
                            CTR
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {realtimeData?.today?.map((row, i) => (
                          <TableRow key={row.name}>
                            <StyledTableCell align="center">{row.dateRange}</StyledTableCell>

                            <StyledTableCell align="center">
                              {row.rev}€
                              <Trend
                                enabled={true}
                                comparisonPeriod={true}
                                current={row.rev}
                                previous={realtimeData.yesterday[i]?.rev}
                              />
                            </StyledTableCell>

                            <StyledTableCell align="center">{row.imp}</StyledTableCell>

                            <StyledTableCell align="center">
                              {row.imp <= 0 ? 0 : ((row.rev / row.imp) * 1000).toFixed(2)}€
                            </StyledTableCell>

                            <StyledTableCell align="center">{row.viewability}%</StyledTableCell>

                            <StyledTableCell align="center">{row.ctr}%</StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </FadeIn>
          </React.Fragment>
        )}
      </>
    </div>
  );
});

export default React.memo(RealtimeDesktop);
