import React, {useEffect, useState} from 'react';
import useStore from '../../store/store';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import FadeIn from 'react-fade-in';
import lscache from 'lscache';
import {APICall} from '../../api/api';
import FeedbackInOboarding from './FeedbackInOnboarding';
import ReactGA from 'react-ga4';
import usePublishers from '../../hooks/usePublishers';
import {OrderGeneratorQuickStats} from './OrderGeneratorQuickStats';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Paper,
  Collapse,
  Button,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/core';
import Draggable from 'react-draggable';

// Material icon
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const useStyles = makeStyles(theme => ({
  wrapper: {
    boxShadow: '5px 5px 70px rgba(0,0,0,0.3)',
    position: 'fixed',
    width: 380,
    bottom: 40,
    right: 60,
    zIndex: theme.zIndex.drawer + 2,
    borderRadius: 8,
    color: theme.palette.text.primary,
  },
  wrapperMinimized: {
    boxShadow: '5px 5px 70px rgba(0,0,0,0.3)',
    position: 'fixed',
    width: 200,
    bottom: 10,
    right: 60,
    zIndex: theme.zIndex.drawer + 2,
    borderRadius: 8,
    color: theme.palette.text.primary,
  },
  list: {
    maxHeight: 400,
    overflow: 'scroll',
  },
  heroImage: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    width: '100%',
  },
  heroImageMinimized: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    width: 200,
  },
  handleIcon: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    position: 'absolute',
    top: -15,
    right: 20,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  minimizeIcon: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    position: 'absolute',
    top: -15,
    right: -15,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
}));

const OnboardingWizzard = ({setDrawerOpen}) => {
  const classes = useStyles();
  const onboardingSteps = useStore(state => state.onboardingSteps);
  const onboardingStepsByType = useStore(state => state.onboardingStepsByType);
  const onboardingMinimized = useStore(state => state.onboardingMinimized);
  const visiblePopup = useStore(state => state.visiblePopup);
  const setState = useStore(state => state.setState);
  const auth = useStore(state => state.auth);
  const completedStep = useStore(state => state.completedStep);
  const activeStepIndex = useStore(state => state.activeStepIndex);
  const location = useLocation();
  const isSelfServe = auth.type >= 5 && auth.type < 8;
  const [onboardingReady, setOnboardingReady] = useState(false);
  const {forcedSelfServeOnboarding} = usePublishers();

  // Minimize onboarding if forced self serve onboarding is required
  useEffect(() => {
    if (forcedSelfServeOnboarding) {
      setState('onboardingMinimized', true);
    } else {
      setState('onboardingMinimized', false);
    }
  }, [forcedSelfServeOnboarding, setState]);

  // Logic to mark onboarding step as disabled/enabled
  // Triggered on auth change
  useEffect(() => {
    if (auth) {
      let onboardingStepsCopy = {...onboardingStepsByType[isSelfServe ? 'selfserve' : 'managed']};

      Object.keys(onboardingStepsCopy).forEach(step => {
        // A block of code which checks if step should be disabled.
        // Might differ for self-serve and managed onboarding wizzard, so we separate checks now.
        if (!isSelfServe) {
          // Check if step should be enabled (by Client UI settings)
          if (onboardingStepsCopy[step].enabledIf) {
            onboardingStepsCopy[step].disabled = auth[onboardingStepsCopy[step].enabledIf] !== 1;
          }

          // Check if billing is filled
          if (onboardingStepsCopy[step].name === 'Billing' && auth.billingDataCheck?.success) {
            onboardingStepsCopy[step].completed = true;
          }

          // Check if password was changed
          if (
            onboardingStepsCopy[step].name === 'Secure your account' &&
            auth.password_changed_by_client === 1
          ) {
            onboardingStepsCopy[step].completed = true;
          }
        } else {
          // Checks for Self-Serve accounts
          // Disable subscription step for SS Enterprise accounts
          if (auth.type === 7 && parseInt(step) === 4) {
            onboardingStepsCopy[step].disabled = true;
          }
        }
      });

      setState('onboardingSteps', onboardingStepsCopy);
      setOnboardingReady(true);

      ReactGA.event({
        category: 'Onboarding',
        action: 'loaded',
        label: auth.username,
      });
    }
  }, [auth]);

  // On completed step change, trigger lscache (local storage) update and determine next step
  useEffect(() => {
    if (Object.keys(onboardingSteps).length === 0) return;

    if (completedStep && auth) {
      lscache.set(`onboarding_step_${completedStep}_completed_${auth?.fos_user_id}`, true);

      ReactGA.event({
        category: 'Onboarding',
        action: `${onboardingSteps[completedStep].name} step completed`,
        label: auth.username,
      });
    }

    // Recheck onboarding steps and mark as completed if they are
    let onboardingStepsCopy = {...onboardingSteps};

    Object.keys(onboardingSteps).map(step => {
      if (lscache.get(`onboarding_step_${step}_completed_${auth?.fos_user_id}`)) {
        onboardingStepsCopy[step].completed = true;
        onboardingStepsCopy[step].active = false;
      }
    });

    // Determine which step to show next by finding first uncompleted step
    // Checks if step is disabled
    let firstUncompleted = Object.keys(onboardingStepsCopy).find(
      step => !onboardingStepsCopy[step].completed && !onboardingStepsCopy[step].disabled
    );

    if (firstUncompleted) {
      firstUncompleted = parseInt(firstUncompleted);
      // Set first uncompleted step as active
      onboardingStepsCopy[firstUncompleted].active = true;

      // Shown Onboarding in full size
      setState('onboardingMinimized', false);

      // Set active step index
      setState('activeStepIndex', firstUncompleted);

      // Open sidebar for fifth step
      if (firstUncompleted === 5) {
        setState('openedSidebarSection', 'reporting');
      } else {
        setState('openedSidebarSection', false);
      }

      // Open profileMenu menu for sixth step
      if (visiblePopup === 'onboarding' && onboardingSteps[6]?.active) {
        setState('profileMenuOpen', true);
      } else {
        setState('profileMenuOpen', false);
      }

      // Set updated onboardingSteps
      setState('onboardingSteps', onboardingStepsCopy);
    } else {
      if (auth.onboarding_completed !== 1) {
        ReactGA.event({
          category: 'Onboarding',
          action: 'onboarding completed',
          label: auth.username,
        });

        // Mark onboarding as completed
        APICall({
          endpoint: 'setOnboardingCompleted',
          options: {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth.token}`,
              'Cache-Control': 'max-age=0, no-cache',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
        });

        setState('auth', {...auth, onboarding_completed: 1});
      }
    }
  }, [completedStep, onboardingReady]);

  if (visiblePopup !== 'onboarding') return null;

  // Is current step link (location) is active?
  let currentStepLinkActive = location?.pathname?.includes(onboardingSteps[activeStepIndex]?.link);

  return (
    <>
      {activeStepIndex && (
        <FadeIn>
          <Draggable handle=".handle">
            <div className={onboardingMinimized ? classes.wrapperMinimized : classes.wrapper}>
              <Tooltip
                arrow
                title={onboardingMinimized ? 'Show full onboarding' : 'Minimize onboarding'}
              >
                <IconButton
                  className={classes.minimizeIcon}
                  size={'small'}
                  onClick={() => setState('onboardingMinimized', !onboardingMinimized)}
                >
                  {onboardingMinimized ? <FullscreenIcon /> : <RemoveIcon />}
                </IconButton>
              </Tooltip>

              <img
                alt={'Onboarding'}
                src={require(`./../img/onboarding/${
                  isSelfServe ? 'selfserve' : 'managed'
                }/${activeStepIndex}.svg`)}
                className={onboardingMinimized ? classes.heroImageMinimized : classes.heroImage}
              />

              <Paper
                style={{
                  padding: onboardingMinimized ? 10 : 25,
                  maxHeight: 'calc(110vh - 420px)',
                  overflowX: 'scroll',
                }}
                elevation={0}
              >
                <Grid container spacing={2}>
                  {!onboardingMinimized && activeStepIndex !== 8 && (
                    <Grid item xs={12}>
                      <>
                        <Typography
                          style={{fontWeight: 'bold'}}
                          gutterBottom={!currentStepLinkActive}
                        >
                          {currentStepLinkActive
                            ? 'Please complete this step:'
                            : isSelfServe
                            ? 'Welcome to Setupad Self-Serve! 👋'
                            : 'Welcome to Setupad Client UI! 👋'}
                        </Typography>
                        {!currentStepLinkActive && (
                          <Typography variant={'body'} color={'textSecondary'}>
                            {isSelfServe
                              ? `We're thrilled to have you on board! We've put together this guide to make your onboarding with us as smooth as possible. Let's get started!`
                              : `  This is an onboarding guide designed to help you get started. Please
                          follow these steps and start monetizing your website successfully!`}
                          </Typography>
                        )}
                      </>
                    </Grid>
                  )}

                  {onboardingMinimized && (
                    <Grid item xs={12}>
                      <Typography variant={'caption'} style={{fontWeight: 'bold'}}>
                        Onboarding: {onboardingSteps[activeStepIndex].name}
                      </Typography>
                    </Grid>
                  )}

                  {!onboardingMinimized && (
                    <Grid item xs={12}>
                      <List disablePadding>
                        {Object.keys(onboardingSteps)
                          .map(Number)
                          .map(i => {
                            // Dont render step if its disabled
                            if (onboardingSteps[i].disabled) return;

                            if (
                              // Dont render step if its not active AND active step link (location) is active
                              (currentStepLinkActive && i !== activeStepIndex) ||
                              // Dont render other than last step if last step is active
                              (activeStepIndex === 8 && i !== 8)
                            )
                              return;

                            // Dont render last step (completed) if it's not active
                            if (i === 8 && activeStepIndex !== 8) return;

                            return (
                              <>
                                <FadeIn>
                                  <ListItem
                                    key={i}
                                    style={{
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                    }}
                                    dense
                                    button
                                    disabled={
                                      !onboardingSteps[i].active && !onboardingSteps[i].completed
                                    }
                                  >
                                    <ListItemIcon style={{minWidth: 35}}>
                                      {onboardingSteps[i].completed || i === 8 ? (
                                        <CheckCircleIcon color={'secondary'} />
                                      ) : onboardingSteps[i].active ? (
                                        <RadioButtonCheckedIcon color={'primary'} />
                                      ) : (
                                        <RadioButtonUncheckedIcon color={'primary'} />
                                      )}
                                    </ListItemIcon>

                                    <ListItemText id={i} primary={onboardingSteps[i].name} />
                                    {i === 1 && isSelfServe && (
                                      <div style={{position: 'relative', top: -30}}>
                                        <OrderGeneratorQuickStats />
                                      </div>
                                    )}
                                  </ListItem>

                                  <Collapse in={onboardingSteps[i].active}>
                                    <Grid
                                      container
                                      spacing={2}
                                      style={{paddingLeft: 0, paddingBottom: 15}}
                                    >
                                      <Grid item xs={12}>
                                        <Typography variant={'caption'} color={'textSecondary'}>
                                          {onboardingSteps[i].description}
                                        </Typography>

                                        <Button
                                          size={'small'}
                                          style={{marginTop: 10}}
                                          endIcon={
                                            currentStepLinkActive ? (
                                              <CircularProgress size={14} />
                                            ) : (
                                              undefined
                                            )
                                          }
                                          fullWidth
                                          variant={
                                            currentStepLinkActive &&
                                            onboardingSteps[i].buttonTextActiveLink
                                              ? 'text'
                                              : 'contained'
                                          }
                                          color={'primary'}
                                          component={Link}
                                          to={onboardingSteps[i].link}
                                          target={
                                            onboardingSteps[i].externalLink ? '_blank' : '_self'
                                          }
                                          onClick={e => {
                                            // Hack for 7 and 8 steps to mark it as completed on click
                                            if (i === 7) {
                                              setState('completedStep', i);
                                            }
                                            if (i === 8) {
                                              e.preventDefault();
                                              setState('completedStep', i);
                                            }

                                            // if SS and last step, minimize drawer and complete step
                                            if (i === 5 && isSelfServe) {
                                              setDrawerOpen(false);
                                              setState('completedStep', i);
                                            }
                                          }}
                                        >
                                          {currentStepLinkActive &&
                                          onboardingSteps[i].buttonTextActiveLink
                                            ? onboardingSteps[i].buttonTextActiveLink
                                            : onboardingSteps[i].buttonText}
                                        </Button>

                                        {onboardingSteps[i].skippable && (
                                          <Button
                                            style={{marginTop: 10}}
                                            variant={'outlined'}
                                            fullWidth
                                            color={'primary'}
                                            size={'small'}
                                            onClick={() => setState('completedStep', i)}
                                          >
                                            Skip
                                          </Button>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Collapse>
                                </FadeIn>
                              </>
                            );
                          })}
                      </List>
                      {!isSelfServe && auth.ticketing !== 1 && <FeedbackInOboarding />}
                      {(isSelfServe || auth.ticketing === 1) && (
                        <Grid item xs={12} style={{marginBottom: -10, marginTop: 5}}>
                          <Typography variant={'subtitle'} color={'textSecondary'}>
                            Need help?
                            <Button
                              component={Link}
                              startIcon={<ConfirmationNumberIcon />}
                              to={'/support'}
                              color={'primary'}
                              variant="text"
                              size={'small'}
                              style={{top: -2, position: 'relative', marginLeft: 7}}
                            >
                              Visit Support section
                            </Button>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </div>
          </Draggable>
        </FadeIn>
      )}
    </>
  );
};

export default OnboardingWizzard;
