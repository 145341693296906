import React, {useState} from 'react';
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  Backdrop,
  IconButton,
  Container,
  CircularProgress,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import useStore from '../../../store/store';
import {APICall} from '../../../api/api';
import {makeStyles} from '@material-ui/core/styles';
import FadeIn from 'react-fade-in/lib/FadeIn';
import useRefreshMenu from '../../../hooks/useRefreshMenu';
import {Close} from '@material-ui/icons';
import usePublishers from '../../../hooks/usePublishers';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.name === 'darkTheme' ? '#fff' : 'inherit',
    backgroundColor: theme.name === 'darkTheme' ? 'rgb(26 24 29 / 95%)' : 'rgb(255 255 255 / 95%)',
    backgroundSize: `200% 200%`,
    animation: '$scaleUpDown 30s ease infinite',
  },
  promoImage: {
    background: `url(https://setupad.com/wp-content/themes/setupad/assets/img/landing/setupad-chrome-extension.svg)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 230,
  },
}));

export function CreatePublisher() {
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(null);
  const [loading, setLoading] = useState(null);
  const {refreshMenu} = useRefreshMenu();
  const auth = useStore(state => state.auth);
  const classes = useStyles();
  const {getPublishers, publishers} = usePublishers();

  const createNewPublisher = async () => {
    setLoading(true);
    setError(null);
    let response = await APICall({
      endpoint: 'createPublisher',
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    if (response.success) {
      setDialogOpen(false);
      refreshMenu();
      getPublishers();
    } else {
      setError('Error, try again later.');
    }

    setLoading(false);
  };

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <>
      {/* Disable new publisher creation when at least one of the publishers is not initialized */}
      {!publishers?.publishers?.find(publisher => publisher.gam_initialized !== 1) && (
        <FadeIn>
          <Grid container spacing={2} justifyContent={'flex-end'}>
            <Grid item xs={6}>
              <Tooltip
                arrow
                title={
                  'You can import and manage multiple sites from the initialized Ad Manager network, but to setup Self-Serve for another network, you have to create new account by clicking on this button.'
                }
              >
                <Button onClick={toggleDialog} variant={'outlined'} color={'primary'} fullWidth>
                  {publishers.length > 0
                    ? 'Want to connect another Ad network?'
                    : 'Connect another GAM account'}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </FadeIn>
      )}

      <Backdrop className={classes.backdrop} open={dialogOpen}>
        <Container maxWidth={'md'}>
          <IconButton
            style={{
              float: 'right',
              border: '1px solid rgba(255,255,255,0.3)',
            }}
            onClick={toggleDialog}
            color={'inherit'}
          >
            <Close />
          </IconButton>
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item xs={6}>
              <Typography variant={'h4'} gutterBottom>
                Connect another GAM account
              </Typography>
              <Typography gutterBottom>
                You must sign in to another GAM account and initiate the Setupad service. Click on
                the button below and follow the initialization instructions.
              </Typography>
            </Grid>

            <Grid item xs={6} className={classes.promoImage} />

            <Grid item xs={12} style={{textAlign: 'center', marginTop: 20}}>
              <Button
                variant="contained"
                color={'primary'}
                className={classes.button}
                onClick={createNewPublisher}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={16} /> : undefined}
              >
                Connect another GAM account
              </Button>

              <Button
                color={'primary'}
                style={{marginLeft: 10}}
                onClick={toggleDialog}
                disabled={loading}
              >
                Cancel
              </Button>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        </Container>
      </Backdrop>
    </>
  );
}
