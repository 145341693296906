import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import lscache from 'lscache';
import Logo from './../components/img/setupad_client_final.svg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles/index';
import {APICall} from '../api/api';
import Alert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles(theme => ({
  loader: {
    position: 'absolute',
    zIndex: 99999,
    top: '0',
    width: '100%',
    left: 0,
  },
  additionalButtons: {
    margin: theme.spacing(1, 0, 1),
  },
}));

function ResetPassword(props) {
  const classes = useStyles();
  const [input, setInput] = useState({
    email: '',
    password: '',
    confirmedPassword: '',
    token: props.match.params.token,
    userId: props.match.params.userId,
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState(lscache.get('auth'));

  let onSubmit = async event => {
    event.preventDefault();

    if (input.email === '' || !input.email.includes('@')) {
      setError('Invalid email address.');
      return;
    }

    if (input.password.length < 8) {
      setError('Password too short. Please use at least 8 symbols.');
      return;
    }

    if (input.password === '' || input.password !== input.confirmedPassword) {
      setError('Password and confirm password does not match.');
      return;
    }

    setLoading(true);

    try {
      let response = await APICall({
        endpoint: 'resetPassword',
        options: {
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });
      if (response.success) {
        setSuccess(true);
        setLoading(false);

        if (auth) {
          let authData = lscache.get('auth');

          authData.password_changed_by_client = 1;
          lscache.set('auth', authData);
        }

        if (window.PasswordCredential && navigator.credentials) {
          let c = new window.PasswordCredential({
            id: props.match.params.username,
            password: input.password,
          });
          return navigator.credentials.store(c);
        }
      } else {
        setError(response.error);
        setLoading(false);
      }
    } catch (error) {
      console.log('Error', error);
      setError('Error, try again');
    }

    setLoading(false);
  };

  const handleInputChange = prop => event => {
    setError(null);
    setInput({...input, [prop]: event.target.value});
  };

  return (
    <>
      {loading && <LinearProgress variant="query" className={classes.loader} position={'fixed'} />}

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '80vh'}}
      >
        <Container
          component="main"
          maxWidth="xs"
          minHeight="100%"
          style={
            auth ? {borderRadius: '4px', border: '1px solid #7979793b', padding: 50} : {padding: 50}
          }
        >
          <div>
            {!auth && (
              <img
                src={
                  !lscache.get('theme') || lscache.get('theme') === 'dark'
                    ? 'https://setupad.com/wp-content/themes/setupad/assets/img/new_company_logo.svg'
                    : Logo
                }
                style={{width: '100%'}}
              />
            )}
            <form action="/" method="POST" noValidate onSubmit={onSubmit}>
              <Typography variant={'h6'} align={'center'}>
                {auth ? (
                  <div>
                    <LockOpenIcon color={'primary'} fontSize={'large'} />
                    <br />
                    Change password
                  </div>
                ) : (
                  'Password reset'
                )}
              </Typography>
              <TextField
                style={{marginTop: '15px'}}
                variant="outlined"
                margin="normal"
                fullWidth
                // autoFocus
                autoComplete={'email'}
                id="email"
                label="Confirm email address"
                name="email"
                type="email"
                value={input.email}
                onChange={handleInputChange('email')}
              />
              <TextField
                style={{display: 'none'}}
                autoComplete={'username'}
                id="username"
                label="username"
                name="username"
                value={props.match.params.username}
              />
              <TextField
                style={{marginTop: '15px'}}
                variant="outlined"
                margin="normal"
                fullWidth
                autoComplete={'new-password'}
                id="password"
                label="New password"
                name="password"
                type="password"
                value={input.password}
                onChange={handleInputChange('password')}
              />
              <TextField
                style={{marginTop: '15px'}}
                variant="outlined"
                margin="normal"
                fullWidth
                id="confirmed-password"
                autoComplete={'new-password'}
                label="Confirm new password"
                name="confirmedPassword"
                type="password"
                value={input.confirmedPassword}
                onChange={handleInputChange('confirmedPassword')}
              />
              {!success && (
                <Button
                  disabled={loading}
                  className={classes.additionalButtons}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {auth ? 'Confirm change' : 'Reset password'}
                </Button>
              )}

              {error && (
                <Box mt={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}

              {success && (
                <Box mt={2}>
                  <Alert severity="success">
                    Password changed successfully!{' '}
                    {!auth && (
                      <Link style={{color: 'inherit'}} to="/login">
                        Login to Client UI
                      </Link>
                    )}
                  </Alert>
                </Box>
              )}

              {/*<Box mt={2}>*/}
              {/*<Typography variant="body2" align="center">*/}
              {/*Or cantact us contact@setupad.com*/}
              {/*</Typography>*/}
              {/*</Box>*/}
            </form>
          </div>
        </Container>
      </Grid>
    </>
  );
}

export default ResetPassword;
