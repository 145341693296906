import React, {useEffect, useRef, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment';
import {Skeleton} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(5),
  },
  mediaSmall: {
    height: 167,
    borderRadius: 20,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 6px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    backgroundPosition: `center !important`,
    backgroundSize: `cover !important`,
  },
  card: {
    marginBottom: theme.spacing(2),
    background: 'none!important',
  },
  cardContent: {
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    background: theme.palette.background.paper,
    fontWeight: `700!important`,
    zIndex: 2,
    margin: '0 12px',
    top: -30,
    position: 'relative',
    padding: 15,
    borderRadius: 20,
  },
}));

const ArticleCardSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={3} className={classes.article}>
        <Typography gutterBottom variant={'caption'} color={'textSecondary'}>
          <Skeleton variant="text" width={100} />
        </Typography>

        <Card elevation={0} className={classes.card}>
          <CardActionArea
            styles={{
              focusHighlight: provided => ({
                ...provided,
                opacity: 0,
                '&:hover': {
                  backgroundColor: 'red',
                },
              }),
            }}
          >
            <Skeleton variant="rect" className={classes.mediaSmall} />
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="caption" component="h2" color={'textSecondary'}>
                <Skeleton variant="text" width={50} />
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                component="h2"
                color={'textSecondary'}
              ></Typography>
              <Typography gutterBottom variant="body2" component="h2" style={{fontWeight: 600}}>
                <Skeleton variant="text" height={40} />
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

export default ArticleCardSkeleton;
