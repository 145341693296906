import React, {useState, useEffect} from 'react';
import {APICall} from '../../api/api';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import ReactGA from 'react-ga4';
import {Skeleton} from '@material-ui/lab';

const useStyles = makeStyles(theme => {
  return {
    tooltip: {
      maxWidth: 'none',
      padding: theme.spacing(2),
    },
    countWrapper: {
      background: theme.palette.background.default,
      borderRadius: 4,
      padding: theme.spacing(0.8),
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      textAlign: 'center',
    },
  };
});

export const ReportsQuickStats = React.memo(props => {
  const classes = useStyles();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function loadData() {
      let reportedAds = await APICall({
        endpoint: 'extensionReports',
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${props.login.token}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      setData(reportedAds);
    }

    loadData();
  }, []);

  return (
    <div>
      {!data && <Skeleton height={28} width={25} />}

      {data && (
        <Tooltip
          onOpen={() => {
            ReactGA.event({
              category: 'Blocked ads quick checker',
              action: 'click',
              label: 'open',
            });
          }}
          onClose={() => {
            ReactGA.event({
              category: 'Blocked ads quick checker',
              action: 'click',
              label: 'close',
            });
          }}
          arrow
          classes={{tooltip: classes.tooltip}}
          interactive
          title={
            <React.Fragment>
              <Grid container spacing={1} direction={'column'}>
                {!data.success && 'Error loading blocked ads status'}

                {data.success &&
                  data.reports.length > 0 &&
                  !data.reports?.find(report => !report.closed) && (
                    <>{data.reports.length} blocked ads</>
                  )}

                {data.success && data.reports.length === 0 && `No blocked ads`}

                {data.success &&
                  data.reports?.find(report => !report.closed) &&
                  data.reports
                    .filter(report => !report.closed)
                    .map(report => (
                      <Grid item>
                        <Typography variant={'caption'}>
                          Report ID {report.id} "{report.comment}" will be blocked soon.
                        </Typography>
                      </Grid>
                    ))}
              </Grid>
            </React.Fragment>
          }
        >
          <div className={classes.countWrapper}>{data.success && data.reports.length}</div>
        </Tooltip>
      )}
    </div>
  );
});
