import React, {useEffect, useState} from 'react';
import {APICall} from '../../api/api';
import countries from './../../components/misc/countries.json';
import languages from './../../components/misc/languages.json';
import cmpLayouts from './../../components/misc/cmpLayouts.json';
import lscache from 'lscache';
import copyToClipboard from 'copy-to-clipboard';
import {Alert, Skeleton} from '@material-ui/lab';
import {useTheme, makeStyles} from '@material-ui/core/styles/index';
import {CMPvendorsTable} from './CMPvendorsTable';
import LinkIcon from '@material-ui/icons/Link';
import Link from '@material-ui/core/Link/index';
import Promo from './CMP/Promo';

import {
  TextField,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  Collapse,
  ListItemText,
  ListItem,
  ListItemIcon,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core';

import {
  FileCopy as FileCopyIcon,
  FindInPage as FindInPageIcon,
  ExpandLess,
  ExpandMore,
  Info as InfoIcon,
  Sync as SyncIcon,
} from '@material-ui/icons';

import LogoDarkTheme from './../../components/img/setupad-logo-UA-white.svg';
import LogoWhiteTheme from './../../components/img/setupad-logo-UA-darkblue.svg';

const useStyles = makeStyles(theme => ({
  domainFavoriteIcon: {
    height: 20,
    width: 20,
    position: 'relative',
    top: 3,
    backgroundColor: 'white',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '80%',
    borderRadius: 4,
    display: 'inline-block',
    marginRight: 8,
  },
  alert: {padding: '0px 14px', marginRight: 5},
  infoIcon: {
    position: 'relative',
    fontSize: 18,
    top: 2,
  },
}));

export function CMPlistItem({
  domain,
  checkedDomain,
  open,
  setOpen,
  token,
  id,
  refreshCurrentCMPstatus,
  vendorsList,
  index,
}) {
  const theme = useTheme();
  const [data, setData] = useState(checkedDomain);
  const [configurationOpen, setConfigurationOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (checkedDomain) {
      setData({
        ...checkedDomain,
        enabledVendorIds: checkedDomain.enabledVendorIds ?? [],
        disabledVendorIds: checkedDomain.disabledVendorIds ?? [],
      });
    }
  }, [checkedDomain]);

  let isSetupadCMP = data?.currentCMPstatus?.cmpId === 379;
  let forceOpen = (!data?.currentCMPstatus && !open) || (data && !isSetupadCMP && !open);

  const handleChange = (field, value) => {
    setUpdated(false);
    setData({
      ...data,
      [field]: value,
    });
  };

  const updateCMP = async () => {
    setDisabled(true);
    try {
      let postData = {
        companyName: data.companyName,
        resurfacePlacement: data.resurfacePlacement,
        isoCode: data.isoCode,
        resurfaceTime: data.resurfaceTime,
        darkMode: data.darkMode,
        domainId: id,
        language: data.language,
        singleParentDomainCookie: data.singleParentDomainCookie ? true : false,
        layout: data.layout ? data.layout : 1,
        enabledVendorIds: data.enabledVendorIds,
        disabledVendorIds: data.disabledVendorIds,
        rejectConsentBtn: data.rejectConsentBtn ? data.rejectConsentBtn : false,
        googleConsentMode: data.googleConsentMode ? true : false,
      };

      let submitResult = await APICall({
        endpoint: 'addCMP',
        options: {
          method: 'POST',
          body: JSON.stringify(postData),
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });

      if (submitResult.error) {
        setUpdated(false);
        setError(submitResult.error);
      } else {
        setUpdated(true);
        setError(null);

        setData({
          ...data,
          success: true,
        });

        // Set lscache for a minute so that on getCMP newest data would be displayed as azure storage lags for a few seconds
        lscache.set(`cmp_data_${id}`, JSON.stringify(postData), 1);
      }
    } catch (e) {
      setError(e.message);
      setUpdated(false);
      lscache.remove(`cmp_data_${id}`);
    }
    setDisabled(false);
  };

  let RefreshAction = () => (
    <IconButton
      size={'small'}
      color={'inherit'}
      style={{marginRight: 5}}
      disabled={!data || data?.currentCMPstatus === 'loading'}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        refreshCurrentCMPstatus(checkedDomain?.id, true);
      }}
    >
      <SyncIcon />
    </IconButton>
  );

  return (
    <>
      <ListItem
        button
        style={{borderTop: `1px solid ${theme.palette.graphColors.themeLightGrayOpaque}`}}
        onClick={() => {
          setOpen(open === domain || forceOpen ? 'closed' : domain);
        }}
      >
        <ListItemIcon>
          <div
            className={classes.domainFavoriteIcon}
            style={{
              backgroundImage: `url(https://www.google.com/s2/favicons?domain=https://${domain}&sz=256)`,
            }}
          />
        </ListItemIcon>

        <ListItemText primary={domain} />

        {data && (
          <>
            {!data.currentCMPstatus && (
              <Alert className={classes.alert} severity={'error'} action={<RefreshAction />}>
                <Typography>CMP not detected</Typography>
              </Alert>
            )}
            {data.currentCMPstatus && isSetupadCMP && (
              <Alert className={classes.alert} severity={'success'} action={<RefreshAction />}>
                <Typography>Setupad CMP detected</Typography>
              </Alert>
            )}
            {data.currentCMPstatus && !isSetupadCMP && data.currentCMPstatus !== 'loading' && (
              <Alert className={classes.alert} severity={'info'} action={<RefreshAction />}>
                <Typography>Non-Setupad CMP detected</Typography>
              </Alert>
            )}
          </>
        )}

        {(!data || data.currentCMPstatus === 'loading') && (
          <Skeleton
            className={classes.alert}
            variant={'rect'}
            width={235}
            height={42}
            style={{
              borderRadius: 4,
              marginRight: 15,
            }}
          />
        )}

        <IconButton disabled={!data}>
          {data ? (
            open === domain || !data.currentCMPstatus ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : (
            <CircularProgress size={'1.2rem'} disableShrink />
          )}
        </IconButton>
      </ListItem>

      {data ? (
        <Collapse in={open === domain || forceOpen} timeout={0} style={{width: '100%'}}>
          <Grid container spacing={2} style={{padding: 30, paddingTop: 10}}>
            {/* Promo component */}
            {!isSetupadCMP && data.currentCMPstatus !== 'loading' && !configurationOpen && (
              <Promo domain={domain} setConfigurationOpen={setConfigurationOpen} />
            )}

            {(isSetupadCMP || configurationOpen) && (
              <>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>
                    <img
                      src={theme.name === 'darkTheme' ? LogoDarkTheme : LogoWhiteTheme}
                      alt={'Setupad'}
                      gutterBottom
                      style={{
                        height: 36,
                        position: 'relative',
                        top: 10,
                      }}
                    />{' '}
                    CMP
                  </Typography>

                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      display={'block'}
                      color={'textSecondary'}
                      style={{marginTop: 10}}
                    >
                      If your website doesn't have an active CMP, seamlessly integrate Setupad
                      CMP—an IAB and Google-approved solution. Select the domain and fill out the
                      form to ensure your site complies with all cookie regulations.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Company name"
                    value={data.companyName}
                    onChange={({target: {value}}) => handleChange('companyName', value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={{width: '100%'}}>
                    <InputLabel id="country-label">
                      <Typography variant={'caption'}>
                        <Typography variant={'body2'}>Country *</Typography>
                      </Typography>
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="country-label"
                      value={data.isoCode}
                      onChange={event => handleChange('isoCode', event.target.value)}
                    >
                      {countries.map(country => (
                        <MenuItem value={country.id}>{country.text}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={{width: '100%'}}>
                    <InputLabel id="select-country">
                      <Typography variant={'body2'}>Resurface Time (weeks)*</Typography>
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="select-country"
                      id="isoCode"
                      value={data.resurfaceTime}
                      onChange={event => handleChange('resurfaceTime', event.target.value)}
                    >
                      {[1, 2, 3, 4].map(i => (
                        <MenuItem value={i}>{i}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={{width: '100%'}}>
                    <InputLabel id="layout-label">
                      <Typography variant={'caption'}>
                        <Tooltip
                          arrow
                          title={
                            'Mobile layouts are unavailable because the full modal CMP content required by IAB policies would not fit in certain layouts, therefore only popup is available for mobile devices.'
                          }
                        >
                          <Typography variant={'body2'}>
                            Layout * <InfoIcon className={classes.infoIcon} />
                          </Typography>
                        </Tooltip>
                      </Typography>
                    </InputLabel>
                    <Select
                      fullWidth
                      disabled={data.disabled}
                      labelId="layout-label"
                      value={data.layout ? data.layout : 1}
                      onChange={event => handleChange('layout', event.target.value)}
                    >
                      {cmpLayouts.map(layout => (
                        <MenuItem value={layout.value}>{layout.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={{width: '100%'}}>
                    <InputLabel id="language-label">
                      <Typography variant={'caption'}>
                        <Typography variant={'body2'}>Language *</Typography>
                      </Typography>
                    </InputLabel>
                    <Select
                      fullWidth
                      disabled={data.disabled}
                      labelId="language-label"
                      value={data.language ? data.language : 'EN'}
                      onChange={event => handleChange('language', event.target.value)}
                    >
                      {languages.map(language => (
                        <MenuItem value={language.id}>{language.text}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{width: '100%'}}>
                    <InputLabel id="resurface-placement">
                      <Typography variant={'body2'}>Resurface button placement *</Typography>
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="select-country"
                      id="isoCode"
                      value={data.resurfacePlacement}
                      onChange={event => handleChange('resurfacePlacement', event.target.value)}
                    >
                      {[
                        'top left',
                        'top right',
                        'middle left',
                        'middle right',
                        'bottom left',
                        'bottom right',
                        'custom link',
                      ].map((placement, i) => (
                        <MenuItem value={i + 1}>{placement}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {data.resurfacePlacement === 7 && (
                    <div
                      style={{
                        fontFamily: 'monospace',
                        marginTop: 20,
                        padding: 20,
                        borderRadius: 4,
                        background: theme.palette.background.paper,
                      }}
                    >
                      {
                        '<a href="#" onclick="window.manualCmpResurface();return false;"> Your custom text, e.g, "Resurface CMP" </a>'
                      }
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    IAB vendor list{' '}
                    <Tooltip
                      arrow
                      title={
                        "You can choose the IAB partners you prefer to collaborate with by clicking opt-in. This table provides information on the vendor's IAB ID, operational environment, territorial scope, and whether they engage in international data transfers outside the EU/EEA."
                      }
                    >
                      <span>
                        <InfoIcon className={classes.infoIcon} />
                      </span>
                    </Tooltip>
                  </Typography>
                  {vendorsList && (
                    <CMPvendorsTable vendorsList={vendorsList} data={data} setData={setData} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Tooltip
                      arrow
                      title={
                        'This feature allows CMP to share data across all subdomains and their parent domains. This means if you change your consent for one subdomain, the changes will automatically apply to all associated subdomains and the parent domain. If you have granted consent on one subdomain or the parent domain, the CMP will not popup again on any of the associated subdomains or the parent domain.'
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            labelId="darkTheme"
                            checked={data.singleParentDomainCookie}
                            color={'primary'}
                            onChange={({target: {checked}}) =>
                              handleChange('singleParentDomainCookie', checked)
                            }
                          />
                        }
                        label={
                          <>
                            Share consent across parent domain and all subdomains{' '}
                            <InfoIcon className={classes.infoIcon} />
                          </>
                        }
                      />
                    </Tooltip>
                  </FormGroup>

                  <FormGroup row>
                    <Tooltip
                      arrow
                      title={
                        "We advise against utilizing this option unless it is explicitly mandated by your country's jurisdiction, which may require a button to reject all consent. Enabling such a feature will result in the automatic rejection of consent by users, potentially leading to the disablement of certain advertisements for those users. Careful consideration should be given to the potential impact on the advertising services before implementing such a mechanism."
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            labelId="rejectConsentBtn"
                            checked={data.rejectConsentBtn}
                            color={'primary'}
                            onChange={({target: {checked}}) =>
                              handleChange('rejectConsentBtn', checked)
                            }
                          />
                        }
                        label={
                          <>
                            Consent rejection button <InfoIcon className={classes.infoIcon} />
                          </>
                        }
                      />
                    </Tooltip>
                  </FormGroup>

                  <FormGroup row>
                    <Tooltip
                      arrow
                      interactive
                      title={
                        <React.Fragment>
                          This module integration enables Google Consent mode, activating user
                          consent features across Google products like Google Analytics. For this
                          module to work correctly, using Google tag (gtag.js) on your website is
                          mandatory.
                          <br />
                          <Link
                            href="https://developers.google.com/tag-platform/gtagjs/install"
                            target={'_blank'}
                            color="primary"
                          >
                            Google tag (gtag.js) installation guide
                          </Link>
                        </React.Fragment>
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            labelId="googleConsentMode"
                            checked={
                              data.googleConsentMode === undefined ? true : data.googleConsentMode
                            }
                            color={'primary'}
                            onChange={({target: {checked}}) =>
                              handleChange('googleConsentMode', checked)
                            }
                          />
                        }
                        label={
                          <>
                            Google consent mode module <InfoIcon className={classes.infoIcon} />
                          </>
                        }
                      />
                    </Tooltip>
                  </FormGroup>

                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          labelId="darkTheme"
                          checked={data.darkMode}
                          color={'primary'}
                          onChange={({target: {checked}}) => handleChange('darkMode', checked)}
                        />
                      }
                      label="Dark mode"
                    />
                  </FormGroup>

                  <Button
                    variant="contained"
                    color={'primary'}
                    style={{marginTop: 20, marginBottom: 25}}
                    disabled={
                      disabled ||
                      !data.companyName ||
                      !data.isoCode ||
                      !data.resurfaceTime ||
                      !data.resurfacePlacement
                    }
                    onClick={updateCMP}
                  >
                    {disabled
                      ? 'Please wait'
                      : data.success === false
                      ? 'Get CMP code'
                      : updated
                      ? 'Updated'
                      : 'Update Settings'}
                  </Button>

                  <Button
                    variant="outlined"
                    color={'primary'}
                    endIcon={<FindInPageIcon />}
                    style={{marginTop: 20, marginBottom: 25, marginLeft: 15}}
                    disabled={disabled || !data.companyName || !data.resurfacePlacement}
                    onClick={() => {
                      if (window.manualCmpPreview) {
                        window.manualCmpPreview(
                          true,
                          data.companyName,
                          data.darkMode ? true : false,
                          data.resurfacePlacement,
                          data.language ? data.language : 'EN',
                          data.layout ? data.layout : 1,
                          data.rejectConsentBtn,
                          data.enabledVendorIds,
                          data.disabledVendorIds
                        );
                      } else {
                        alert('CMP preview is not available as library is not loaded');
                      }
                    }}
                  >
                    Show preview
                  </Button>

                  {error && (
                    <Typography variant={'h6'} color={'error'} gutterBottom>
                      {error}
                    </Typography>
                  )}
                  {data?.success !== false && (
                    <>
                      <Typography gutterBottom>Insert this code to {domain} header:</Typography>
                      <Grid
                        container
                        direction={'row'}
                        style={{
                          fontFamily: 'monospace',
                          padding: 20,
                          borderRadius: 4,
                          background: theme.palette.background.paper,
                        }}
                      >
                        <Grid item xs={11}>
                          <Typography variant={'caption'}>
                            {`<script src="https://cmp.setupcmp.com/cmp/cmp/cmp-stub.js" data-prop-id="${checkedDomain.id}"></script>`}
                          </Typography>
                          <br />
                          <Typography variant={'caption'}>
                            {`<script src="https://cmp.setupcmp.com/cmp/cmp/cmp-v1.js" data-prop-stpd-cmp-id="${checkedDomain.id}" async></script>`}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title={'Copy to clipboard'}>
                            <IconButton style={{float: 'right'}}>
                              <FileCopyIcon
                                onClick={() =>
                                  copyToClipboard(`
                        <script src="https://cmp.setupcmp.com/cmp/cmp/cmp-stub.js" data-prop-id="${checkedDomain.id}"></script>
                        <script src="https://cmp.setupcmp.com/cmp/cmp/cmp-v1.js" data-prop-stpd-cmp-id="${checkedDomain.id}" async></script>
                        `)
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          color={'primary'}
                          endIcon={<LinkIcon />}
                          style={{marginTop: 20, marginBottom: 25}}
                          href={`https://code.setupad.io/cmp/${id}/${domain}`}
                          target="_blank"
                        >
                          Share instructions via link
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Collapse>
      ) : null}
    </>
  );
}
