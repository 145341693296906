import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {SocketProvider} from './context/SocketProvider';
import {GoogleOAuthProvider} from '@react-oauth/google';
Sentry.init({
  dsn: 'https://e772cfe1a6f24ec9b34762f3ebbd6968@o960297.ingest.sentry.io/5908586',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <GoogleOAuthProvider clientId="110167923738-nsajf53a3svvskn3qlp2hapclg7p6924.apps.googleusercontent.com">
    <SocketProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SocketProvider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
