import React, {useEffect, useState, memo, useCallback} from 'react';
import {Typography, Grid, Container, List} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {APICall} from '../api/api';
import lscache from 'lscache';
import {CMPlistItem} from '../components/subcomponents/CMPlistItem';
import useStore from '../store/store';
import useVendors from '../hooks/useVendors';

const GridIContainer = props => <Grid container {...props} />;

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    borderRadius: '4px',
    border: '1px solid #7979793b',
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
}));

export const CMP = memo(({login, domains}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [checked, setChecked] = useState([]);
  const auth = useStore(state => state.auth);
  const isSelfServe = auth?.type >= 5 && auth?.type < 8;
  const setState = useStore(state => state.setState);
  const vendorsList = useVendors();

  const getCurrentCMPstatus = async (domainId, force = null) => {
    // Check current CMP status for domain
    let currentCMPstatus = await APICall({
      endpoint: 'checkCurrentCMP',
      params: `${domainId}${force ? '?force=true' : ''}`,
      options: {
        method: 'get',
        headers: {
          Authorization: `Bearer ${login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });

    return currentCMPstatus;
  };

  const refreshCurrentCMPstatus = async (domainId, force = null) => {
    setChecked(prevChecked => ({
      ...prevChecked,
      [domainId]: {
        ...prevChecked[domainId],
        currentCMPstatus: 'loading',
      },
    }));

    let currentCMPstatus = await getCurrentCMPstatus(domainId, force);

    setChecked(prevChecked => ({
      ...prevChecked,
      [domainId]: {
        ...prevChecked[domainId],
        currentCMPstatus:
          currentCMPstatus.success === false ? null : JSON.parse(currentCMPstatus.cmp),
      },
    }));
  };

  const checkCMP = useCallback(async () => {
    for (const domain of domains) {
      // Check if cache exists for this domain
      let data = lscache.get(`cmp_data_${domain.id}`);

      if (!data) {
        data = await APICall({
          endpoint: 'getCMP',
          params: `${domain.id}`,
          options: {
            method: 'get',
            headers: {
              Authorization: `Bearer ${login.token}`,
              'Cache-Control': 'max-age=0, no-cache',
              'Content-Type': 'application/json',
            },
          },
        });
      } else {
        data = JSON.parse(data);
      }

      let currentCMPstatus = await getCurrentCMPstatus(domain.id);

      setChecked(prevChecked => ({
        ...prevChecked,
        [domain.id]: {
          currentCMPstatus:
            currentCMPstatus.success === false || !currentCMPstatus.cmp
              ? null
              : JSON.parse(currentCMPstatus.cmp),
          ...data,
          ...domain,
        },
      }));
    }
  }, [domains, login.token]);

  // Additional use effect for checked domains, required to autocomplete Self-serve onboarding CMP step
  useEffect(() => {
    if (
      isSelfServe &&
      checked.length &&
      domains.length &&
      domains.length === checked.filter(domain => domain.currentCMPstatus?.cmpId).length
    ) {
      setState('completedStep', 3);
    }
  }, [checked, isSelfServe, domains, setState]);

  useEffect(() => {
    checkCMP();
    return () => {
      if (window.manualCmpPreview) {
        window.manualCmpPreview(false);
      }
    };
  }, [checkCMP]);

  return (
    <Container component="main" maxWidth="xl" className={classes.viewWrapper}>
      <div className={classes.root}>
        <Typography variant={'h4'} gutterBottom>
          Consent Management Platform (CMP)
        </Typography>

        <Typography gutterBottom>
          A CMP is a technology used by website owners to collect data from users. It complies with
          handling users’ personal information, such as meeting the General Data Protection
          Regulation (GDPR) and IAB Europe’s TCF. Every website must have a CMP in place to
          effectively monetize traffic from the USA and Europe.
        </Typography>

        <Typography gutterBottom style={{fontWeight: 'bold'}}>
          Is Your CMP Active?
        </Typography>

        <Typography gutterBottom>
          Our platform provides a CMP tracker to detect the presence of an active CMP on each
          domain.
        </Typography>
      </div>

      <br />

      <Grid container className={classes.wrapper} spacing={1} direction={'column'}>
        <List disablePadding component={GridIContainer}>
          {domains.map((domain, index) => (
            <>
              <CMPlistItem
                index={index}
                key={index}
                token={login.token}
                domain={domain.title}
                id={domain.id}
                checkedDomain={checked[domain.id]}
                classes={classes}
                open={open}
                setOpen={setOpen}
                refreshCurrentCMPstatus={refreshCurrentCMPstatus}
                vendorsList={vendorsList}
              />
            </>
          ))}
        </List>
      </Grid>
    </Container>
  );
});
