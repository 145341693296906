import React, {useState, useEffect, useRef} from 'react';
import AmCharts from '@amcharts/amcharts3-react/index';
import {useTheme} from '@material-ui/core/styles';
import {
  getStructuredFixedGraphDataForTheDay,
  getAvailableSources,
  getStructuredDynamicGraphDataForTheDay,
  getTotals,
  getPercents,
} from './helpers/dataHelpers';
import {Grid, Tooltip, Typography, makeStyles, Button} from '@material-ui/core';
import millify from 'millify';
import moment from 'moment';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(theme => ({
  domainImage: {
    height: 24,
    width: 24,
  },
  sourceRow: {borderBottom: `1px solid #7979793b`, paddingBottom: 4, marginTop: 4, marginBottom: 4},
}));

const balloonSettings = {
  adjustBorderColor: false,
  borderColor: '#e75d3c000',
  fillColor: '#e75d3c000',
  fillAlpha: 0,
  shadowAlpha: 0,
  color: '#fff',
  cornerRadius: 4,
  horizontalPadding: 10,
  verticalPadding: 10,
};

// Configuration used to describe dynamic data in that is displayed in the graph
// Not all the options is always present in the data, so we need to check if the data is present
// Key is the name of the data in the API response
const sourcesConfig = {
  AdX: {
    logo: 'https://www.gstatic.com/admanager/ad_manager.ico',
    name: 'AdX',
    colorKey: 'adx',
  },
  AdSense: {
    logo: 'https://storage.googleapis.com/support-kms-prod/bJppRS0Z8FmVujFmM7KFHADzbq3L0PPb1Wpj',
    name: 'Adsense',
    colorKey: 'adsense',
  },
  Prebid: {
    logo: 'https://prebid.org/wp-content/uploads/2023/06/cropped-prebid-logo-180x180.png',
    name: 'Prebid',
    colorKey: 'prebid',
  },

  Amazon: {
    logo: 'https://advertising.amazon.com/favicon.ico',
    name: 'Amazon',
    colorKey: 'amazon',
  },
  'Open Bidding': {
    logo: 'https://www.google.com/favicon.ico',
    colorKey: 'eCPM',
  },
};

const StackedDemandGraph = ({isMobile, data}) => {
  const theme = useTheme();
  const chartRef = useRef();
  const [demandData, setDemandData] = useState(null);
  const [disabledSources, setDisabledSources] = useState([]);
  const [enabledMetric, setEnabledMetric] = useState('Rev');
  const classes = useStyles();

  // Metrics that are used in the graph
  const metricsConfig = {
    fixed: {
      requests: {
        dataField: 'All Imp',
      },
      date: {
        dataField: 'date',
      },
    },
    perSource: {
      impressions: {
        dataField: 'Paid Imp',
        displayName: 'Impressions',
        comparisonWithTotalEnabled: true,
        graphValueAxisConfig: {
          stackType: 'regular',
          id: 'impressions',
          title: 'Impressions',
        },
        indicatorType: 'column',
        indicatorStyle: {
          height: 50,
          width: 20,
          display: 'inline-block',
          border: `1px solid ${theme.palette.graphColors.indicatorBorder}`,
          marginLeft: 10,
          borderRadius: 2,
          top: 6,
          position: 'absolute',
        },
      },
      revenue: {
        dataField: 'Rev',
        displayName: 'Revenue',
        symbol: '€',
        comparisonWithTotalEnabled: true,
        graphValueAxisConfig: {
          position: 'right',
          stackType: 'regular',
          id: 'revenue',
          title: 'Revenue',
        },
        indicatorType: 'line',
        indicatorStyle: {
          height: 4,
          width: 40,
          display: 'inline-block',
          position: 'absolute',
          top: 22,
        },
      },
      eCPM: {
        dataField: 'eCPM',
        displayName: 'eCPM',
        symbol: '€',
        comparisonWithTotalEnabled: false,
        graphValueAxisConfig: {
          id: 'eCPM',
          title: 'eCPM',
          position: 'right',
        },
        indicatorType: 'dashed-line',
        indicatorStyle: {
          height: 1,
          width: 40,
          display: 'inline-block',
          position: 'absolute',
          top: 22,
        },
      },
    },
  };

  useEffect(() => {
    // Transform the data
    let graphData = [];
    // Get all available demand sources which are in this data
    let availableSources = getAvailableSources(data, sourcesConfig);

    console.log('availableSources', availableSources);

    data.forEach(day => {
      graphData.push({
        ...getStructuredFixedGraphDataForTheDay(day, metricsConfig),
        ...getStructuredDynamicGraphDataForTheDay(day, availableSources, metricsConfig),
      });
    });

    // Calculate totals from graphData and availableSources
    let totals = getTotals(graphData, availableSources, metricsConfig);

    setDemandData({
      totals,
      graphData,
      availableSources,
    });
  }, [data]);

  // Amcharts 3 balloon function accepts HTML string as a return value
  const balloonFunction = (item, graph) => {
    let html =
      '<table style="border-radius: 4px; padding: 8px; background: rgba(0,0,0,.7); border: none!important; color: #c7c7c7; text-align: left; font-size: 13px;  line-height: 15px; -webkit-font-smoothing: auto; font-family: Inter; width: 100%; margin-top: 7px;">';
    html +=
      '<tr style="font-weight: bold; font-size: 11px;"><td>' +
      moment(item.dataContext['date']).format('YYYY MMMM D') +
      '</td></tr>';

    demandData.availableSources.forEach(source => {
      Object.keys(metricsConfig.perSource).forEach(metric => {
        let config = metricsConfig.perSource[metric];
        let value = item.dataContext[`${source} ${config.dataField}`];
        let totalValue = item.dataContext[config.dataField];
        if (!enabledMetric.includes(config.dataField) || disabledSources.includes(source)) {
          return;
        }

        html +=
          `<tr><td style="min-width: 180px; padding-top: 5px; padding-right: 15px; color: ${
            theme.palette.graphColors[sourcesConfig[source].colorKey]
          }">
          <img
                    src=${sourcesConfig[source].logo}
                    alt=${source}
                    style="height: 16px;width: 16px; position: relative; top: 2px"
                  />
          ${source}
        ${config.displayName}</td></td>` +
          `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(
            value
          )}${config.symbol ?? ''} 
          </td>

          <td style="padding-top: 5px; text-align: right; font-weight: bold">
          ${metric !== 'eCPM' ? `(${getPercents(value, totalValue)}%)` : ''}
          </td>
          </tr>`;
      });
    });

    html += '</table>';

    return html;
  };

  const toggleSource = source => {
    if (!disabledSources.includes(source)) {
      setDisabledSources(disabledSources => [...disabledSources, source]);
    } else {
      setDisabledSources(disabledSources =>
        disabledSources.filter(disabledSource => disabledSource !== source)
      );
    }
  };

  const toggleMetric = metric => {
    setEnabledMetric(metric);
  };

  const totalsTable = () => {
    return demandData.availableSources.map(source => {
      return (
        <Grid alignItems="center" spacing={2} container className={classes.sourceRow}>
          <Grid item xs={3}>
            <Tooltip
              title={!disabledSources.includes(source) ? `Hide ${source}` : `Show ${source}`}
            >
              <Button
                variant={'outlined'}
                onClick={() => toggleSource(source)}
                style={{
                  color: disabledSources.includes(source) ? theme.palette.text.primary : 'white',
                  textTransform: 'capitalize',
                  backgroundColor: !disabledSources.includes(source)
                    ? theme.palette.graphColors[sourcesConfig[source].colorKey]
                    : 'inherit',
                  fontWeight: 'bold',
                  borderColor: !disabledSources.includes(source)
                    ? theme.palette.graphColors[sourcesConfig[source].colorKey]
                    : '#7979793b',
                }}
                startIcon={
                  !disabledSources.includes(source) ? (
                    <img
                      src={sourcesConfig[source].logo}
                      alt={source}
                      className={classes.domainImage}
                    />
                  ) : (
                    <VisibilityOffIcon />
                  )
                }
              >
                {source}
              </Button>
            </Tooltip>
          </Grid>

          {Object.keys(metricsConfig.perSource).map(metric => {
            let config = metricsConfig.perSource[metric];
            let totalBySourceAndMetric =
              demandData.totals.perSource[`${source} ${config.dataField}`];

            if (metric === 'eCPM') {
              totalBySourceAndMetric = (
                (demandData.totals.perSource[`${source} Rev`] /
                  demandData.totals.perSource[`${source} Paid Imp`]) *
                1000
              ).toFixed(2);
            }
            return (
              <Grid
                item
                xs={(12 - 3) / Object.keys(metricsConfig.perSource).length}
                style={{
                  position: 'relative',
                  opacity:
                    !enabledMetric.includes(metricsConfig.perSource[metric].dataField) ||
                    disabledSources.includes(source)
                      ? 0.5
                      : 1,
                }}
              >
                <Typography
                  variant={'h5'}
                  style={{
                    display: 'inline-block',
                    minWidth: 180,
                  }}
                >
                  {millify(totalBySourceAndMetric)}
                  {config.symbol}

                  {config.comparisonWithTotalEnabled && (
                    <Typography variant={'caption'} display="inline">
                      {' '}
                      ({getPercents(totalBySourceAndMetric, demandData.totals[config.dataField])}%)
                    </Typography>
                  )}
                </Typography>

                {enabledMetric.includes(metricsConfig.perSource[metric].dataField) &&
                  !disabledSources.includes(source) && (
                    <>
                      {config.indicatorType === 'column' && (
                        <div
                          style={{
                            ...config.indicatorStyle,
                            background: `linear-gradient(0deg, ${
                              theme.palette.graphColors[sourcesConfig[source].colorKey]
                            } 0%, ${
                              theme.palette.graphColors[sourcesConfig[source].colorKey]
                            } ${getPercents(
                              totalBySourceAndMetric,
                              demandData.totals[config.dataField]
                            )}%, rgba(0,0,0,0) ${parseInt(
                              getPercents(
                                totalBySourceAndMetric,
                                demandData.totals[config.dataField]
                              )
                            ) + 0}%)`,
                          }}
                        />
                      )}
                      {config.indicatorType === 'line' && (
                        <div
                          style={{
                            ...config.indicatorStyle,
                            background: theme.palette.graphColors[sourcesConfig[source].colorKey],
                          }}
                        />
                      )}
                      {config.indicatorType === 'dashed-line' && (
                        <div
                          style={{
                            ...config.indicatorStyle,
                            borderBottom: `4px dashed ${
                              theme.palette.graphColors[sourcesConfig[source].colorKey]
                            }`,
                          }}
                        />
                      )}
                    </>
                  )}
              </Grid>
            );
          })}
        </Grid>
      );
    });
  };

  const getValueAxis = () => {
    let valueAxis = [
      {
        id: 'requests',
        titleBold: false,
        minVerticalGap: 50,
        gridAlpha: 0,
        titleColor: theme.palette.graphColors.themeGray,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
        gridColor: '#7979793b',
        position: 'right',
        title: 'Total requests and impressions by demand source',
        stackType: 'regular',
      },
      ...Object.keys(metricsConfig.perSource).map(metric => ({
        ...metricsConfig.perSource[metric].graphValueAxisConfig,
        titleBold: false,
        minVerticalGap: 50,
        gridAlpha: 1,
        gridColor: '#7979793b',
        titleColor: theme.palette.graphColors.themeGray,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
      })),
    ];

    return valueAxis;
  };

  const getGraphs = () => {
    // Requests are fixed graph
    let graphs = [
      // Add dynamic graph collumns
      ...demandData.availableSources.map(source => ({
        clustered: false,
        id: source,
        type: 'column',
        newStack: false,
        valueAxis: 'requests',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors[sourcesConfig[source].colorKey],
        lineAlpha: 0.7,
        fillAlphas: 0.7,
        lineAlpha: 0.8,
        fillAlphas: 0.9,
        lineThickness: 1,
        columnWidth: 0.8,
        legendValueText: source,
        legendPeriodValueText: source,
        valueField: `${source} Paid Imp`,
        hidden: disabledSources.includes(source) || !enabledMetric.includes('Paid Imp'),
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      })),
      // Add fixed graph requests
      {
        clustered: false,
        id: 'g1',
        type: 'column',
        valueAxis: 'requests',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        lineAlpha: 1,
        lineThickness: 1,
        columnWidth: 0.8,
        fillAlphas: 0,
        legendValueText: 'requests',
        legendPeriodValueText: 'requests',
        valueField: 'requests',
        newStack: true,
        balloon: balloonSettings,
        hidden: !enabledMetric.includes('Paid Imp'),
        balloonFunction: balloonFunction,
      },

      // Add dynamic graph revenue lines
      ...demandData.availableSources.map((source, i) => ({
        type: 'smoothedLine',
        valueAxis: 'revenue',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors[sourcesConfig[source].colorKey],
        lineThickness: 1,
        legendValueText: 'Revenue',
        legendPeriodValueText: 'Revenue',
        valueField: `${source} Rev`,
        hidden: disabledSources.includes(source) || !enabledMetric.includes('Rev'),
        fillColors: theme.palette.graphColors[sourcesConfig[source].colorKey],
        fillAlphas: 0.8,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      })),

      // Add dynamic eCPM lines
      ...demandData.availableSources.map((source, i) => ({
        type: 'smoothedLine',
        dashLength: 4,
        valueAxis: 'eCPM',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors[sourcesConfig[source].colorKey],
        lineThickness: 4,
        legendValueText: 'eCPM',
        legendPeriodValueText: 'eCPM',
        valueField: `${source} eCPM`,
        hidden: disabledSources.includes(source) || !enabledMetric.includes('eCPM'),
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      })),
    ];
    return graphs;
  };

  return (
    <>
      {demandData && (
        <>
          <Grid
            alignItems="center"
            container
            style={{
              borderBottom: `1px solid #7979793b`,
              marginBottom: 6,
            }}
          >
            <Grid item xs={3}></Grid>
            {Object.keys(metricsConfig.perSource).map(metric => (
              <Grid item xs={3}>
                <Typography variant={'caption'}>
                  <Tooltip
                    title={
                      enabledMetric.includes(metricsConfig.perSource[metric].dataField)
                        ? `Hide ${metric}`
                        : `Show ${metric}`
                    }
                  >
                    <Button
                      onClick={() => toggleMetric(metricsConfig.perSource[metric].dataField)}
                      startIcon={
                        !enabledMetric.includes(metricsConfig.perSource[metric].dataField) ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )
                      }
                      style={{textTransform: 'capitalize', fontWeight: 'bold'}}
                    >
                      {metricsConfig.perSource[metric].displayName}
                    </Button>
                  </Tooltip>
                </Typography>
              </Grid>
            ))}
          </Grid>

          {totalsTable()}
        </>
      )}

      {demandData && (
        <>
          <AmCharts.React
            ref={chartRef}
            style={{
              height: isMobile ? 300 : 420,
              width: '100%',
              transition: 'none!important',
            }}
            options={{
              columnSpacing: 0,
              zoomOutOnDataUpdate: true,
              type: 'serial',
              theme: 'light',
              marginTop: 15,
              marginRight: 15,
              responsive: {
                enabled: true,
              },

              dataProvider: demandData.graphData,
              categoryField: 'date',
              categoryAxis: {
                autoGridCount: false,
                labelFrequency: 1,
                tickLength: 1,
                gridCount: 12,
                labelRotation: 45,
                parseDates: true,
                gridAlpha: 0,
                color: theme.palette.graphColors.themeGray,
                axisColor: theme.palette.graphColors.themeLightGrayOpaque,
              },
              valueAxes: getValueAxis(),
              graphs: getGraphs(),
              chartCursor: {
                oneBalloonOnly: true,
                cursorColor: theme.palette.graphColors.themeGray,
                color: 'rgba(0,0,0,0)',
                categoryBalloonColor: 'rgba(0,0,0,0)',
                categoryBalloonDateFormat: 'YYYY-MM-DD',
                categoryBalloonAlpha: 1,
                cursorAlpha: 1,
                valueLineEnabled: true,
                valueLineBalloonEnabled: true,
                valueLineAlpha: 1,
                zoomable: false,
              },
              chartScrollbar: {
                enabled: false,
                backgroundAlpha: 0,
                dragIcon: 'dragIconRectSmall',
                graph: 'g1',
                graphFillAlpha: 0,
                graphLineAlpha: 0,
                scrollbarHeight: 30,
                color: theme.palette.primary.main,
                graphLineColor: theme.palette.graphColors.themeAlmostWhite,
                selectedGraphFillColor: theme.palette.graphColors.themeAlmostWhite,
                selectedGraphFillAlpha: 0,
                graphType: 'step',
                selectedBackgroundAlpha: 0.1,
                selectedBackgroundColor: theme.palette.graphColors.themeAlmostWhite,
              },
              dataDateFormat: 'YYYY-MM-DD',
              creditsPosition: 'bottom-right',
              zoomOutButton: {
                backgroundColor: '#e0e0e0',
                backgroundAlpha: 0,
              },
              zoomOutText: '',
              listeners: [],
            }}
          />
        </>
      )}
    </>
  );
};

export default React.memo(StackedDemandGraph);
