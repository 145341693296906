import {useEffect, useCallback} from 'react';
import useStore from '../store/store';
import {APICall} from '../api/api';
import moment from 'moment';
import useSnackbarSuccessOrError from '../components/subcomponents/subscription/customerSuccessBar';

// Hook used to get consumed credits start date for Self-Serve clients to draw separator line on graph
const useConsumedCreditsStart = id => {
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);
  const {value: selection} = useStore(state => state.selection);
  const consumedCreditsStart = useStore(state => state.consumedCreditsStart);
  const isSelfServe = auth.type >= 5 && auth?.type < 8;
  const [callSnackbarSuccessError] = useSnackbarSuccessOrError();

  const getConsumedCreditStart = useCallback(async () => {
    try {
      let data = await APICall({
        endpoint: 'consumedCreditsStart',
        options: {
          method: 'POST',
          body: JSON.stringify({selection: selection}),
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        },
      });

      if (data.success) {
        setState('consumedCreditsStart', {
          ...data,
          // Override used for testing
          //startDate: moment().subtract(1, 'days'),
          selection: selection,
        });
      } else {
        throw new Error(data.error);
      }
    } catch (e) {
      setState('consumedCreditsStart', {
        success: false,
        selection: selection,
      });
      callSnackbarSuccessError(false, '', `Consumed credits start date error: ${e.message}`);
    }
  }, [auth, selection, setState]);

  useEffect(() => {
    // Only fetch if self serve and selection has not changed
    if (
      isSelfServe &&
      JSON.stringify(selection) !== JSON.stringify(consumedCreditsStart?.selection)
    ) {
      getConsumedCreditStart();
    }
  }, [auth, selection, isSelfServe]);

  return consumedCreditsStart;
};

export default useConsumedCreditsStart;
