import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Grid, Button, Typography, Paper, makeStyles, Tooltip} from '@material-ui/core';
import AdManagerLogo from './../img/ad-manager-logo.svg';
import useStore from '../../store/store';
import moment from 'moment';
import 'moment-timezone';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {FixedSizeList as List} from 'react-window';
import usePersistentStore from '../../store/persistentStore';
const shadow = 'hsla(218, 50%, 10%, 0.1)';

const useStyles = makeStyles(theme => ({
  tooltip: {
    padding: theme.spacing(2),
  },
  iconInTheList: {
    paddingRight: 10,
    height: 18,
    position: 'relative',
    top: 4,
    display: 'inline-block',
  },
  adManagerLogoStartIcon: {
    height: 18,
    marginLeft: 10,
  },
  wrapper: {
    display: 'block',
    textAlign: 'right',
  },
  dropdownMenu: {
    borderRadius: 4,
    boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
    position: 'absolute',
    right: 0,
    zIndex: 2,
    width: 'inherit',
    minWidth: 300,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropDownBlanket: {
    bottom: 0,
    left: 0,
    top: 0,
    right: 0,
    position: 'fixed',
    zIndex: 1,
  },
  setDefaultButton: {
    marginTop: theme.spacing(1),
  },
  preferredIndicator: {
    fontSize: 10,
    display: 'inline-block',
    borderRadius: 4,
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    color: 'white',
    background: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
}));

const selectStyles = {
  container: provided => ({...provided, minWidth: '100%', marginTop: 0}),
  input: provided => ({...provided, color: 'inherit'}),
  control: provided => ({...provided, minWidth: '100%', marginTop: 0, background: 'inherit'}),
  menu: () => ({boxShadow: '0 1px 20px rgba(0, 0, 0, .1)', position: 'relative'}),
  menuList: provided => ({...provided, maxHeight: '60vh'}),
  option: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',
      color: 'inherit',
      opacity: state.isFocused ? 1 : 0.8,
      backgroundColor: state.isFocused ? 'rgba(199, 199, 199, .05)' : 'none',
      '&:active': {
        background: 'none',
        opacity: 1,
      },
      '&:hover': {
        backgroundColor: 'none',
        opacity: 1,
      },
    };
  },
};

const formatedTimezoneLabel = timezone => timezone.replaceAll('_', ' ').replaceAll('Etc/', '');

const Svg = p => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);

const ChevronDown = () => (
  <Svg style={{marginRight: -6}}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

export function TimezonePicker({disabled = false, isMobile = false}) {
  const [selectionMenuOpen, setSelectionMenuOpen] = useState(false);
  const timezone = useStore(state => state.timezone);
  const setState = useStore(state => state.setState);
  const auth = useStore(state => state.auth);
  const selection = useStore(state => state.selection);
  const classes = useStyles();
  const usersTimezone = moment.tz.guess();
  const preferredTimezone = usePersistentStore(state => state.preferredTimezone);
  const setPersistentState = usePersistentStore(state => state.setState);

  // This use effect controls selected timezone
  useEffect(() => {
    // Default timezone is browsers timezone
    let timezone = usersTimezone;

    // If publisher is selected, set timezone according to the publishers timezone
    if (selection?.value?.publisher && auth.publishers) {
      timezone = auth.publishers.find(
        publisher => parseInt(publisher.id) === parseInt(selection.value.publisher)
      ).timezone;
    }
    // Else if nothing is selected, set timezone as first publisher timezone
    // Ideally, all publishers in account should have same timezone
    else if (auth?.publishers[0]) {
      timezone = auth.publishers[0].timezone;
    }

    // If preferred timezone is set and selector is not disabled, use preferred timezone
    if (preferredTimezone && !disabled) {
      timezone = preferredTimezone;
    }

    setState('timezone', timezone);
  }, [auth, selection, setState]);

  // Disable account properties selector typing events when selection menu is open
  useEffect(() => {
    setState('disableTypingForAccountSelector', selectionMenuOpen ? true : false);
  }, [selectionMenuOpen, setState]);

  const isTimezoneSameAsPublishers = timezone => {
    let publishersTimezones = auth.publishers.map(publisher => publisher.timezone);
    return publishersTimezones.includes(timezone);
  };

  const generateTimezonesList = () => {
    let menu = [];

    // If selected timezone is not any of publishers timezone, push all publishers add it at the top
    if (!isTimezoneSameAsPublishers(timezone) && auth.publishers?.length) {
      auth.publishers.map(publisher => {
        menu.push({
          // Replace all dashes in timezones names
          label: formatedTimezoneLabel(publisher.timezone),
          value: publisher.timezone,
        });
      });
    }

    // If selected timezone is not current browser zone, add it at the top
    if (!isTimezoneSameAsBrowsers(timezone)) {
      menu.push({
        // Replace all dashes in timezones names
        label: formatedTimezoneLabel(usersTimezone),
        value: usersTimezone,
      });
    }

    // Push all available timezones
    moment.tz.names().map(timezone => {
      menu.push({
        // Replace all dashes in timezones names
        label: formatedTimezoneLabel(timezone),
        value: timezone,
      });
    });

    // Filter out duplicates, they appear after adding items at the top
    menu = [...new Map(menu.map(item => [item['value'], item])).values()];

    return menu;
  };

  const isTimezoneSameAsBrowsers = timezone => timezone === usersTimezone;

  const getTooltipTitle = () => {
    let tooltipTitle = `Click to view data in a different timezone. Note that only hourly data can be viewed in different timezones, as daily data is fixed by the timezone in Google Ad Manager.`;

    // If the component is disabled, explain why
    if (disabled) {
      tooltipTitle = `The timezone for daily data matches the Google Ad Manager timezone and cannot be changed. Only hourly data can be viewed in a different timezone.`;
    }

    if (!disabled && isTimezoneSameAsPublishers(timezone)) {
      tooltipTitle = `This timezone matches the publisher's selected timezone in Google Ad Manager. Click to view data in a different timezone.`;
    }

    let tooltipJSX = <Typography>{tooltipTitle}</Typography>;

    if (!disabled && timezone !== preferredTimezone && !isTimezoneSameAsPublishers(timezone)) {
      tooltipJSX = (
        <>
          <Typography>
            {tooltipTitle}
            <Button
              className={classes.setDefaultButton}
              fullWidth
              variant={'contained'}
              size={'small'}
              onClick={() => {
                setPersistentState('preferredTimezone', timezone);
              }}
              color={'primary'}
            >
              Set as preferred timezone
            </Button>
          </Typography>
        </>
      );
    }

    return tooltipJSX;
  };

  const Menu = props => {
    return <Paper className={classes.dropdownMenu} {...props} />;
  };

  const Blanket = props => <Paper css={classes.dropDownBlanket} {...props} />;

  const Dropdown = ({children, isOpen, target, onClose}) => (
    <div>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );

  const formatOptionLabel = ({value, label}) => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant={'body1'}>
          {preferredTimezone === value && (
            <Typography className={classes.preferredIndicator}>Preferred</Typography>
          )}
          {isTimezoneSameAsPublishers(value) && (
            <Tooltip
              title={`${
                auth.publishers.find(publisher => publisher.timezone === value).name
              } publisher timezone set in Google Ad Manager.`}
            >
              <img alt={'Ad manager logo'} src={AdManagerLogo} className={classes.iconInTheList} />
            </Tooltip>
          )}

          {isTimezoneSameAsBrowsers(value) && !isTimezoneSameAsPublishers(value) && (
            <Tooltip title={'Your browsers timezone'}>
              <AccessTimeIcon className={classes.iconInTheList} />
            </Tooltip>
          )}

          {label}
        </Typography>
      </Grid>
    </Grid>
  );

  const MenuList = ({children}) => {
    const height = 45;

    return (
      <List
        height={Math.min(window.innerHeight * 0.7, height * children.length)}
        itemCount={children.length}
        itemSize={height}
      >
        {({index, style}) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  return (
    <div className={classes.wrapper}>
      <Dropdown
        isOpen={selectionMenuOpen}
        onClose={() => setSelectionMenuOpen(!selectionMenuOpen)}
        target={
          <>
            <Tooltip
              interactive
              classes={{tooltip: classes.tooltip}}
              title={getTooltipTitle()}
              disableHoverListener={selectionMenuOpen}
              placement="bottom-end"
            >
              <div>
                <Button
                  fullWidth={isMobile}
                  startIcon={
                    isTimezoneSameAsPublishers(timezone) ? (
                      <img
                        alt={'Ad manager logo'}
                        src={AdManagerLogo}
                        className={classes.adManagerLogoStartIcon}
                      />
                    ) : isTimezoneSameAsBrowsers(timezone) ? (
                      <AccessTimeIcon />
                    ) : null
                  }
                  endIcon={<ChevronDown />}
                  onClick={() => setSelectionMenuOpen(!selectionMenuOpen)}
                  isSelected={selectionMenuOpen}
                  disabled={disabled}
                >
                  {timezone}
                </Button>
              </div>
            </Tooltip>
          </>
        }
      >
        <Select
          value={{
            label: timezone,
            value: timezone,
          }}
          autoFocus
          noOptionsMessage={() => <Typography>Timezone not found 🌐</Typography>}
          onBlur={() => setSelectionMenuOpen(!selectionMenuOpen)}
          formatOptionLabel={formatOptionLabel}
          backspaceRemovesValue={false}
          styles={selectStyles}
          tabSelectsValue={false}
          menuIsOpen
          placeholder={'Type to search by name'}
          isClearable={false}
          components={{MenuList, IndicatorSeparator: null}}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          options={generateTimezonesList()}
          onChange={change => {
            setSelectionMenuOpen(!selectionMenuOpen);
            setState('timezone', change.value);
          }}
        />
      </Dropdown>
    </div>
  );
}
