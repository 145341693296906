import React, {useState, useEffect, useLayoutEffect} from 'react';
import {useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {makeStyles, withStyles} from '@material-ui/core/styles/index';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import millify from 'millify';
import InfoIcon from '@material-ui/icons/Info';
import ReactGA from 'react-ga4';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: '20%',
    paddingRight: '20%',
  },
  seperator: {
    background: theme.palette.graphColors.eCPM,
    float: 'right',
    height: 90,
    width: 2,
    right: -1,
    boxShadow: `0 0 10px ${theme.palette.background.default}`,
    borderRadius: 2,
    zIndex: 9999,
    position: 'relative',
  },
  percents: {
    width: '19%',
    display: 'inline-block',
    background: theme.palette.graphColors.themeAlmostWhiteOpaque,
  },
  revenueBar: {
    borderRadius: 2,
    height: 20,
    marginTop: 20,

    right: 0,
    // boxShadow: `0 0 10px ${theme.palette.graphColors.revenue}`,
    background: theme.palette.graphColors.revenue,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  impressionsBar: {
    borderRadius: 2,
    display: 'inline-block',
    position: 'relative',
    marginTop: 50,
    marginBottom: 40,
    height: 20,
    width: '100%',
    background: theme.palette.graphColors.themeDarkGray,
    right: 0,
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  passbacksBar: {
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'block',
  },
  impressionsLabel: {
    display: 'block',
    width: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    zIndex: 2,
  },
}));

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    background: 'none',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    fontSize: 11,
  },
}))(Tooltip);

const CustomSlider = withStyles(theme => ({
  root: {
    color: theme.palette.graphColors.eCPM,
    // height: 2,
    // padding: '15px 0',
  },
  thumb: {
    borderRadius: 0,
    height: 120,
    zIndex: 9,
    //marginLeft: -40,
    width: 3,
    transform: 'translateX(5px)',
    '&:focus, &:hover, &$active': {
      boxShadow: `0 0 20px ${theme.palette.graphColors.eCPM}`,
    },
  },
  active: {},
  valueLabel: {
    left: 0,
    top: -70,
    position: 'relative',
    // display: 'none'
    // height: 120,
    // width: 4,
    // background: theme.palette.graphColors.eCPM,
    // top: '10px',
    //left: -4,
    // left: 'calc(-50% + 12px)',
    // top: 9,
    // '& *': {
    //     fontSize: '1rem',
    //     background: 'transparent',
    //     color: 'white',
    // },
  },
  track: {
    height: 2,
    opacity: 1,
  },
  rail: {
    height: 2,
    opacity: 1,
    backgroundColor: theme.palette.graphColors.themeGray,
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginTop: -3,
    marginLeft: -4,
    opacity: 1,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
}))(Slider);

const CMPvisualiser = React.memo(props => {
  //console.log('CMPvisualiser', props)

  const [CPM, setCPM] = React.useState(props.eCPM);
  const [eCPM, setECPM] = React.useState(props.eCPM);
  const [shifts, setShifts] = React.useState({
    revenue: 0,
    impressions: 0,
    slider: 0,
  });

  useEffect(() => {
    calculateShifts();
  }, [props, CPM, eCPM]);

  useEffect(() => {
    setCPM(props.eCPM);
  }, [props.selectedBidder]);

  const calculateShifts = () => {
    console.log('calculate shifts', props.data, props.eCPM, CPM);
    let totalRevenue = props.data.reduce((total, part) => total + part.revenue, 0);
    let totalImpressions = props.data.reduce((total, part) => total + part.imp, 0);

    let revenueShift = 100 - (props.data[0].revenue * 100) / totalRevenue;
    let impressionsShift = (props.data[0].imp * 100) / totalImpressions;

    let sliderMax = props.data[1] ? props.data[1].maxInRange : props.data[0].maxInRange;

    let sliderShift = (sliderPosition(CPM) * 100) / sliderMax;

    setShifts({
      revenue: revenueShift,
      impressions: impressionsShift,
      slider: sliderShift - 50,
      passbacksBarWidth: props.data[0].passbacks
        ? (props.data[0].passbacks * 100) / totalImpressions
        : 0,
    });
  };

  const classes = useStyles();
  const theme = useTheme();

  const logScale = x => {
    let minpos = props.data[0].rangeStart + 0.01;
    let maxpos = props.data[1] ? props.data[1].maxInRange : props.data[0].maxInRange;
    let minlval = Math.log(props.data[0].rangeStart + 0.01 || 1);
    let maxlval = Math.log(maxpos || 100000);

    let scale = (maxlval - minlval) / (maxpos - minpos);

    return Math.exp((x - minpos) * scale + minlval);
  };

  const sliderPosition = x => {
    let minpos = props.data[0].rangeStart + 0.01;
    let maxpos = props.data[1] ? props.data[1].maxInRange : props.data[0].maxInRange;
    let minlval = Math.log(props.data[0].rangeStart + 0.01 || 1);
    let maxlval = Math.log(maxpos || 100000);

    let scale = (maxlval - minlval) / (maxpos - minpos);

    return minpos + (Math.log(x) - minlval) / scale;
  };

  return (
    <Grid container direction={'row'} className={classes.wrapper}>
      <Grid item xs={12}>
        <CustomTooltip
          open={true}
          placement="left"
          title={`${(props.data[0].rangeStart + 0.01).toFixed(2)} <`}
        >
          <CustomTooltip
            open={true}
            placement="right"
            title={`< ${(props.data[1]
              ? props.data[1].maxInRange
              : props.data[0].maxInRange
            ).toFixed(2)}`}
          >
            <CustomSlider
              defaultValue={sliderPosition(CPM)}
              value={sliderPosition(CPM)}
              step={0.1}
              scale={x => logScale(x)}
              valueLabelFormat={x => x.toFixed(2)}
              min={props.data[0].rangeStart + 0.01}
              //  min={0.1}
              max={props.data[1] ? props.data[1].maxInRange : props.data[0].maxInRange}
              onChange={(event, CMP) => {
                ReactGA.event({
                  category: 'Deciles slider',
                  action: 'slide',
                  label: `${logScale(CMP)}`,
                });

                setCPM(logScale(CMP));
                props.recalculate(props.decilesData, props.selectedBidder, logScale(CMP));
              }}
              marks={[
                {
                  value: sliderPosition(eCPM),
                  //  label: `eCPM ${parseFloat(eCPM)}`,
                },
              ]}
              valueLabelDisplay="on"
            />
          </CustomTooltip>
        </CustomTooltip>
      </Grid>

      <Grid item xs={6} style={{left: `${shifts.slider}%`, position: 'relative'}}>
        {/*<div className={classes.seperator}/>*/}

        <Grid item xs={12} style={{left: `${shifts.revenue}%`, position: 'relative'}}>
          {/*<Typography variant={"body"} className={classes.percents} display={"inline-block"}>{(100-shifts.revenue).toFixed(0)}%</Typography>*/}

          <CustomTooltip
            open={true}
            placement="left-start"
            title={`${props.data[0].revenue.toFixed(2)}€ (${
              100 - shifts.revenue < 10
                ? (100 - shifts.revenue).toFixed(2)
                : (100 - shifts.revenue).toFixed(0)
            }%)`}
          >
            <CustomTooltip
              open={true}
              placement="right-start"
              title={`${props.data[1] ? props.data[1].revenue.toFixed(2) : 0}€ (${
                shifts.revenue < 10 ? shifts.revenue.toFixed(2) : shifts.revenue.toFixed(0)
              }%)`}
            >
              <div className={classes.revenueBar}>Revenue</div>
            </CustomTooltip>
          </CustomTooltip>

          {/*<Typography variant={"body"} className={classes.percents} display={"inline-block"}>{(shifts.revenue).toFixed(0)}%</Typography>*/}
        </Grid>
      </Grid>

      <Grid item xs={6} style={{left: `${shifts.slider}%`, position: 'relative'}}>
        <Grid item xs={12} style={{right: `${shifts.impressions}%`, position: 'relative'}}>
          {/*<Typography variant={"body"} className={classes.percents} display={"inline-block"}>{(100 -shifts.impressions).toFixed(0)}%</Typography>*/}

          <CustomTooltip
            open={true}
            placement="left-start"
            title={`${millify(props.data[0].imp)} (${
              shifts.impressions < 10
                ? shifts.impressions.toFixed(2)
                : shifts.impressions.toFixed(0)
            }%)`}
          >
            <CustomTooltip
              open={true}
              placement="right-start"
              title={`${millify(props.data[1] ? props.data[1].imp : 0)} (${
                100 - shifts.impressions < 10
                  ? (100 - shifts.impressions).toFixed(2)
                  : (100 - shifts.impressions).toFixed(0)
              }%)`}
            >
              {/*<Tooltip arrow*/}
              {/*title={`Passbacks: ${millify(props.data[0].passbacks)} (${shifts.passbacksBarWidth}%)`}>*/}
              <div className={classes.impressionsBar}>
                <div
                  className={classes.passbacksBar}
                  style={{width: `${shifts.passbacksBarWidth}%`}}
                />

                <div className={classes.impressionsLabel}>Impressions</div>
              </div>
              {/*</Tooltip>*/}
            </CustomTooltip>
          </CustomTooltip>

          {/*<Typography variant={"body"} className={classes.percents} display={"inline-block"}>{(shifts.impressions).toFixed(0)}%</Typography>*/}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default React.memo(CMPvisualiser);
