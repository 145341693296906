import create from 'zustand';
import moment from 'moment';
import lscache from 'lscache';
import tz from 'moment-timezone';

// Using Zustand slicing pattern https://docs.pmnd.rs/zustand/guides/slices-pattern
import {onboardingSlice} from './onboardingSlice';
import {adsTxtSlice} from './adsTxtSlice';
import {ticketsSlice} from './ticketsSlice';
import {realtimeSlice} from './realtimeSlice';

// Additional time altering logic to prevent marking ad units as inactive if the data is not on our servers yet
let startingDay =
  parseInt(moment.tz('Europe/Vilnius').format('HH')) > 7 ? moment() : moment().subtract(1, 'day');

const useStore = create(set => ({
  ...onboardingSlice(set),
  ...adsTxtSlice(set),
  ...ticketsSlice(set),
  ...realtimeSlice(set),
  googleAuth: false,
  disableTypingForAccountSelector: false,
  openedSidebarSection: null,
  profileMenuOpen: null,
  visiblePopup: null,
  auth: null,
  propertiesSelectionScrollOffset: 0,
  startingDay: startingDay,
  collapsedDomains: [],
  MCMstatuses: {},
  selectedSection: 0,
  calculated: null,
  publishers: null,
  selectionLabel: null,
  showSectionsTooltip: lscache.get('tabsTooltipSeen'),
  activeBillingSection: 0,
  period: {
    label: 'Last 30 days',
    startDate: moment(startingDay)
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    endDate: moment(startingDay)
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
  },
  comparisonPeriod: {
    startDate: moment(startingDay)
      .subtract(61, 'days')
      .format('YYYY-MM-DD'),
    endDate: moment(startingDay)
      .subtract(31, 'days')
      .format('YYYY-MM-DD'),
  },
  selection: {
    value: {
      publisher: null,
      placement: null,
      domain: null,
    },
  },
  assignedGoogleProperties: null,

  // Global state change function, two params - first is key of state, second is data
  setState: (key, data) => {
    set(() => ({
      [key]: data,
    }));
  },
}));

export default useStore;
