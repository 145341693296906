import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles/index';
import millify from 'millify';
import FadeIn from 'react-fade-in';
import {useMediaQuery} from 'react-responsive';
import breakpoints from './../helpers/breakpoints';

const arrowUp = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 18">
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path fill="#57cb80" fill-rule="nonzero" d="M7 15l5-5 5 5z" />
    </g>
  </svg>
);

const arrowDown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 -4 20 18">
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path fill="#FF3636" fill-rule="nonzero" d="M7 10l5 5 5-5z" />
    </g>
  </svg>
);

const useStyles = makeStyles(theme => ({
  wrapper: {
    top: 7,
    position: 'absolute',
    display: 'inline-grid',
    borderRadius: 4,
    padding: 2,
    margin: 2,
  },
  wrapperDropdown: {
    // top: 12,
    // position: 'absolute',
    // display: 'inline-grid',
    // borderRadius: 4,
    // padding: 2,
    // margin: 2,
  },
  wrapperMobile: {
    top: 14,
    right: 70,
    // position: 'absolute',
    display: 'inline-grid',
    borderRadius: 4,
    padding: 2,
    margin: 2,
  },
}));

const Trend = React.memo(props => {
  const classes = useStyles();
  const isMobile = useMediaQuery({query: breakpoints.mobile});

  let diff = ((100 - (props.current * 100) / props.previous) * -1).toFixed(2);

  const checkDiff = anumber => {
    switch (anumber.toString()) {
      case 'NaN':
        return false;
      case 'Infinity':
        return false;
      default:
        return true;
    }
  };

  // Dont show comparison if difference if Nan or Infinity, or higher than 10k or previous period is 0
  if (
    !checkDiff(diff) ||
    (Math.abs(diff) > 10000 && !props.showHighPercentage) ||
    props.previous === 0
  ) {
    return;
  }

  if (props.enabled) {
    if (isMobile) {
      return (
        <div className={classes.wrapperMobile}>
          <Typography variant={'caption'}>
            <Tooltip
              title={<React.Fragment>Current vs Previous period</React.Fragment>}
              enterTouchDelay={60}
            >
              {diff < 0 ? arrowDown : arrowUp}
            </Tooltip>
            {Math.abs(Math.round(diff))}%
          </Typography>
        </div>
      );
    } else if (props.inDropdown) {
      return (
        <Typography variant={props.big ? 'h6' : 'caption'} display={'inline'}>
          {diff < 0 ? arrowDown : arrowUp}
          {props.millify ? millify(Math.abs(Math.round(diff))) : Math.abs(Math.round(diff))}%
        </Typography>
      );
    } else {
      return (
        <div className={classes.wrapper}>
          <FadeIn>
            <Typography variant={'caption'}>
              <Tooltip arrow title={<React.Fragment>Current vs Previous period</React.Fragment>}>
                {diff < 0 ? arrowDown : arrowUp}
              </Tooltip>
              {Math.abs(Math.round(diff))}%
            </Typography>
          </FadeIn>
        </div>
      );
    }
  }
});

export default React.memo(Trend);
