import React, {useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import ReactGA from 'react-ga4';
import lscache from 'lscache';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MATUILink from '@material-ui/core/Link';
import fileDownload from 'js-file-download';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FadeIn from 'react-fade-in/lib/FadeIn';
import {useLocation} from 'react-router-dom';
import useStore from '../../store/store';
import {Alert} from '@material-ui/lab';

const useStyles = makeStyles(theme => {
  return {
    wrapper: {
      position: 'absolute',
      top: '20px',
      right: '17px',
    },
    redirectStatusWrapper: {
      display: 'inline-block',
      top: -5,
      marginLeft: theme.spacing(2),
      position: 'relative',
      float: 'right',
    },
    tooltip: {
      maxWidth: 'none',
      padding: theme.spacing(2),
    },
    refreshButton: {
      marginTop: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.name === 'darkTheme' ? '#fff' : 'inherit',
      backgroundColor:
        theme.name === 'darkTheme' ? 'rgb(26 24 29 / 95%)' : 'rgb(255 255 255 / 95%)',
      backgroundSize: `200% 200%`,
      animation: '$scaleUpDown 30s ease infinite',
    },
    errorIcon: {
      color: 'red',
      top: 2,
      position: 'relative',
    },
    successIcon: {
      top: 2,
      position: 'relative',
    },
  };
});

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" style={{right: -4}}>
      <CircularProgress variant={'static'} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary" style={{fontSize: 10}}>
          {props.checked.length}/{props.domains}
        </Typography>
      </Box>
    </Box>
  );
}

export const AdsTXTQuickStats = React.memo(({login, checked, domains}) => {
  const classes = useStyles();
  const setState = useStore(state => state.setState);
  const [visible, setVisible] = useState(true);
  const [open, setOpen] = useState(null);
  const [rechecking, setRechecking] = useState(false);
  const location = useLocation();

  return (
    <div className={classes.wrapper}>
      {domains.length !== checked.length && (
        <CircularProgressWithLabel
          size={35}
          value={(checked.length / domains.length) * 100}
          checked={checked}
          domains={domains.length}
          color="secondary"
        />
      )}

      {checked.length > 0 && domains.length === checked.length && (
        <Tooltip
          onOpen={() => {
            ReactGA.event({
              category: 'Ads.txt quick checker',
              action: 'click',
              label: 'open',
            });
          }}
          onClose={() => {
            ReactGA.event({
              category: 'Ads.txt quick checker',
              action: 'click',
              label: 'close',
            });
          }}
          arrow
          classes={{tooltip: classes.tooltip}}
          interactive
          title={
            <React.Fragment>
              <Grid container spacing={1} direction={'column'}>
                {checked.map(checkedDomain => {
                  if (checkedDomain.success) {
                    let {redirectStatus} = checkedDomain;
                    return (
                      <Grid item style={{padding: 8}}>
                        <Typography>
                          {checkedDomain.missingAdsTXT?.length > 0 ? (
                            <ReportProblemIcon fontSize={'inherit'} className={classes.errorIcon} />
                          ) : (
                            <CheckCircleIcon
                              fontSize={'inherit'}
                              color={'secondary'}
                              className={classes.successIcon}
                            />
                          )}{' '}
                          {checkedDomain.domain}
                          {checkedDomain.currentAdsTXT?.success ? '' : ` - ads.txt not found`}
                          {checkedDomain.missingAdsTXT?.length > 0
                            ? ` - ${checkedDomain.missingAdsTXT?.length} ${
                                checkedDomain.missingAdsTXT?.length === 1 ? 'line' : 'lines'
                              } missing`
                            : ''}
                          <Tooltip arrow interactive title={redirectStatus.tooltip}>
                            <div className={classes.redirectStatusWrapper}>
                              <Alert
                                style={{padding: '0px 16px'}}
                                icon={redirectStatus.icon}
                                severity={redirectStatus.alert}
                              >
                                <Typography>{redirectStatus.message}</Typography>
                              </Alert>
                            </div>
                          </Tooltip>
                        </Typography>
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Button
                variant={'contained'}
                startIcon={<SyncIcon />}
                color={'primary'}
                size={'small'}
                className={classes.refreshButton}
                onClick={e => {
                  e.preventDefault();
                  setState('checked', []);
                  ReactGA.event({
                    category: 'Ads.txt quick checker',
                    action: 'click',
                    label: 'Check again',
                  });
                }}
              >
                Check again
              </Button>
            </React.Fragment>
          }
        >
          <div>
            {(checked.filter(checkedDomain => !checkedDomain.success).length > 0 ||
              checked.filter(checkedDomain => checkedDomain.missingAdsTXT.length > 0).length >
                0) && <ReportProblemIcon style={{color: 'red'}} />}
            {checked.filter(checkedDomain => !checkedDomain.success).length === 0 &&
              checked.filter(checkedDomain => checkedDomain.missingAdsTXT.length > 0).length ===
                0 && <CheckCircleIcon color={'secondary'} />}
          </div>
        </Tooltip>
      )}

      {(checked?.filter(domain => domain.missingAdsTXT?.length)?.length > 0 || rechecking) &&
        !location?.pathname?.includes('/ads-txt') &&
        !lscache.get(`ads_txt_warning_suspended_${login.accountId}`) &&
        login?.ads_txt_popup === 1 && (
          <Backdrop className={classes.backdrop} open={visible}>
            <Container maxWidth={'md'}>
              <IconButton
                style={{
                  float: 'right',
                  border: '1px solid rgba(255,255,255,0.3)',
                }}
                onClick={() => {
                  setVisible(false);
                  lscache.set(`ads_txt_warning_suspended_${login.accountId}`, true, 1440);
                  ReactGA.event({
                    category: 'Yield Formula Promo',
                    action: 'Closed',
                  });
                }}
                color={'inherit'}
              >
                <CloseIcon />
              </IconButton>
              <Grid
                container
                spacing={4}
                direction="row"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item xs={12}>
                  <Typography
                    variant={'h3'}
                    align={'center'}
                    style={{fontWeight: 'bold'}}
                    gutterBottom
                  >
                    <ReportProblemIcon
                      style={{
                        fontSize: 'inherit',
                        color: '#ffe100',
                        top: 2,
                        position: 'relative',
                        top: 6,
                        marginRight: 14,
                      }}
                    />
                    Ads.txt warning
                  </Typography>
                  <Typography align={'center'} color="textSecondary">
                    Please upload the newest ads.txt to your domain(s) root with our new ad partners
                    to avoid losing ad revenue.{' '}
                    <MATUILink
                      href={'https://setupad.com/blog/ads-txt-guide-for-publishers/'}
                      target={'_blank'}
                    >
                      How to implement ads.txt?
                    </MATUILink>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List disablePadding>
                    {checked
                      .filter(domain => domain?.missingAdsTXT?.length)
                      .map((domain, i) => {
                        return (
                          <>
                            <FadeIn>
                              <ListItem
                                disableRipple
                                disableTouchRipple
                                disableFocusRibble
                                button
                                disablePadding
                                style={{marginBottom: 5}}
                                onClick={() => {
                                  setOpen(i === open ? null : i);

                                  ReactGA.event({
                                    category: 'Ads.txt',
                                    action: 'click',
                                    label: `expand required ads.txt`,
                                  });
                                }}
                                selected={open === null || open === i}
                              >
                                <ListItemIcon>
                                  <PlaylistAddIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={`${domain.domain}: ${
                                    domain.currentAdsTXT.success
                                      ? `${domain.missingAdsTXT?.length} ads.txt line${
                                          domain.missingAdsTXT?.length > 1 ? `s` : ``
                                        } missing`
                                      : 'ads.txt not found'
                                  }`}
                                />
                                {open === i ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                            </FadeIn>
                            <Collapse
                              in={open === i}
                              timeout={0}
                              classes={{
                                container: classes.collapseContainer,
                              }}
                            >
                              <Grid
                                container
                                style={{
                                  fontFamily: 'monospace',
                                  maxHeight: 100,
                                  overflowX: 'scroll',
                                }}
                                spacing={1}
                              >
                                <Grid item xs={1}>
                                  <Paper elevation={0}>
                                    {domain.missingAdsTXT?.map((line, i) => {
                                      return (
                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            textAlign: 'right',
                                            marginRight: 5,
                                            marginTop: 2.121,
                                            //  borderBottom: `1px solid rgb(0 0 0 / 95%)`,
                                          }}
                                        >
                                          <Typography variant={'caption'}>{i + 1}</Typography>
                                        </Grid>
                                      );
                                    })}
                                  </Paper>
                                </Grid>

                                <Grid item xs={11}>
                                  {domain.missingAdsTXT?.map((line, i) => {
                                    return (
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          marginLeft: 5,
                                          marginTop: 3,

                                          background: i % 2 === 0 ? `rgb(255 255 255 / 5%)` : null,
                                        }}
                                      >
                                        <Typography variant={'subtitle'} noWrap>
                                          {line}
                                        </Typography>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </Collapse>
                          </>
                        );
                      })}
                  </List>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    style={{marginRight: 10}}
                    onClick={() => {
                      setVisible(false);
                      lscache.set(`ads_txt_warning_suspended_${login.accountId}`, true, 1440);
                      ReactGA.event({
                        category: 'Yield Formula Promo',
                        action: 'Closed',
                      });
                    }}
                  >
                    Close
                  </Button>

                  <Button
                    variant="outlined"
                    color={'primary'}
                    className={classes.button}
                    style={{marginRight: 10}}
                    onClick={() => {
                      fetch(`https://wb.setupad.com/api/getAdsTXT/${login.accountId}/download`)
                        .then(res => res.blob())
                        .then(res => {
                          ReactGA.event({
                            category: 'Ads.txt',
                            action: 'click',
                            label: `download`,
                          });
                          fileDownload(res, 'ads.txt');
                        })
                        .catch(err => {
                          console.log(err);
                        });
                    }}
                  >
                    Download Ads.txt
                  </Button>

                  <Button
                    variant={'contained'}
                    startIcon={
                      rechecking ? (
                        !checked.length ? (
                          <CircularProgress size={18} />
                        ) : (
                          <CircularProgressWithLabel
                            size={28}
                            value={(checked.length / domains.length) * 100}
                            checked={checked}
                            domains={domains.length}
                            color="secondary"
                          />
                        )
                      ) : (
                        <SyncIcon />
                      )
                    }
                    color={'primary'}
                    className={classes.button}
                    disabled={rechecking}
                    onClick={e => {
                      e.preventDefault();
                      setRechecking(true);
                      setState('checked', []);
                      setOpen(null);
                      ReactGA.event({
                        category: 'Ads.txt quick checker',
                        action: 'click',
                        label: 'Check again',
                      });
                    }}
                  >
                    Check again
                  </Button>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <Typography variant={'subtitle'} align={'center'} color="textSecondary">
                    Ads.txt file on the domain website confirms ownership of the domain and
                    indicates partner accounts (such as ad exchanges and SSPs) eligibility to sell
                    ad inventory.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Backdrop>
        )}
    </div>
  );
});
