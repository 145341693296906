// Number formatter, shortened to nf
// API returns some values INFINITY, or NaN, so we need to check if the value is a number else return 0
const nf = (value, metric) => {
  if (metric === 'date') {
    return value;
  }
  if (!isFinite(value) || isNaN(value)) {
    return 0;
  }

  return parseFloat(value);
};

// Returns day data in a structured format for the graph for fixed metrics
const getStructuredFixedGraphDataForTheDay = (day, metricsConfig) => {
  let metrics = {};
  Object.keys(metricsConfig.fixed).forEach(metric => {
    metrics[metric] = nf(day[metricsConfig.fixed[metric].dataField], metric);
  });
  return metrics;
};

const getAvailableSources = (data, sourcesConfig) => {
  let getAvailableSources = [];
  data.forEach(day => {
    // Not all the sources are always present in the data, so we need to know what's present
    Object.keys(sourcesConfig).forEach(source => {
      if (day[`${source} Paid Imp`]) {
        if (!getAvailableSources.includes(source)) {
          getAvailableSources.push(source);
        }
      }
    });
  });
  return getAvailableSources;
};

// Returns day data in a structured format for the graph by the available sources
const getStructuredDynamicGraphDataForTheDay = (day, availableSources, metricsConfig) =>
  availableSources.reduce((metrics, source) => {
    Object.keys(metricsConfig.perSource).forEach(metric => {
      let key = `${source} ${metricsConfig.perSource[metric].dataField}`;

      if (metrics[metricsConfig.perSource[metric].dataField] === undefined) {
        metrics[metricsConfig.perSource[metric].dataField] = 0;
      }

      metrics[metricsConfig.perSource[metric].dataField] += nf(day[key]);
      metrics[key] = nf(day[key]);
    });

    return metrics;
  }, {});

// Returns totals by the available sources from daily data
const getTotals = (graphData, availableSources, metricsConfig) => {
  let totals = {
    perSource: {},
  };

  graphData.map(day => {
    availableSources.map(source => {
      Object.keys(metricsConfig.perSource).forEach(metric => {
        let key = `${source} ${metricsConfig.perSource[metric].dataField}`;

        if (!totals[`perSource`][key]) {
          totals[`perSource`][key] = 0;
        }

        totals[`perSource`][key] += day[key];

        if (!totals[metricsConfig.perSource[metric].dataField]) {
          totals[metricsConfig.perSource[metric].dataField] = 0;
        }

        totals[metricsConfig.perSource[metric].dataField] += day[key];
      });
    });
  });

  return totals;
};

// Returns percents, first param is value, another is sum
const getPercents = (value, sum) => {
  if (sum === 0) {
    return 0;
  }

  return ((value / sum) * 100).toFixed(2);
};

module.exports = {
  getStructuredFixedGraphDataForTheDay,
  getAvailableSources,
  getStructuredDynamicGraphDataForTheDay,
  getTotals,
  getPercents,
};
