import React, {useEffect, useState} from 'react';
import {
  Typography,
  Grid,
  List,
  makeStyles,
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import {ExpandLess, ExpandMore, MenuBook} from '@material-ui/icons';
import {Skeleton, Alert} from '@material-ui/lab';
import {options} from './options';
import FadeIn from 'react-fade-in/lib/FadeIn';
import useStore from '../../../store/store';
import LinkIcon from '@material-ui/icons/Link';

const GridIContainer = props => <Grid container {...props} />;

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    borderRadius: '4px',
    border: '1px solid #7979793b',
    marginTop: 20,
    // padding: 20,
    background: theme.palette.background.paper,
  },
  collapseContainer: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  redirectAlert: {
    marginBottom: 10,
  },
  codeOption: {
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    fontSize: '0.8rem',
    padding: 20,
    background: '#00000038',
    borderRadius: 4,
    marginTop: 20,
    marginBottom: 20,
  },
}));

export const RedirectOptions = ({accountId, domains, checked, isSelfServe = false}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const allDomainsChecked = domains.length === checked.length;
  const setState = useStore(state => state.setState);
  const selectedAdsTXTDomain = useStore(state => state.selectedAdsTXTDomain);
  const selectedDomainData = checked?.find(domain => domain.id === selectedAdsTXTDomain);
  const selectedDomainCustomEntriesLength = selectedDomainData
    ? Object.keys(selectedDomainData?.requiredAdstXT?.customEntries).length
    : 0;

  useEffect(() => {
    if (domains.length === 1 && !selectedAdsTXTDomain) {
      setState('selectedAdsTXTDomain', domains[0].id);
    } else if (!selectedAdsTXTDomain) {
      setMenuOpen(open);
    }

    // Reset selected domain on list item collapse
    if (!open && domains.length !== 1) {
      setState('selectedAdsTXTDomain', null);
    }
  }, [open]);

  return (
    <div style={{marginTop: 50}}>
      <FadeIn>
        <Typography variant={'h4'} gutterBottom>
          Streamline Ads.txt with Setupad Redirect
        </Typography>
        <Typography gutterBottom>
          {isSelfServe && (
            <>
              See our platform-specific setup guides to implement the ads.txt redirect effortlessly.
            </>
          )}
          {!isSelfServe && (
            <>
              We add new partners regularly, so setting up an automated redirect to your ads.txt
              hosted by Setupad is the easiest way to keep your file updated. Once the redirect is
              set up, you can add custom new lines, which will be included in the redirected ads.txt
              file. See our platform-specific setup guides to implement the redirect effortlessly.
            </>
          )}
        </Typography>
        <Grid container className={classes.wrapper} spacing={1} direction={'column'}>
          <List disablePadding component={GridIContainer}>
            {options.map((option, index) => {
              if (!allDomainsChecked && isSelfServe) {
                return (
                  <Skeleton variant="rect" width={'100%'} height={65} style={{marginBottom: 2}} />
                );
              } else {
                return (
                  <>
                    <ListItem
                      disableRipple
                      button
                      selected={open === index}
                      style={{minHeight: 63, borderTop: '1px solid #7979793b'}}
                      onClick={() => {
                        setOpen(open === index ? null : index);
                      }}
                    >
                      <ListItemIcon>
                        <MenuBook />
                      </ListItemIcon>
                      <ListItemText primary={option.title} />
                      {open === index ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={open === index}
                      timeout={0}
                      classes={{
                        container: classes.collapseContainer,
                      }}
                    >
                      <div style={{padding: 20}}>
                        {domains && domains.length > 0 && (
                          <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                              <InputLabel shrink={true} id="domain-label">
                                <Typography
                                  gutterBottom
                                  color={selectedAdsTXTDomain ? 'inherit' : 'primary'}
                                >
                                  {selectedAdsTXTDomain
                                    ? 'Selected domain'
                                    : 'Select domain to see instructions'}
                                </Typography>
                              </InputLabel>
                              <Select
                                color={'primary'}
                                fullWidth
                                labelId="domain-label"
                                value={selectedAdsTXTDomain}
                                open={open === index && menuOpen === index}
                                onChange={event => {
                                  setState('selectedAdsTXTDomain', event.target.value);
                                  setMenuOpen(null);
                                }}
                                onOpen={() => setMenuOpen(index)}
                                onClose={() => setMenuOpen(null)}
                                MenuProps={{
                                  getContentAnchorEl: null,
                                }}
                              >
                                {domains.map(domain => (
                                  <MenuItem value={domain.id}>{domain.title}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                        <div style={{opacity: selectedAdsTXTDomain ? 1 : 0.1}}>
                          {/* Show ads.txt configuration error if mode is self serve and custom entries are not configured */}
                          {selectedDomainCustomEntriesLength === 0 && isSelfServe && (
                            <Alert severity="error" className={classes.redirectAlert}>
                              Please configure your ads.txt file for this domain by clicking "Edit
                              ads.txt" button before setting up the redirect.
                            </Alert>
                          )}

                          {/* Prevent seeing redirect options if custom entries is not configured (no custom entries set) and mode is self serve*/}
                          {(!isSelfServe ||
                            (isSelfServe && selectedDomainCustomEntriesLength > 0)) &&
                            option.content.map(item => {
                              if (item.type === 'text') {
                                return <Typography gutterBottom>{item.text}</Typography>;
                              }
                              if (item.type === 'code') {
                                // Code replacements
                                let text = item.text
                                  .replaceAll('{accountId}', accountId)
                                  .replaceAll('{domainId}', selectedAdsTXTDomain)
                                  .replaceAll('&nbsp;', ' ');

                                // Redirect url replacements by mode
                                if (isSelfServe) {
                                  text = text.replaceAll('{mode}', 'selfserve');
                                } else {
                                  text = text.replaceAll('{mode}', 'file');
                                }

                                return (
                                  <Typography
                                    className={classes.codeOption}
                                    gutterBottom
                                    style={{opacity: selectedAdsTXTDomain ? 1 : 0}}
                                  >
                                    {text}
                                  </Typography>
                                );
                              }
                              if (item.type === 'step') {
                                return <Typography gutterBottom>• {item.text}</Typography>;
                              }
                            })}
                        </div>

                        {isSelfServe && selectedDomainCustomEntriesLength !== 0 && (
                          <Grid item xs={12} style={{marginTop: 20}}>
                            <Alert
                              severity="info"
                              action={
                                <Button
                                  variant="outlined"
                                  color={'primary'}
                                  endIcon={<LinkIcon />}
                                  href={`https://code.setupad.io/adstxt/${accountId}/${selectedAdsTXTDomain}/${
                                    domains.find(domain => domain.id === selectedAdsTXTDomain).title
                                  }`}
                                  target="_blank"
                                >
                                  Link
                                </Button>
                              }
                            >
                              Share redirect instructions via link
                            </Alert>
                          </Grid>
                        )}
                      </div>
                    </Collapse>
                  </>
                );
              }
            })}
          </List>
        </Grid>
      </FadeIn>
    </div>
  );
};
