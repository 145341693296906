import {useEffect, useCallback} from 'react';
import useStore from '../store/store';
import {APICall} from '../api/api';

// Used to get the list of vendors from the CMP API and set the default enabled value
const useVendors = () => {
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);
  const vendorsList = useStore(state => state.vendorsList);

  const getVendorsList = useCallback(async () => {
    try {
      let fullVendorsList = await APICall({
        endpoint: 'fullVendorsList',
      });

      let enabledByDefaultVendors = await APICall({
        endpoint: 'enabledByDefaultVendors',
      });

      let vendors = [];
      let territorialScopeList = [];
      let environmentsList = [];

      // Loop through the vendors list and set the default enabled value
      Object.keys(fullVendorsList.vendors).forEach(key => {
        fullVendorsList.vendors[key].enabledByDefault = enabledByDefaultVendors.includes(
          fullVendorsList.vendors[key].id.toString()
        );
        vendors.push(fullVendorsList.vendors[key]);

        fullVendorsList.vendors[key].territorialScope.forEach(scope => {
          if (!territorialScopeList.includes(scope)) {
            territorialScopeList.push(scope);
          }
        });

        fullVendorsList.vendors[key].environments.forEach(environment => {
          if (!environmentsList.includes(environment)) {
            environmentsList.push(environment);
          }
        });
      });

      setState('environmentsList', environmentsList);
      setState('vendorsList', vendors);
      setState('territorialScopeList', territorialScopeList);
    } catch (e) {
      setState('fullVendorsList', {
        success: false,
        message: 'Error getting vendors list',
      });
    }
  }, [setState]);

  useEffect(() => {
    // Only trigger if the user is a self-serve client and if the selection publisher has changed
    if (!vendorsList && auth?.token) {
      getVendorsList();
    }
  }, [auth, vendorsList, getVendorsList]);

  return vendorsList;
};

export default useVendors;
