import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import lscache from 'lscache';
import Logo from './../components/img/setupad_client_final.svg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles/index';
import {APICall} from '../api/api';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  loader: {
    position: 'absolute',
    zIndex: 99999,
    top: '0',
    width: '100%',
    left: 0,
  },
  additionalButtons: {
    margin: theme.spacing(1, 0, 1),
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  let onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      let response = await APICall({
        endpoint: 'remindPassword',
        params: email,
      });
      if (response.success) {
        setSuccess(true);
      } else {
        setError(response.error);
      }
      setLoading(false);
    } catch (error) {
      setError('Error, try again');
      setLoading(false);
    }
  };

  let handleInputChange = event => {
    setError(null);
    setEmail(event.target.value);
  };

  return (
    <>
      {loading && <LinearProgress variant="query" className={classes.loader} position={'fixed'} />}

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '80vh'}}
      >
        <Container component="main" maxWidth="xs" minHeight="100%" style={{padding: 50}}>
          <div>
            <img
              src={
                lscache.get('theme') === 'dark'
                  ? 'https://setupad.com/wp-content/themes/setupad/assets/img/new_company_logo.svg'
                  : Logo
              }
              style={{width: '100%'}}
            />
            <form action="/" method="POST" noValidate onSubmit={onSubmit}>
              <Typography variant={'h6'} align={'center'}>
                Enter your email address and we'll get you back on track
              </Typography>
              <TextField
                style={{marginTop: '15px'}}
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                name="email"
                label="Email address"
                type="email"
                id="email"
                value={email}
                onChange={handleInputChange}
              />
              {!success && (
                <Button
                  disabled={loading}
                  className={classes.additionalButtons}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Send password reset link
                </Button>
              )}

              {error && (
                <Box mt={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}

              {success && (
                <Box mt={2}>
                  <Alert severity="success">
                    Password reset link sent and is valid for an hour. Please check your spam or
                    junk mail section if you can not find email.
                  </Alert>
                </Box>
              )}

              {/*<Box mt={2}>*/}
              {/*<Typography variant="body2" align="center">*/}
              {/*Or cantact us contact@setupad.com*/}
              {/*</Typography>*/}
              {/*</Box>*/}
            </form>
          </div>
        </Container>
      </Grid>
    </>
  );
}

export default ForgotPassword;
