import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import ReactGA from 'react-ga4';
import useStore from '../../store/store';
import {Alert} from '@material-ui/lab';

const useStyles = makeStyles(theme => {
  return {
    tooltip: {
      maxWidth: 'none',
      padding: theme.spacing(2),
    },
    redirectStatusWrapper: {
      display: 'inline-block',
      top: -5,
      marginLeft: theme.spacing(2),
      position: 'relative',
      float: 'right',
    },
    refreshButton: {
      marginTop: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.name === 'darkTheme' ? '#fff' : 'inherit',
      backgroundColor:
        theme.name === 'darkTheme' ? 'rgb(26 24 29 / 95%)' : 'rgb(255 255 255 / 95%)',
      backgroundSize: `200% 200%`,
      animation: '$scaleUpDown 30s ease infinite',
    },
    countWrapper: {
      background: theme.palette.background.default,
      borderRadius: 4,
      padding: theme.spacing(0.8),
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      textAlign: 'center',
      marginRight: 5,
    },
  };
});

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" style={{right: -4}}>
      <CircularProgress variant={'static'} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary" style={{fontSize: 10}}>
          {props.checked.length}/{props.domains}
        </Typography>
      </Box>
    </Box>
  );
}

export const AdsTXTQuickStatsSelfServe = React.memo(({login, checked, domains}) => {
  const classes = useStyles();
  const setState = useStore(state => state.setState);

  return (
    <div
      style={{
        position: 'absolute',
        top: '20px',
        right: '17px',
      }}
    >
      {domains.length !== checked.length && (
        <CircularProgressWithLabel
          size={35}
          value={(checked.length / domains.length) * 100}
          checked={checked}
          domains={domains.length}
          color="secondary"
        />
      )}
      {checked.length > 0 && domains.length === checked.length && (
        <Tooltip
          onOpen={() => {
            ReactGA.event({
              category: 'Ads.txt quick checker',
              action: 'click',
              label: 'open',
            });
          }}
          onClose={() => {
            ReactGA.event({
              category: 'Ads.txt quick checker',
              action: 'click',
              label: 'close',
            });
          }}
          arrow
          classes={{tooltip: classes.tooltip}}
          interactive
          title={
            <React.Fragment>
              <Grid container spacing={1} direction={'column'}>
                {checked.map(checkedDomain => {
                  const currentAdsTXT = checkedDomain?.currentAdsTXT?.rows ?? null;
                  if (checkedDomain.success) {
                    let {redirectStatus} = checkedDomain;
                    return (
                      <Grid item style={{padding: 8}}>
                        <Typography>
                          {!checkedDomain.currentAdsTXT.success ||
                          checkedDomain.currentAdsTXT?.rows?.length === 0 ? (
                            <ReportProblemIcon
                              fontSize={'inherit'}
                              style={{
                                color: 'red',
                                top: 2,
                                position: 'relative',
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              fontSize={'inherit'}
                              color={'secondary'}
                              style={{
                                top: 2,
                                position: 'relative',
                              }}
                            />
                          )}{' '}
                          {checkedDomain.domain}
                          {currentAdsTXT && (
                            <>
                              {' '}
                              -{' '}
                              <span className={classes.countWrapper}>{currentAdsTXT?.length}</span>
                              {currentAdsTXT?.length === 1 ? 'line' : 'lines'} found
                            </>
                          )}
                          <Tooltip arrow interactive title={redirectStatus.tooltip}>
                            <div className={classes.redirectStatusWrapper}>
                              <Alert
                                style={{padding: '0px 16px'}}
                                icon={redirectStatus.icon}
                                severity={redirectStatus.alert}
                              >
                                <Typography>{redirectStatus.message}</Typography>
                              </Alert>
                            </div>
                          </Tooltip>
                        </Typography>
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Button
                variant={'contained'}
                startIcon={<SyncIcon />}
                color={'primary'}
                size={'small'}
                className={classes.refreshButton}
                onClick={e => {
                  e.preventDefault();
                  setState('checked', []);
                  ReactGA.event({
                    category: 'Ads.txt quick checker',
                    action: 'click',
                    label: 'Check again',
                  });
                }}
              >
                Check again
              </Button>
            </React.Fragment>
          }
        >
          <div>
            {/* Check if there are domains without redirect enabled*/}
            {checked.filter(checkedDomain => !checkedDomain.redirectStatus.success).length > 0 && (
              <ReportProblemIcon style={{color: 'orange'}} />
            )}
            {/* Check if there are no domains without ads.txt lines and display success icon*/}
            {checked.filter(checkedDomain => !checkedDomain.redirectStatus.success).length ===
              0 && <CheckCircleIcon color={'secondary'} />}
          </div>
        </Tooltip>
      )}
    </div>
  );
});
