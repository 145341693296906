import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  Checkbox,
  TextField,
  Grid,
  Button,
  IconButton,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles/index';
import {Skeleton} from '@material-ui/lab';
import useStore from '../../store/store';
import {APICall} from '../../api/api';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FadeIn from 'react-fade-in/lib/FadeIn';
import {Alert} from '@material-ui/lab';

const StyledTableCell = withStyles(theme => ({
  head: {
    background: theme.palette.graphColors.themeDarkGray,
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    fontWeight: 'bold',
    padding: 8,
  },
  body: {
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    padding: 10,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  TableContainer: {
    height: '45vh',
  },
  listWrapper: {
    borderLeft: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    background: theme.palette.background.paper,
  },
}));

const tableSkeletonRows = (rows, columns) =>
  Array.from({length: rows}, (x, i) => {
    return (
      <TableRow key={i}>
        {Array.from({length: columns}, (x, i) => (
          <StyledTableCell key={i}>
            <Skeleton height={41} />
          </StyledTableCell>
        ))}
      </TableRow>
    );
  });

export function IABcategoriesTable({selectedCategories, setSelectedCategories}) {
  const theme = useTheme();
  const classes = useStyles();
  const auth = useStore(state => state.auth);
  const [page, setPage] = useState(0);
  const [filteredList, setFilteredList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [categoriesList, setCategoriesList] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [showTopCategoriesOnly, setShowTopCategoriesOnly] = useState(null);

  useEffect(() => {
    async function loadCategories() {
      setError(null);
      let categoriesRequest = await APICall({
        endpoint: 'getDomainCategories',
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      if (categoriesRequest.success === false) {
        setError('Error loading categories');
      }

      setCategoriesList(categoriesRequest);
    }

    loadCategories();
  }, []);

  useEffect(() => {
    if (!categoriesList || error) return;

    let updatedList = [...categoriesList];
    if (showTopCategoriesOnly) {
      updatedList = updatedList.filter(category => !category.id.includes('-'));
    }

    if (!searchQuery) {
      setFilteredList(updatedList);
      return;
    }

    let filtered = updatedList.filter(
      category =>
        category.category_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        category.id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredList(filtered);
  }, [searchQuery, categoriesList, showTopCategoriesOnly]);

  const findTopLevelCategory = category =>
    categoriesList.find(row => row.id === category.id.split('-')[0]);

  const getSubcategoriesList = (category, includeTopLevelCategory = false) => {
    let topLevelCategory = findTopLevelCategory(category);
    let subcategoriesList = categoriesList.filter(
      row => row.id.split('-')[0] === topLevelCategory.id && row.id.includes('-')
    );
    if (includeTopLevelCategory) {
      subcategoriesList = [topLevelCategory, ...subcategoriesList];
    }

    return subcategoriesList;
  };

  const toggleSelect = (category, isTopLevelCategory, checked) => {
    if (checked) {
      // If top level category is checked, check it and all subcategories
      if (isTopLevelCategory) {
        const subcategoriesList = getSubcategoriesList(category, true);
        setSelectedCategories([
          ...new Set([...selectedCategories, ...subcategoriesList.map(row => row.category_name)]),
        ]);
      }

      // If subcategory is selected, check it and also toggle top category if all subcategories are checked
      else {
        let topLevelCategory = findTopLevelCategory(category);
        let subcategoriesList = getSubcategoriesList(category, false);

        // If all subcategories are checked, also check top level category
        if (
          subcategoriesList.every(subcategory =>
            [...selectedCategories, category.category_name].includes(subcategory.category_name)
          )
        ) {
          setSelectedCategories([
            ...new Set([
              ...selectedCategories,
              topLevelCategory.category_name,
              category.category_name,
            ]),
          ]);
        } else {
          // Else check only the subcategory
          setSelectedCategories([...new Set([...selectedCategories, category.category_name])]);
        }
      }
    } else {
      // If top level category is unchecked, uncheck it and all subcategories
      if (isTopLevelCategory) {
        const subcategoriesList = getSubcategoriesList(category, true);
        setSelectedCategories(
          selectedCategories.filter(
            category_name =>
              !subcategoriesList.map(row => row.category_name).includes(category_name)
          )
        );
      }

      // If subcategory is unchecked, uncheck it.
      // Also uncheck top level category.
      else {
        let topLevelCategory = findTopLevelCategory(category);

        let categoriesToUncheck = [category.category_name, topLevelCategory.category_name];

        setSelectedCategories([
          ...selectedCategories.filter(
            category_name => !categoriesToUncheck.includes(category_name)
          ),
        ]);
      }
    }
  };

  if (error)
    return (
      <Alert severity="error" style={{width: '100%'}}>
        {error}
      </Alert>
    );

  return (
    <Grid container style={{marginTop: 10}}>
      <Grid item xs={12}>
        <Typography gutterBottom color={'textSecondary'} variant={'subtitle'} display={'block'}>
          3. Add advertiser categories to the blocklist
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TableContainer className={classes.TableContainer}>
          <Table stickyHeader component={Paper}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" style={{height: 80, padding: 20}}>
                  <TextField
                    placeholder={'Search by category name'}
                    inputProps={{
                      style: {
                        minWidth: 200,
                        fontSize: 14,
                        color: theme.palette.text.primary,
                      },
                    }}
                    onChange={({target: {value}}) => setSearchQuery(value)}
                  />
                  <FormControlLabel
                    style={{float: 'right', position: 'relative', top: -3}}
                    labelPlacement={'end'}
                    control={
                      <Switch
                        color={'primary'}
                        checked={showTopCategoriesOnly}
                        onChange={e => {
                          setShowTopCategoriesOnly(e.target.checked);
                        }}
                      />
                    }
                    label={<Typography variant={'caption'}>Only top level categories</Typography>}
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {/* Placeholder while loads */}
              {!categoriesList && tableSkeletonRows(rowsPerPage, 1)}

              {/* If nothing found */}
              {filteredList?.length === 0 && (
                <StyledTableCell align="center">
                  No {showTopCategoriesOnly ? 'top level' : ''} categories found
                </StyledTableCell>
              )}

              {filteredList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(category => {
                  let isTopLevelCategory = !category.id.includes('-');

                  let isChecked = selectedCategories.includes(category.category_name);

                  let isInterminate = false;
                  if (isTopLevelCategory && !isChecked) {
                    let subcategoriesList = getSubcategoriesList(category, false);
                    isInterminate = subcategoriesList.some(subcategory =>
                      selectedCategories.includes(subcategory.category_name)
                    );

                    if (isInterminate) isChecked = true;
                  }

                  return (
                    <TableRow key={category.id}>
                      <StyledTableCell
                        align="left"
                        style={
                          isTopLevelCategory
                            ? {fontWeight: 'bold', fontSize: 14}
                            : {opacity: 0.8, padding: '0 0 0 40px'}
                        }
                      >
                        <Checkbox
                          checked={isChecked}
                          color={'primary'}
                          onChange={({target: {checked}}) =>
                            toggleSelect(category, isTopLevelCategory, checked)
                          }
                          indeterminate={isInterminate}
                        />
                        {category.category_name}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={2}
                  count={filteredList?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {'aria-label': 'categories per page'},
                    native: true,
                  }}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={({target}) => {
                    setRowsPerPage(parseInt(target.value));
                    setPage(0);
                  }}
                  labelRowsPerPage={'Categories per page'}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6} className={classes.listWrapper}>
        <TableContainer className={classes.TableContainer}>
          <Table stickyHeader component={Paper}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" style={{height: 80, padding: 20}}>
                  Selected categories
                  <Button
                    disabled={!selectedCategories.length}
                    color="primary"
                    onClick={() => setSelectedCategories([])}
                    size={'small'}
                    style={{float: 'right'}}
                  >
                    Clear all
                  </Button>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {categoriesList?.map(category => {
                let isTopLevelCategory = !category.id.includes('-');
                let subcategoriesList = getSubcategoriesList(category, false);

                if (
                  selectedCategories.includes(category.category_name) ||
                  (isTopLevelCategory &&
                    subcategoriesList.some(subcategory =>
                      selectedCategories.includes(subcategory.category_name)
                    ))
                ) {
                  // Dont show subcategories if showTopCategoriesOnly is enabled
                  if (!isTopLevelCategory && showTopCategoriesOnly) return null;

                  return (
                    <TableRow key={category.id}>
                      <StyledTableCell
                        align="left"
                        style={
                          isTopLevelCategory
                            ? {fontWeight: 'bold', fontSize: 14, padding: 16}
                            : {opacity: 0.8, padding: '6px 0 6px 40px'}
                        }
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            toggleSelect(category, isTopLevelCategory, false);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>{' '}
                        {category.category_name}
                        {isTopLevelCategory && showTopCategoriesOnly
                          ? ` (Including ${
                              [...selectedCategories].filter(category_name =>
                                subcategoriesList
                                  .map(subcategory => subcategory.category_name)
                                  .includes(category_name)
                              ).length
                            } subcategories)`
                          : ''}
                      </StyledTableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
