import {useCallback} from 'react';
import useStore from '../store/store';
import {APICall} from '../api/api';

const useRefreshMenu = () => {
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);

  const refreshMenu = useCallback(async () => {
    setState('tickets', null);

    let response = await APICall({
      endpoint: 'refreshMenu',
      options: {
        method: 'POST',
        body: JSON.stringify({reportingToken: auth.reportingToken}),
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
      },
    });

    if (response.success) {
      // Override auth object with updated data
      setState('auth', {
        ...useStore.getState().auth,
        token: response.token,
        menu: response.menu,
      });
    }
  }, [auth, setState]);

  return {refreshMenu};
};

export default useRefreshMenu;
