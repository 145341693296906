import React, {useState, useEffect, useRef} from 'react';
import AmCharts from '@amcharts/amcharts3-react/index';
import {useTheme} from '@material-ui/core/styles';
import moment from 'moment';
import millify from 'millify';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles/index';
import ReactGA from 'react-ga4';
import Trend from './Trend';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  lowerOpacity: {
    opacity: 0.5,
  },
  metricWrapper: {
    position: 'relative',
  },
}));

const RealtimeTable = React.memo(({isMobile, realtimeData}) => {
  const theme = useTheme();
  const classes = useStyles();
  const chartRef = useRef();
  const [graphData, setGraphData] = useState(null);

  const allMetrics = [
    {name: 'Revenue', color: theme.palette.graphColors.revenue},
    {name: 'Impressions', color: theme.palette.graphColors.themeGray},
    {name: 'eCPM', color: theme.palette.graphColors.eCPM},
    {name: 'Viewability', color: theme.palette.graphColors.viewability},
    {name: 'CTR', color: theme.palette.graphColors.CTR},
  ];
  // Metrics which are enabled by default
  const [enabledMetrics, setEnabledMetrics] = useState(['Revenue', 'Impressions', 'eCPM']);

  useEffect(() => {
    let graphData = [];

    for (let hour = 0; hour < 24; hour++) {
      let hourData = realtimeData.today.find(row => row.hour === hour);
      let hourDataYesterday = realtimeData.yesterday.find(row => row.hour === hour);

      graphData.push({
        date: `${moment()
          .startOf('day')
          .add(hour + 0, 'hour')
          .format('HH.mm')} - ${moment()
          .startOf('day')
          .add(hour + 1, 'hour')
          .format('HH.mm')}`,
        dateForTooltip: `${moment()
          .startOf('day')
          .add(hour + 0, 'hour')
          .format('HH.mm')} - ${moment()
          .startOf('day')
          .add(hour + 1, 'hour')
          .format('HH.mm')}`,
        revenue: hourData ? hourData.rev : 0,
        revenueYesterday: hourDataYesterday ? hourDataYesterday.rev : 0,
        impressions: hourData ? hourData.imp : 0,
        impressionsYesterday: hourDataYesterday ? hourDataYesterday.imp : 0,
        eCPM: hourData ? (hourData.rev / hourData.imp) * 1000 : 0,
        eCPMyesterday: hourDataYesterday
          ? (hourDataYesterday.rev / hourDataYesterday.imp) * 1000
          : 0,
        viewability: hourData ? hourData.viewability : 0,
        viewabilityYesterday: hourDataYesterday ? hourDataYesterday.viewability : 0,
        CTR: hourData ? hourData.ctr : 0,
        CTRyesterday: hourDataYesterday ? hourDataYesterday.ctr : 0,
      });
    }

    setGraphData(graphData);
  }, [realtimeData]);

  const balloonFunction = (item, graph) => {
    let html =
      '<table style="border-radius: 4px; padding: 8px; background: rgba(0,0,0,.7); border: none!important; color: #c7c7c7; text-align: left; font-size: 13px;  line-height: 15px; -webkit-font-smoothing: auto; font-family: Inter; width: 100%; margin-top: 7px;">';

    html += `<tr><td style="padding-top: 5px; min-width: 100px">${item.dataContext['dateForTooltip']}</td><td style="padding-top: 5px; padding-right: 15px;">Today</td><td style="padding-top: 5px; padding-right: 15px;">Yesterday</td></tr>`;

    if (enabledMetrics.includes('Revenue')) {
      html += `<tr><td style="padding-top: 5px; padding-right: 15px; color: ${
        theme.palette.graphColors.revenue
      }">Revenue</td><td>${millify(item.dataContext['revenue'])}€</td><td>${millify(
        item.dataContext['revenueYesterday']
      )}€</td></tr>`;
    }

    if (enabledMetrics.includes('Impressions')) {
      html += `<tr><td style="padding-top: 5px; padding-right: 15px; color: ${
        theme.palette.graphColors.themeGray
      }">Impressions</td><td>${millify(item.dataContext['impressions'])}</td><td>${millify(
        item.dataContext['impressionsYesterday']
      )}</td></tr>`;
    }

    if (enabledMetrics.includes('eCPM')) {
      html += `<tr><td style="padding-top: 5px; padding-right: 15px; color: ${
        theme.palette.graphColors.eCPM
      }">eCPM</td><td>${millify(item.dataContext['eCPM'])}€</td><td>${millify(
        item.dataContext['eCPMyesterday']
      )}€</td></tr>`;
    }

    if (enabledMetrics.includes('Viewability')) {
      html += `<tr><td style="padding-top: 5px; padding-right: 15px; color: ${
        theme.palette.graphColors.viewability
      }">Viewability</td><td>${millify(item.dataContext['viewability'])}%</td><td>${millify(
        item.dataContext['viewabilityYesterday']
      )}%</td></tr>`;
    }

    if (enabledMetrics.includes('CTR')) {
      html += `<tr><td style="padding-top: 5px; padding-right: 15px; color: ${theme.palette.graphColors.CTR}">CTR</td><td>${item.dataContext['CTR']}%</td><td>${item.dataContext['CTRyesterday']}%</td></tr>`;
    }

    html += '</table>';

    return html;
  };

  const balloonSettings = {
    adjustBorderColor: false,
    borderColor: '#e75d3c000',
    fillColor: '#e75d3c000',
    fillAlpha: 0,
    shadowAlpha: 0,
    color: '#fff',
    cornerRadius: 4,
    horizontalPadding: 10,
    verticalPadding: 10,
  };

  const getValueAxis = () => {
    let valueAxis = [
      {
        id: 'v0',
        titleBold: false,
        minVerticalGap: 50,
        gridAlpha: 0,
        titleColor: theme.palette.graphColors.themeGray,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
      },
      {
        id: 'v1',
        titleColor: theme.palette.graphColors.revenue,
        titleBold: false,
        gridAlpha: 0,
        minVerticalGap: 50,
        color: theme.palette.graphColors.revenue,
        axisColor: theme.palette.graphColors.revenue,
        minimum: 0,
        position: 'right',
        offset: /eCPM/.test(enabledMetrics) ? 40 : 0,
      },
      {
        id: 'v2',
        titleBold: false,
        titleColor: theme.palette.graphColors.eCPM,
        minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.eCPM,
        axisColor: theme.palette.graphColors.eCPM,
        minimum: 0,
        position: 'right',
      },
      {
        id: 'v3',
        title: '%',
        titleBold: false,
        titleColor: theme.palette.graphColors.themeGray,
        gridAlpha: 0,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
        offset: /Impressions/.test(enabledMetrics) ? 65 : 0,
      },
    ];

    return valueAxis;
  };

  const getGraphs = () => {
    let graphs = [
      {
        id: 'g1',
        type: 'column',
        valueAxis: 'v0',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        lineAlpha: 1,
        lineThickness: 1,
        columnWidth: 1,
        fillAlphas: 0.4,
        legendValueText: 'Impressions',
        legendPeriodValueText: 'Impressions',
        valueField: 'impressions',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
        hidden: !enabledMetrics.includes('Impressions'),
      },
      {
        id: 'g2',
        type: 'column',
        valueAxis: 'v0',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        lineAlpha: 1,
        lineThickness: 1,
        columnWidth: 1,
        legendValueText: 'Impressions',
        legendPeriodValueText: 'Impressions',
        valueField: 'impressionsYesterday',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
        hidden: !enabledMetrics.includes('Impressions'),
      },
      {
        id: 'g3',
        type: 'smoothedLine',
        valueAxis: 'v1',
        baseAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.revenue,
        lineThickness: 3,
        legendValueText: 'Revenue',
        legendPeriodValueText: 'Revenue',
        valueField: 'revenue',
        fillAlphas: theme.palette.type === 'dark' ? 0.5 : 0,
        fillColors: [theme.palette.graphColors.revenue, 'rgba(0,0,0,0)'],
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
        hidden: !enabledMetrics.includes('Revenue'),
      },
      {
        id: 'g4',
        type: 'smoothedLine',
        valueAxis: 'v1',
        baseAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.revenue,
        lineThickness: 3,
        legendValueText: 'Revenue',
        legendPeriodValueText: 'Revenue',
        valueField: 'revenueYesterday',
        dashLength: 4,
        fillAlphas: 0,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
        hidden: !enabledMetrics.includes('Revenue'),
      },
      {
        id: 'g5',
        type: 'smoothedLine',
        valueAxis: 'v2',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.eCPM,
        lineThickness: 3,
        legendValueText: 'eCPM',
        legendPeriodValueText: 'eCPM',
        valueField: 'eCPM',
        fillAlphas: theme.palette.type === 'dark' ? 0.2 : 0,
        fillColors: [theme.palette.graphColors.eCPM, 'rgba(0,0,0,0)'],
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
        hidden: !enabledMetrics.includes('eCPM'),
      },
      {
        id: 'g6',
        type: 'smoothedLine',
        valueAxis: 'v2',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.eCPM,
        lineThickness: 3,
        legendValueText: 'eCPM',
        legendPeriodValueText: 'eCPM',
        valueField: 'eCPMyesterday',
        fillAlphas: 0,
        dashLength: 4,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
        hidden: !enabledMetrics.includes('eCPM'),
      },
      {
        id: 'g7',
        type: 'smoothedLine',
        valueAxis: 'v3',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.viewability,
        lineThickness: 3,
        legendValueText: 'Viewability',
        legendPeriodValueText: 'Viewability',
        valueField: 'viewability',
        hidden: !enabledMetrics.includes('Viewability'),
        dashLength: 0,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g8',
        type: 'smoothedLine',
        valueAxis: 'v3',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.viewability,
        lineThickness: 3,
        legendValueText: 'Viewability',
        legendPeriodValueText: 'Viewability',
        valueField: 'viewabilityYesterday',
        hidden: !enabledMetrics.includes('Viewability'),
        dashLength: 5,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g9',
        type: 'smoothedLine',
        valueAxis: 'v3',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.CTR,
        lineThickness: 3,
        legendValueText: 'CTR',
        legendPeriodValueText: 'CTR',
        valueField: 'CTR',
        hidden: !enabledMetrics.includes('CTR'),
        dashLength: 0,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g10',
        type: 'smoothedLine',
        valueAxis: 'v3',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.CTR,
        lineThickness: 3,
        legendValueText: 'CTR',
        legendPeriodValueText: 'CTR',
        valueField: 'CTRyesterday',
        hidden: !enabledMetrics.includes('CTR'),
        dashLength: 5,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
    ];

    return graphs;
  };

  const toggleMetric = metric => {
    if (!enabledMetrics.includes(metric)) {
      setEnabledMetrics(currentMetrics => [...currentMetrics, metric]);
      ReactGA.event({
        category: 'Realtime metric comparison enable',
        action: 'click',
        label: metric,
      });
    } else {
      ReactGA.event({
        category: 'Realtime metric comparison disable',
        action: 'click',
        label: metric,
      });
      setEnabledMetrics(currentMetrics =>
        currentMetrics.filter(currentMetric => currentMetric !== metric)
      );
    }

    chartRef.current.state.chart.graphs.map(graph => {
      if (graph.legendValueText === metric) {
        if (graph.hidden) {
          chartRef.current.state.chart.showGraph(graph);
        } else {
          chartRef.current.state.chart.hideGraph(graph);
        }
      }
    });
  };

  const customChips = () => {
    return allMetrics.map(metric => {
      let totals = {
        Revenue: `${realtimeData.today.reduce((rev, hour) => rev + hour.rev, 0).toFixed(2)} €`,
        Impressions: `${millify(realtimeData.today.reduce((rev, hour) => rev + hour.imp, 0))}`,
        eCPM: `${(
          (realtimeData.today.reduce((rev, hour) => rev + hour.rev, 0) /
            realtimeData.today.reduce((imp, hour) => imp + hour.imp, 0)) *
          1000
        ).toFixed(2)}€`,
        Viewability: `${(
          realtimeData.today.reduce((viewability, hour) => viewability + hour.viewability, 0) /
          realtimeData.today.length
        ).toFixed(0)}%`,
        CTR: `${(
          realtimeData.today.reduce((ctr, hour) => ctr + hour.ctr, 0) / realtimeData.today.length
        ).toFixed(2)}% `,
      };

      let yesterdaysSlicedData = realtimeData.yesterday.slice(0, realtimeData.today.length);

      let totalsYesterday = {
        Revenue: yesterdaysSlicedData.reduce((rev, hour) => rev + hour.rev, 0),
        Impressions: millify(yesterdaysSlicedData.reduce((rev, hour) => rev + hour.imp, 0)),
        eCPM:
          (yesterdaysSlicedData.reduce((rev, hour) => rev + hour.rev, 0) /
            yesterdaysSlicedData.reduce((imp, hour) => imp + hour.imp, 0)) *
          1000,
        Viewability:
          yesterdaysSlicedData.reduce((viewability, hour) => viewability + hour.viewability, 0) /
          yesterdaysSlicedData.length,
        CTR:
          yesterdaysSlicedData.reduce((ctr, hour) => ctr + hour.ctr, 0) /
          yesterdaysSlicedData.length,
      };

      return (
        <Tooltip
          title={
            enabledMetrics.includes(metric.name)
              ? `Disable ${metric.name} comparison`
              : `Enable ${metric.name} comparison`
          }
        >
          <div
            style={{
              width: '20%',
              cursor: 'pointer',

              paddingBottom: 3,
              borderBottom: enabledMetrics.includes(metric.name) ? `3px solid ${metric.color}` : ``,
            }}
            className={!enabledMetrics.includes(metric.name) ? classes.lowerOpacity : ''}
            onClick={() => toggleMetric(metric.name)}
          >
            <div className={classes.metricWrapper}>
              <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                {!isMobile
                  ? metric.name
                  : metric.name.replace('Impressions', 'Imp.').replace('Viewability', 'View.')}
              </Typography>

              <Typography variant={isMobile ? 'body2' : 'h5'}>
                {!totals[metric.name].toString().includes('NaN')
                  ? totals[metric.name]
                  : totals[metric.name].replace('NaN', '0')}
                <Trend
                  enabled={!isMobile}
                  comparisonPeriod={true}
                  current={parseFloat(totals[metric.name])}
                  previous={parseFloat(totalsYesterday[metric.name])}
                />
              </Typography>
            </div>
          </div>
        </Tooltip>
      );
    });
  };

  return (
    <div
      style={{
        minWidth: isMobile ? undefined : '100%',
        maxWidth: '98%',
        padding: isMobile ? '0 15px 15px 15px' : '0',
      }}
    >
      <Grid container>{customChips()}</Grid>

      {graphData && (
        <AmCharts.React
          ref={chartRef}
          style={{
            height: isMobile ? 300 : 420,
            width: '100%',
            transition: 'none!important',
          }}
          options={{
            columnSpacing: 0,
            zoomOutOnDataUpdate: true,
            type: 'serial',
            theme: 'light',
            marginTop: 15,
            marginRight: 15,
            responsive: {
              enabled: true,
            },
            legend: {
              enabled: false,
              combineLegend: true,
              color: theme.palette.graphColors.themeGray,
              markerType: 'circle',
              divId: 'legend',
              valueAlign: 'left',
              valueWidth: 100,
            },
            dataProvider: graphData,
            categoryField: 'date',
            categoryAxis: {
              autoGridCount: false,
              labelFrequency: 1,
              tickLength: 1,
              gridCount: 12,
              labelRotation: 45,
              //parseDates: true,
              gridAlpha: 0,
              color: theme.palette.graphColors.themeGray,
              axisColor: theme.palette.graphColors.themeGray,
            },
            valueAxes: getValueAxis(),
            graphs: getGraphs(),
            chartCursor: {
              oneBalloonOnly: true,
              cursorColor: theme.palette.graphColors.themeGray,
              color: 'rgba(0,0,0,0)',
              categoryBalloonColor: 'rgba(0,0,0,0)',
              categoryBalloonDateFormat: 'YYYY-MM-DD',
              categoryBalloonAlpha: 1,
              cursorAlpha: 1,
              valueLineEnabled: true,
              valueLineBalloonEnabled: true,
              valueLineAlpha: 1,
            },
            chartScrollbar: {
              enabled: false,
              backgroundAlpha: 0,
              dragIcon: 'dragIconRectSmall',
              graph: 'g1',
              graphFillAlpha: 0,
              graphLineAlpha: 0,
              scrollbarHeight: 30,
              color: theme.palette.primary.main,
              graphLineColor: theme.palette.graphColors.themeAlmostWhite,
              selectedGraphFillColor: theme.palette.graphColors.themeAlmostWhite,
              selectedGraphFillAlpha: 0,
              graphType: 'step',
              selectedBackgroundAlpha: 0.1,
              selectedBackgroundColor: theme.palette.graphColors.themeAlmostWhite,
            },
            dataDateFormat: 'YYYY-MM-DD',
            creditsPosition: 'bottom-right',
            zoomOutButton: {
              backgroundColor: '#e0e0e0',
              backgroundAlpha: 0,
            },
            zoomOutText: '',
            listeners: [],
          }}
        />
      )}
    </div>
  );
});

export default React.memo(RealtimeTable);
