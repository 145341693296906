import {useEffect, useCallback} from 'react';
import useStore from '../store/store';
import {APICall} from '../api/api';

const useTicketsStatus = () => {
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);
  const tickets = useStore(state => state.tickets);

  const getTickets = useCallback(async () => {
    setState('tickets', null);

    let tickets = await APICall({
      endpoint: 'getTicketsByAccountId',
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    setState('tickets', tickets);
  }, [auth, setState]);

  useEffect(() => {
    if (!tickets && auth) {
      getTickets();
    }
  }, [auth, tickets, getTickets]);

  return {tickets, getTickets};
};

export default useTicketsStatus;
