import React, {useState, useEffect, useLayoutEffect} from 'react';
import AmCharts from '@amcharts/amcharts3-react/index';
import millify from 'millify';
import {useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const DecilesTable = props => {
  //console.log('decilesTableProps', props)

  const theme = useTheme();

  const balloonFunction = (item, graph) => {
    let html =
      '<table style="border-radius: 4px; padding: 8px; background: rgba(0,0,0,.7); border: none!important; color: #c7c7c7; text-align: left; font-size: 13px;  line-height: 15px; -webkit-font-smoothing: auto; font-family: Inter; width: 120%; margin-top: 7px;">';
    html +=
      '<tr style="font-weight: bold; font-size: 11px;"><td>eCPM range: ' +
      item.dataContext['eCPMrange'] +
      '</td></tr>';

    // html += `<tr><td style="padding-top: 5px; padding-right: 15px;">Passbacks: </td></td><td style="padding-top: 5px;">${millify(item.dataContext['passbacks'])}</td></tr>`

    graph.chart.graphs.map(metric => {
      if (
        !metric.hidden &&
        metric.valueField !== 'passbacks' &&
        (item.dataContext[metric.valueField] !== 0 || metric.valueField === 'revenue')
      ) {
        html += `<tr><td style="padding-top: 5px; padding-right: 15px; ${
          metric.valueField === 'revenue' ? `color: ${theme.palette.graphColors.revenue}` : ''
        }">${metric.legendValueText}</td></td><td style="padding-top: 5px;">${millify(
          item.dataContext[metric.valueField]
        )} ${metric.valueField === 'revenue' ? '€' : ''} ${
          metric.valueField === 'revenue'
            ? `(${item.dataContext['revShare']})`
            : `(${item.dataContext['impShare']})`
        }</td></tr>`;
      }
      if (metric.valueField === 'passbacks' && item.dataContext[metric.valueField] !== 0) {
        html += `<tr><td style="padding-top: 5px; padding-right: 15px; ${
          metric.valueField === 'revenue' ? `color: ${theme.palette.graphColors.revenue}` : ''
        }">${metric.legendValueText}</td></td><td style="padding-top: 5px;">${millify(
          item.dataContext[metric.valueField]
        )} (${item.dataContext['passbacksShare']})</td></tr>`;
      }
    });

    html += '</table>';

    return html;
  };

  const handleGraphReady = event => {
    event.chart.zoomToIndexes(0, props.data.length);
  };

  const balloonSettings = {
    adjustBorderColor: false,
    borderColor: '#e75d3c000',
    fillColor: '#e75d3c000',
    fillAlpha: 0,
    shadowAlpha: 0,
    color: '#fff',
    cornerRadius: 4,
    horizontalPadding: 10,
    verticalPadding: 10,
  };

  const getValueAxis = () => {
    let valueAxis = [
      {
        id: 'v0',
        title: 'Impressions',
        titleBold: false,
        //stackType: "regular",
        titleColor: theme.palette.graphColors.themeGray,
        minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
      },
      {
        id: 'v1',
        title: 'Revenue',
        titleBold: false,
        //stackType: "regular",
        titleColor: theme.palette.graphColors.revenue,
        position: 'right',
        minVerticalGap: 50,
        gridAlpha: 0,
        color: theme.palette.graphColors.revenue,
        axisColor: theme.palette.graphColors.revenue,
        minimum: 0,
      },
    ];
    return valueAxis;
  };

  const getGraphs = () => {
    let graphs = [
      // {
      //     id: 'g1',
      //     type: "column",
      //     valueAxis: 'v1',
      //     baseAxis: 'v1',
      //     bullet: 'none',
      //     useLineColorForBulletBorder: true,
      //     lineColor: theme.palette.graphColors.revenue,
      //     lineThickness: 1,
      //     legendValueText: 'Revenue',
      //     legendPeriodValueText: 'Revenue',
      //     valueField: 'revenue',
      //     fillAlphas: 1,
      //     fillColors: theme.palette.graphColors.revenue,
      //     balloon: balloonSettings,
      //     balloonFunction: balloonFunction
      // },
      // {
      //     id: 'g0',
      //     valueAxis: 'v0',
      //     bullet: 'none',
      //     useLineColorForBulletBorder: true,
      //     lineColor: theme.palette.graphColors.themeDarkGray,
      //     lineAlpha: 1,
      //     lineThickness: 3,
      //     //  negativeLineColor: layoutColors.danger,
      //     type: "smoothedLine",
      //     legendValueText: 'Impressions',
      //     legendPeriodValueText: 'Impressions',
      //     valueField: 'imp',
      //     balloon: balloonSettings,
      //     balloonFunction: balloonFunction
      // },

      // Alternative
      {
        id: 'g3',
        valueAxis: 'v0',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        fillAlphas: 0,
        columnWidth: 1,
        lineAlpha: 1,
        lineThickness: 1,
        type: 'column',
        clustered: false,
        legendValueText: 'Passbacks',
        legendPeriodValueText: 'Passbacks',
        valueField: 'passbacks',
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g0',
        type: 'column',
        valueAxis: 'v0',
        clustered: false,
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        lineAlpha: 1,
        lineThickness: 1,
        columnWidth: 1,
        legendValueText: 'Impressions',
        legendPeriodValueText: 'Impressions',
        valueField: 'imp',
        fillAlphas: 1,
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      {
        id: 'g1',
        type: 'smoothedLine',
        valueAxis: 'v1',
        baseAxis: 'v1',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.revenue,
        lineThickness: 3,
        legendValueText: 'Revenue',
        legendPeriodValueText: 'Revenue',
        valueField: 'revenue',
        fillAlphas: theme.palette.type === 'dark' ? 0.5 : 0,
        fillColors: [theme.palette.graphColors.revenue, 'rgba(0,0,0,0)'],
        balloon: balloonSettings,
        balloonFunction: balloonFunction,
      },
      //
    ];

    return graphs;
  };

  return (
    <>
      <AmCharts.React
        className="my-class"
        style={{
          height: '420px',
          maxWidth: '95%',
        }}
        options={{
          // "depth3D": 60,
          // "angle": 30,
          zoomOutOnDataUpdate: false,
          type: 'serial',
          theme: 'blur',
          marginTop: 15,
          marginRight: 15,
          responsive: {
            enabled: true,
          },
          legend: {
            enabled: false,
            combineLegend: true,
            color: theme.palette.graphColors.themeGray,
            markerType: 'circle',
            divId: 'legend',
            valueAlign: 'left',
            valueWidth: 100,
          },
          dataProvider: props.data,
          categoryField: 'eCPMrange',
          categoryAxis: {
            title: 'eCPM',
            labelRotation: 45,
            titleBold: false,
            titleColor: theme.palette.graphColors.eCPM,
            gridAlpha: 0,
            color: theme.palette.graphColors.eCPM,
            axisColor: theme.palette.graphColors.eCPM,
          },
          valueAxes: getValueAxis(),
          graphs: getGraphs(),
          chartCursor: {
            oneBalloonOnly: true,
            cursorColor: theme.palette.graphColors.themeGray,
            color: 'rgba(0,0,0,0)',
            categoryBalloonColor: 'rgba(0,0,0,0)',
            categoryBalloonDateFormat: 'YYYY-MM-DD',
            categoryBalloonAlpha: 1,
            cursorAlpha: 1,
            valueLineEnabled: true,
            valueLineBalloonEnabled: true,
            valueLineAlpha: 1,
          },
          chartScrollbar: {
            enabled: false,
            backgroundAlpha: 0,
            dragIcon: 'dragIconRectSmall',
            graph: 'g1',
            graphFillAlpha: 0,
            graphLineAlpha: 0,
            scrollbarHeight: 30,
            color: theme.palette.primary.main,
            graphLineColor: theme.palette.graphColors.themeGray,
            selectedGraphFillColor: theme.palette.graphColors.themeGray,
            selectedGraphFillAlpha: 0,
            graphType: 'step',
            selectedBackgroundAlpha: 0.1,
            selectedBackgroundColor: theme.palette.graphColors.themeGray,
          },
          dataDateFormat: 'YYYY-MM-DD',
          creditsPosition: 'bottom-right',
          zoomOutButton: {
            backgroundColor: '#e0e0e0',
            backgroundAlpha: 0,
          },
          zoomOutText: '',
          listeners: [
            //{"event": "zoomed", "method": props.handleZoom},
            {event: 'rendered', method: handleGraphReady},
          ],
        }}
      />
    </>
  );
};

export default React.memo(DecilesTable);
