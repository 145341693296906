import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles/index';
import {APICall} from '../api/api';

const useStyles = makeStyles(theme => ({
  loader: {
    position: 'absolute',
    zIndex: 99999,
    top: '0',
    width: '100%',
    left: 0,
  },
  additionalButtons: {
    margin: theme.spacing(1, 0, 1),
  },
}));

function SharedGraph(props) {
  const classes = useStyles();

  //props.match.params.token

  const [error, setError] = useState(false);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '80vh'}}
      >
        <Container component="main" maxWidth="xs" minHeight="100%">
          {props.match.params.identifier}
        </Container>
      </Grid>
    </>
  );
}

export default SharedGraph;
