import React from 'react';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AnalyticsIcon from './../img/ic_analytics.svg';
import Badge from '@material-ui/core/Badge';

const AnalyticsPropertySelect = React.memo(props => {
  const [selectionMenuOpen, setSelectionMenuOpen] = React.useState(false);

  const Menu = props => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
      <Paper
        style={{
          //   backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
          marginTop: 4,
          position: 'absolute',
          width: '100%',
          zIndex: 2,
        }}
        {...props}
      />
    );
  };

  const Blanket = props => (
    <Paper
      css={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
      }}
      {...props}
    />
  );
  const Dropdown = ({children, isOpen, target, onClose}) => (
    <Paper style={{position: 'relative'}}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </Paper>
  );
  const Svg = p => (
    <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
  );
  const DropdownIndicator = () => (
    <Paper css={{color: 'white'}}>
      <Svg>
        <path
          d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </Svg>
    </Paper>
  );
  const ChevronDown = () => (
    <Svg style={{marginRight: -6}}>
      <path
        d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  );

  const selectStyles = {
    container: provided => ({...provided, minWidth: '100%', marginTop: 0}),
    input: provided => ({...provided, color: 'inherit'}),
    control: provided => ({...provided, minWidth: '100%', marginTop: 0, background: 'inherit'}),
    menu: () => ({boxShadow: '0 1px 20px rgba(0, 0, 0, .1)', position: 'relative'}),
    menuList: provided => ({...provided, maxHeight: '60vh'}),
    option: (provided, state) => {
      //console.log(provided)
      return {
        ...provided,
        cursor: 'pointer',
        opacity: state.isFocused ? 1 : 0.8,
        //   backgroundColor: state.isFocused? 'rgba(199, 199, 199, .7)' : 'none',
        backgroundColor: 'none',

        '&:active': {
          background: 'none',
          opacity: 1,
        },
        '&:hover': {
          //backgroundColor: 'none',
          opacity: 1,
        },
      };
    },
  };

  const formatOptionLabel = ({value, label, customAdditionalLabel}) => (
    <Paper elevation={0} style={{display: 'flex'}}>
      <img src={AnalyticsIcon} style={{paddingRight: 10}} />

      {customAdditionalLabel ? (
        <Chip label={'GA4'} size={'small'} variant="outline" style={{marginRight: 8}} />
      ) : (
        ''
      )}

      <Typography variant={'body1'}>{label}</Typography>
    </Paper>
  );

  const generateOptions = () => {
    let menu = [];

    // Push GA3 options
    props.googleAuth.analyticsProperties.results.data.items.map(item => {
      item.webProperties.map(property => {
        property.profiles.map(profile => {
          profile.label = `${
            property.websiteUrl
              ? property.websiteUrl
                  .replace('https://www.', '')
                  .replace('http://www.', '')
                  .replace('http://', '')
                  .replace('https://', '')
              : ''
          } (${profile.name})`;
          menu.push({
            label: profile.label,
            value: profile,
          });
        });
      });
    });

    // Push GA4 options
    props.googleAuth.analyticsProperties.ga4.map(item => {
      item.propertySummaries.map(property => {
        property.ga4 = true;
        property.label = `${item.displayName} (${property.displayName})`;

        menu.push({
          label: property.label,
          value: property,
          customAdditionalLabel: true,
        });
      });
    });

    return menu;
  };

  return (
    <Paper style={{width: '50%', margin: '8px'}}>
      {props.googleAuth.analyticsProperties.success && (
        <Dropdown
          isOpen={selectionMenuOpen}
          onClose={() => setSelectionMenuOpen(!selectionMenuOpen)}
          target={
            <Button
              //style={{borderBottom: '2px solid #e0e0e0', borderRadius: 0, fontWeight: 600}}
              color={props.domain.assignedProperty.length === 0 ? 'primary' : 'inherit'}
              style={props.domain.assignedProperty.length === 0 ? {backgroundColor: '#F57E02'} : {}}
              fullWidth
              variant={props.domain.assignedProperty.length !== 0 ? 'text' : 'contained'}
              endIcon={<ChevronDown />}
              onClick={() => setSelectionMenuOpen(!selectionMenuOpen)}
              isSelected={selectionMenuOpen}
            >
              {props.domain.assignedProperty.length !== 0
                ? `Change assigned Analytics property`
                : 'Assign property'}
            </Button>
          }
        >
          <Select
            // value={{
            //    label: props.domain.assignedProperty[0].property.name,
            //    value: props.domain.assignedProperty[0].property
            // }}
            autoFocus
            noOptionsMessage={() => <Typography>Nothing found</Typography>}
            onBlur={() => setSelectionMenuOpen(!selectionMenuOpen)}
            formatOptionLabel={formatOptionLabel}
            backspaceRemovesValue={false}
            styles={selectStyles}
            tabSelectsValue={false}
            menuIsOpen
            isClearable={false}
            components={{DropdownIndicator, IndicatorSeparator: null}}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            placeholder={'Search'}
            options={generateOptions()}
            onChange={change => {
              setSelectionMenuOpen(!selectionMenuOpen);
              props.handleAssignedPropertyChange(
                props.domain.id,
                props.googleAuth.user.id,
                change.value
              );
            }}
          />
        </Dropdown>
      )}

      {!props.googleAuth.analyticsProperties.success && (
        <Typography>
          {props.googleAuth.name} account doesn't have any Analytics properties
        </Typography>
      )}

      {/*<>*/}
      {/*{googleAuth.analyticsProperties.success &&*/}
      {/*<Select*/}
      {/*disabled={loading}*/}
      {/*variant={"filled"}*/}
      {/*label="Assigned property"*/}
      {/*style={{minWidth: "50%"}}*/}
      {/*value={domain.assignedProperty[0].property}*/}
      {/*onChange={(event) => handleAssignedPropertyChange(domain.id, googleAuth.googleId, event.target.value)}*/}
      {/*>*/}
      {/*{googleAuth.analyticsProperties.results.data.items.map((item, propertyIndex) => {*/}
      {/*return item.webProperties.map(property => {*/}
      {/*return property.profiles.map((profile, profileIndex) => {*/}
      {/*console.log('assigned value', profile)*/}
      {/*return <MenuItem*/}
      {/*value={profile}>{property.websiteUrl.replace('https://www.', '').replace('http://www.', '')} / {profile.name}</MenuItem>*/}
      {/*})*/}
      {/*})*/}
      {/*})}*/}
      {/*</Select>*/}
      {/*}*/}
      {/*{!googleAuth.analyticsProperties.success &&*/}
      {/*<Typography>{googleAuth.name} account doesn't have any Analytics properties</Typography>*/}
      {/*}*/}
      {/*</>*/}
    </Paper>
  );
});

export default React.memo(AnalyticsPropertySelect);
