import React, {useEffect, useState} from 'react';
import {
  Button,
  Typography,
  makeStyles,
  Container,
  Grid,
  Backdrop,
  IconButton,
  TextField,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import moment from 'moment';
import {Close} from '@material-ui/icons';
import {APICall} from '../../../api/api';
import useStore from '../../../store/store';

const useStyles = makeStyles(theme => ({
  customEntriesButton: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  collapseContainer: {
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.name === 'darkTheme' ? '#fff' : 'inherit',
    backgroundColor: theme.name === 'darkTheme' ? 'rgb(26 24 29 / 95%)' : 'rgb(255 255 255 / 95%)',
    backgroundSize: `200% 200%`,
    animation: '$scaleUpDown 30s ease infinite',
  },
  entriesCount: {
    background: theme.palette.primary.main,
    padding: '6px 8px',
    borderRadius: 4,
    color: 'white',
    lineHeight: 1,
    marginLeft: 8,
    fontWeight: 'bold',
    fontSize: 12,
  },
  countWrapper: {
    background: theme.palette.secondary.main,
    borderRadius: 4,
    padding: theme.spacing(0.8),
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1,
    textAlign: 'center',
    marginLeft: 10,
  },
}));

export const CustomEntries = ({
  domain,
  customEntries,
  isSelfServe = false,
  currentAdsTXT = null,
  redirectStatus = null,
}) => {
  const entriesFromProps = customEntries?.entries ? JSON.parse(customEntries?.entries) : null;
  const classes = useStyles();
  const [entries, setEntries] = useState(entriesFromProps);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);
  const customEntriesOpen = useStore(state => state.customEntriesOpen);
  const selectedAdsTXTDomain = useStore(state => state.selectedAdsTXTDomain);

  // Handle closing of the modal
  useEffect(() => {
    if (!customEntriesOpen) {
      setEntries(entriesFromProps);
    } else {
      if (customEntriesOpen === domain.id && isSelfServe) {
        // If redirect is not successfully enabled and currently we have no custom entries saved, then paste the current ads.txt lines from the clients website
        if (!redirectStatus?.success && !customEntries?.entries && currentAdsTXT) {
          setEntries(currentAdsTXT.join('\n'));
        }

        // Reset error
        setError(null);
      }
    }
  }, [customEntriesOpen]);

  let updateCustomEntries = async () => {
    setError(null);
    setLoading(true);

    // Filter empty lines
    let filteredEntries = entries
      .split('\n')
      .filter(line => line.length)
      .join('\r\n');

    let request = await APICall({
      endpoint: 'addCustomEntries',
      options: {
        method: 'POST',
        body: JSON.stringify({
          domain,
          entries: filteredEntries,
        }),
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });
    if (!request.success) {
      setError('There was an error updating the custom entries');
    } else {
      // Recheck ads.txt statuses
      setState('checked', []);
      setState('customEntriesOpen', false);
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        color={'primary'}
        className={classes.customEntriesButton}
        style={
          selectedAdsTXTDomain === domain.id && !entries && isSelfServe
            ? {background: 'red', color: 'white'}
            : undefined
        }
        variant={
          selectedAdsTXTDomain === domain.id && !entries && isSelfServe ? 'contained' : 'text'
        }
        onClick={e => {
          e.stopPropagation();
          setState('customEntriesOpen', domain.id);
        }}
      >
        {isSelfServe ? 'Edit ads.txt' : 'Edit Custom Entries'}

        <div
          className={classes.entriesCount}
          style={
            entries
              ? undefined
              : {
                  display: 'none',
                }
          }
        >
          {entries ? entries.split('\n').length : 0}
        </div>
      </Button>

      <Backdrop className={classes.backdrop} open={customEntriesOpen === domain?.id}>
        <Container maxWidth={'md'}>
          <IconButton
            style={{
              float: 'right',
              border: '1px solid rgba(255,255,255,0.3)',
            }}
            onClick={() => {
              setState('customEntriesOpen', false);
            }}
            color={'inherit'}
          >
            <Close />
          </IconButton>
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item xs={12}>
              <Typography variant={'h4'} align={'center'} gutterBottom style={{fontWeight: 'bold'}}>
                {domain.title}/ads.txt
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Tooltip arrow interactive title={redirectStatus.tooltip}>
                <Alert
                  style={{padding: '0px 16px'}}
                  icon={redirectStatus.icon}
                  severity={redirectStatus.alert}
                >
                  <Typography>{redirectStatus.messageInCustomEntries}</Typography>
                </Alert>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant={'filled'}
                id="additionalEntries"
                label={isSelfServe ? 'Ads.txt file' : 'Additional Entries'}
                multiline
                fullWidth
                autoFocus
                minRows={12}
                maxRows={12}
                value={entries}
                onChange={e => setEntries(e.target.value)}
                inputProps={{
                  style: {
                    lineHeight: '1.5rem',
                    fontFamily: 'monospace',
                  },
                }}
              />
              <Typography variant={'subtitle'} display={'block'} color="textSecondary">
                {entries?.length ? entries?.split('\n').length : 0} line(s)
              </Typography>
              {customEntries.timestamp && (
                <Typography variant={'caption'} display={'block'} color="textSecondary">
                  Last update: {moment(customEntries.timestamp).format('YYYY-MM-DD HH:mm')}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'center', marginTop: 20}}>
            <Button
              color={'primary'}
              style={{marginRight: 10}}
              onClick={() => {
                setState('customEntriesOpen', false);
              }}
              disabled={loading}
            >
              Close
            </Button>

            <Button
              variant="contained"
              color={'primary'}
              className={classes.button}
              style={{marginRight: 10}}
              onClick={updateCustomEntries}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={16} /> : undefined}
            >
              Save
            </Button>

            {error && (
              <Grid item xs={12} style={{textAlign: 'center', marginTop: 20}}>
                <Typography color={'error'}>{error}</Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </Backdrop>
    </>
  );
};
