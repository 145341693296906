import {useEffect, useCallback, useState} from 'react';
import useStore from '../store/store';
import {APICall} from '../api/api';

const usePublishers = () => {
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);
  const publishers = useStore(state => state.publishers);
  const [publishersError, setPublishersError] = useState(null);
  const [forcedSelfServeOnboarding, setForcedSelfServeOnboarding] = useState(null);
  const [publisherWithActiveOrderGeneration, setPublisherWithActiveOrderGeneration] = useState(
    null
  );
  const [publishersHasOwnGam, setPublishersHasOwnGam] = useState(null);

  // Returns boolean if forced self serve onboarding is required
  useEffect(() => {
    if (publishers && auth) {
      setPublisherWithActiveOrderGeneration(
        publishers.publishers.find(publisher => publisher.order_generator_job_id !== null)
      );

      setPublishersHasOwnGam(
        publishers.publishers.find(publisher => publisher.own_gam === 1) ? true : false
      );

      setForcedSelfServeOnboarding(
        publishers.publishers.filter(
          publisher =>
            publisher.order_generator_job_id === null && publisher.gam_initialized === null
        ).length > 0 &&
          publishers.publishers.length === 1 &&
          auth.type >= 5 &&
          auth.type < 8
      );

      // Check if onboarding step 1 should be autocompleted for SS clients
      if (
        auth.onboarding_completed !== 1 &&
        auth.type >= 5 &&
        auth.type < 8 &&
        publishers.publishers?.find(publisher => publisher.gam_initialized === 1)
      ) {
        setState('completedStep', 1);
      }
    }
  }, [publishers, auth, setState]);

  const getPublishers = useCallback(async () => {
    setForcedSelfServeOnboarding(null);

    let publishers = await APICall({
      endpoint: 'getPublishers',
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    if (publishers.success) {
      publishers.accountId = auth.accountId;
      setState('publishers', publishers);
    } else {
      setPublishersError('Failed to load publishers');
    }
  }, [auth, setState]);

  useEffect(() => {
    // Flush older publishers data if user authenticated via WB login
    if (publishers && auth && auth?.accountId !== publishers?.accountId) {
      setState('publishers', null);
    }

    if (!publishers && auth) {
      getPublishers();
    }
  }, [auth, publishers, getPublishers, setState]);

  useEffect(() => {
    if (publishers) {
      setPublishersError(null);
    }
  }, [setPublishersError, publishers]);

  return {
    publishers,
    getPublishers,
    publishersError,
    forcedSelfServeOnboarding,
    publisherWithActiveOrderGeneration,
    setForcedSelfServeOnboarding,
    publishersHasOwnGam,
  };
};

export default usePublishers;
