import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import lscache from 'lscache';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SuccessFormulaImage from './../img/success_formula.png';
import ReactGA from 'react-ga4';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgb(0 0 0 / 75%)',
    backgroundSize: `200% 200%`,
    animation: '$scaleUpDown 30s ease infinite',
  },
  promoImg: {
    width: '52%',
    margin: '0 auto',
    display: 'block',
    borderRadius: 8,
  },
}));

const YieldFormulaPromo = () => {
  const classes = useStyles();

  const [visible, setVisible] = useState(true);

  useEffect(
    () =>
      ReactGA.event({
        category: 'Yield Formula Promo',
        action: 'Shown',
      }),
    []
  );

  return (
    <Backdrop className={classes.backdrop} open={visible}>
      <Container maxWidth={'md'}>
        <IconButton
          style={{
            float: 'right',
            border: '1px solid rgba(255,255,255,0.3)',
          }}
          onClick={() => {
            setVisible(false);
            lscache.set('yield_promo_closed', true, false);
            ReactGA.event({
              category: 'Yield Formula Promo',
              action: 'Closed',
            });
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={4} direction="row" justifyContent={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <img className={classes.promoImg} src={SuccessFormulaImage} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h3'} align={'center'} style={{fontWeight: 'bold'}} gutterBottom>
              Yield Formula
            </Typography>
            <Typography align={'center'}>
              See how to unlock your full website’s potential and generate more ad revenue.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <Button
              startIcon={<TrendingUpIcon />}
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                setVisible(false);
                ReactGA.event({
                  category: 'Yield Formula Promo',
                  action: 'Link click',
                });
                lscache.set('yield_promo_closed', true, false);
              }}
              href={`/yield-formula`}
            >
              Go to Yield Formula
            </Button>

            <Button
              variant={'text'}
              color={'primary'}
              style={{marginLeft: 10}}
              onClick={() => {
                setVisible(false);
                lscache.set('yield_promo_closed', true, false);
                ReactGA.event({
                  category: 'Yield Formula Promo',
                  action: 'Closed',
                });
              }}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <Typography variant={'subtitle'} align={'center'}>
              Your website's revenue depends on just a few key metrics: number of users, loyalty
              measure in visit sessions, engagement and monetization strength. We have developed a
              simple and sophisticated view of those key metrics to help you understand how to
              unlock 100% of your revenue potential.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Backdrop>
  );
};

export default YieldFormulaPromo;
