const steps = {
  1: {
    title: 'Sign in with Google',
    active: true,
    tooltipText: `Authenticate with Google so we can access your Google Ad Manager. This ensures secure and automated integration.`,
  },
  2: {
    title: 'Select your Ad Manager network',
    tooltipText: `Choose the Ad Manager network to link with Setupad. You can add multiple networks post initial setup.`,
  },
  3: {
    title: 'Enable API access',
    tooltipText: `Ensure API access is enabled for your chosen network to establish a connection. Note: API access is off by default.`,
  },
  4: {
    title: 'Import sites to Setupad, or create new ones',
    tooltipText: `Select the sites you want to monetize with Prebid. If you don't have any sites in your Ad Manager network, you can create new ones.`,
  },
  5: {
    title: 'Start automatic Ad Manager configuration',
    tooltipText: `Once you select the sites, we’ll auto-configure your Ad Manager for Prebid.`,
  },
};

module.exports = {
  steps,
};
