import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const boxShadow = 'inset rgba(0, 0, 0, 0.7) 0px 1px 4px, rgba(0, 0, 0, 0) 0px 1px 2px';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: 100,
    width: '100%',
    borderRadius: 8,
    position: 'relative',
    overflow: 'hidden',
    animation: '$slideLeftBack 120s linear infinite, $bringAttention 1s linear 2',
    backgroundImage:
      'url(https://setupad.com/wp-content/uploads/2023/05/How-to-Test-Google-Ad-Manager-Setup-1024x349.png)',
    backgroundSize: 'contain',
    boxShadow: boxShadow,
    transition: 'box-shadow 1s ease-in-out',
  },
  '@keyframes bringAttention': {
    '0%': {boxShadow: boxShadow},
    '50%': {
      boxShadow:
        'inset rgba(0, 0, 0, 0.7) 0px 1px 4px, rgba(0, 0, 0, 0.4) 0px 1px 2px, 0 0 25px #1cbc9c80',
    },
    '100%': {boxShadow: boxShadow},
  },
  textOverlay: {
    color: 'white!important',
    textAlign: 'center',
    zIndex: 6,
    textShadow: '1px 1px 1px rgb(0 0 0)',
  },
  tint: {
    position: 'absolute',
    background: `rgba(0,0,0,0.05)`,
    width: '100%',
    height: '100%',
    zIndex: 4,
  },
  progressWrapper: {
    position: 'absolute',
    background: `linear-gradient(90deg, #20b5c4 0%, rgb(28 188 156) 100%, rgba(0,212,255,0) 100%)`,
    width: '100%',
    height: '100%',
    transition: 'left 1s ease-in-out',
    opacity: 1,
    borderRadius: 8,
    animation: '$gradientAnimation 6s linear infinite',
    zIndex: 5,
  },
  image: {
    height: 300,
    margin: '0 auto',
    display: 'block',
    position: 'relative',
    animation: '$slideLeftBack 5s linear infinite',
  },
  '@keyframes slideLeftBack': {
    '0%': {backgroundPosition: '0% auto'},
    '100%': {backgroundPosition: '100% 0'},
  },
}));

export function JobProgressBar({percentage, message}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper} alignItems={'center'} alignContent={'center'}>
      <Grid item xs={12} className={classes.textOverlay}>
        <Typography variant={'h6'}>{percentage ? `${percentage}% completed` : ``}</Typography>
        <Typography variant={'body2'} style={{fontSize: 16}}>
          {message}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.tint} />
      <Grid
        item
        xs={12}
        className={classes.progressWrapper}
        style={{left: `${(parseFloat(percentage) > 0 ? percentage : 0) - 100}%`}}
      />
    </Grid>
  );
}
