import React from 'react';
import CreatableSelect from 'react-select/creatable';
import useStore from '../../../store/store';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles/index';

const useStyles = makeStyles(theme => ({
  domainImage: {
    height: 20,
    width: 20,
    position: 'relative',
    top: 7,
    backgroundColor: 'white',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '80%',
    borderRadius: 4,
    display: 'inline-block',
    marginRight: 8,
  },
}));

export function CustomUrlList({listOfUrls, setListofUrls}) {
  const classes = useStyles();
  const theme = useTheme();

  const domains = useStore(state => state.domains);

  const selectStyles = {
    container: provided => ({...provided, minWidth: '100%', marginTop: 0}),
    input: provided => ({...provided, color: 'inherit'}),
    indicatorSeparator: provided => ({
      ...provided,
      backgroundColor: theme.palette.graphColors.themeDarkGray,
    }),
    placeholder: provided => ({...provided, color: 'inherit', fontWeight: 'bold'}),
    control: provided => ({
      ...provided,
      minHeight: 50,
      minWidth: '100%',
      marginTop: 0,
      background: 'inherit',
      border: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
      // This line disable the blue border
      boxShadow: '0 !important',
      '&:hover': {
        //  border: '0 !important',
      },
    }),
    multiValue: provided => ({
      ...provided,
      background: theme.palette.background.paper,
      // border: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
      borderRadius: 4,
      margin: 3,
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: 'inherit',
      fontWeight: 'bold',
      fontSize: 14,
    }),
    menu: provided => ({
      ...provided,
      background: theme.palette.background.default,
      boxShadow: '0 1px 20px rgba(0, 0, 0, .2)',
      zIndex: 9999999,
    }),
    menuList: provided => ({...provided, maxHeight: '60vh'}),
    option: (provided, state) => {
      return {
        ...provided,
        cursor: 'pointer',
        color: 'inherit',
        fontWeight: 'bold',
        fontSize: 14,
        opacity: state.isFocused ? 1 : 0.8,
        backgroundColor: state.isFocused ? 'rgba(199, 199, 199, .05)' : 'none',
        '&:active': {
          background: 'none',
          opacity: 1,
        },
        '&:hover': {
          opacity: 1,
        },
      };
    },
  };

  const formatOptionLabel = ({value, label}) => {
    return (
      <Grid item xs={12} style={{paddingBottom: 7}}>
        <div
          className={classes.domainImage}
          style={{
            backgroundImage: `url(https://www.google.com/s2/favicons?domain=https://${label}&sz=256)`,
          }}
        />
        {label}
      </Grid>
    );
  };

  return (
    <Grid item xs={12}>
      <CreatableSelect
        isMulti
        placeholder="Type advertiser URLs to block"
        styles={selectStyles}
        formatCreateLabel={inputValue => `Add "${inputValue}" to the blocked URLs list`}
        formatOptionLabel={formatOptionLabel}
        value={listOfUrls}
        onChange={setListofUrls}
        noOptionsMessage={() => 'Type to block URl(s)'}
      />
    </Grid>
  );
}
