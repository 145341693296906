import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, List} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import {APICall} from '../api/api';
import {MCMlistItem} from '../components/subcomponents/MCM/listItem';
import useStore from '../store/store';
import {Alert} from '@material-ui/lab';
import {MCMquickStats} from '../components/subcomponents/MCM/MCMquickStats';
import usePublishers from '../hooks/usePublishers';

const GridIContainer = props => <Grid container {...props} />;

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    borderRadius: '4px',
    border: '1px solid #7979793b',
    marginBottom: 20,
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
}));

export const MCM = React.memo(({login, sidebar = false, hidden = false}) => {
  const classes = useStyles();
  const {publishers} = usePublishers();
  const MCMstatuses = useStore(state => state.MCMstatuses);
  const setState = useStore(state => state.setState);
  const [error, setError] = useState(null);
  const auth = useStore(state => state.auth);

  useEffect(() => {
    if (sidebar && auth?.menu) {
      getPublishers();
    }
    if (!auth) {
      setState('MCMstatuses', {});
    }
  }, [auth]);

  useEffect(() => {
    if (sidebar && publishers && auth) {
      setState('MCMstatuses', {});
    }
  }, [publishers]);

  useEffect(() => {
    if (sidebar && auth && publishers && Object.keys(MCMstatuses).length === 0) {
      getMCMstatuses();
    }
  }, [MCMstatuses]);

  const getPublishers = async () => {
    let publishers = await APICall({
      endpoint: 'getPublishers',
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    if (publishers.success) {
      setState('publishers', publishers);
    } else {
      setError('Error getting publishers list');
    }
  };

  const getMCMstatuses = async () => {
    for (const publisher of publishers.publishers) {
      if (!MCMstatuses[publisher.publisherId]) {
        let mcmStatus = await getMCMstatus(publisher);

        if (mcmStatus.success && mcmStatus.data.childPublisher) {
          mcmStatus.data.sites = await getSites(publisher);

          // Mark onboarding MCM step as completed if MCM status were loaded then it means it was completed
          if (auth.type < 5) {
            setState('completedStep', 2);
          }
        }

        setState('MCMstatuses', {
          ...useStore.getState().MCMstatuses,
          [publisher.publisherId]: mcmStatus,
        });
      }
    }
  };

  const getMCMstatus = async publisher => {
    try {
      let mcmStatus = await APICall({
        endpoint: 'getMCMstatus',
        params: publisher.publisherId,
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        },
      });
      return mcmStatus;
    } catch (e) {
      setError(`Error getting MCM for publisher ${publisher.publisherId} status: ${e.message}`);
    }
  };

  const getSites = async publisher => {
    let sites = await APICall({
      endpoint: 'getSitesByNetworkCode',
      params: `${publisher.mcmCompanyId}/${publisher.networkCode}`,
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    return sites;
  };

  return (
    <>
      {sidebar && !hidden && auth && <MCMquickStats key={auth} />}
      {!sidebar && auth && (
        <Container component="main" maxWidth="md" className={classes.viewWrapper}>
          <div className={classes.root}>
            <Typography variant={'h4'} gutterBottom>
              Multiple Customer Management
            </Typography>
            <Typography variant={'subtitle'} gutterBottom>
              A mandatory program created by Google to disclose a relationship between a publisher
              and a third-party service provider (Setupad) who can consult, manage, and represent
              inventory on the publisher’s behalf.
            </Typography>
          </div>

          <br />

          <Grid container spacing={1} direction={'column'}>
            {publishers?.success &&
              publishers?.publishers?.length > 0 &&
              publishers.publishers.map((publisher, i) => (
                <List disablePadding className={classes.wrapper} component={GridIContainer}>
                  <MCMlistItem
                    key={i}
                    mcmData={MCMstatuses[publisher.publisherId]?.data}
                    publisher={publisher}
                    getPublishers={getPublishers}
                    login={auth}
                  />
                </List>
              ))}
          </Grid>

          {error && (
            <Alert severity={'error'} style={{marginTop: 15}}>
              {error}
            </Alert>
          )}
        </Container>
      )}
    </>
  );
});
