import React, {useState} from 'react';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {Grid, ListItem, ListItemText, Collapse, Tooltip} from '@material-ui/core';
import {NewPublisher} from './NewPublisher';
import {SitesList} from './SitesList';
import {makeStyles} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import usePublishers from '../../../hooks/usePublishers';

const useStyles = makeStyles(theme => ({
  successIcon: {
    position: 'relative',
    marginRight: 6,
    fontSize: 20,
    top: 4,
  },
}));

export function AdManagerListItem({publisher}) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const {forcedSelfServeOnboarding} = usePublishers();

  return (
    <>
      {!forcedSelfServeOnboarding && (
        <ListItem button onClick={() => setOpen(!open)} selected={open}>
          <>
            <ListItemText
              primary={
                <>
                  {publisher.gam_initialized === 1 ? (
                    <Tooltip title={'Google Ad Manager is initialized'} arrow>
                      <CheckCircleIcon
                        fontSize={'inherit'}
                        color={'secondary'}
                        className={classes.successIcon}
                      />
                    </Tooltip>
                  ) : (
                    ''
                  )}
                  {publisher.publisherName} account
                </>
              }
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </>
        </ListItem>
      )}

      <Collapse in={open} timeout={0} style={{width: '100%'}}>
        <Grid container spacing={2} style={{padding: forcedSelfServeOnboarding ? 44 : 30}}>
          {!publisher?.gam_initialized && <NewPublisher publisher={publisher} />}
          {publisher.gam_initialized && <SitesList publisher={publisher} />}
        </Grid>
      </Collapse>
    </>
  );
}
