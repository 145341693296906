import React from 'react';
import {CircularProgress, Box, Typography, Tooltip} from '@material-ui/core';
import usePublishers from '../../hooks/usePublishers';
import useOrderGeneratorStatus from '../../hooks/useOrderGeneratorStatus';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" style={{right: -4}}>
      <CircularProgress variant={'static'} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary" style={{fontSize: 10}}>
          {props.progress}%
        </Typography>
      </Box>
    </Box>
  );
}

export const OrderGeneratorQuickStats = () => {
  const {publisherWithActiveOrderGeneration} = usePublishers();
  const {orderGeneratorError, jobStatus} = useOrderGeneratorStatus(
    publisherWithActiveOrderGeneration
  );

  return (
    <div
      style={{
        position: 'absolute',
        top: '15px',
        right: '17px',
        cursor: 'help',
      }}
    >
      {jobStatus && (
        <Tooltip
          title={
            <>
              {orderGeneratorError && (
                <Typography variant={'body2'} color={'error'}>
                  {orderGeneratorError}
                </Typography>
              )}
              {jobStatus && <Typography variant={'body2'}>{jobStatus?.statusMessage}</Typography>}
            </>
          }
        >
          <span>
            <CircularProgressWithLabel
              size={35}
              value={jobStatus?.taskProgress}
              progress={jobStatus?.taskProgress}
              color="secondary"
            />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
