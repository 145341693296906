import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import useStore from '../../../store/store';
import {NetworksList} from './NetworksList';
import {OrderStatus} from './OrderStatus';
import {LoginStep} from './LoginStep';
import {SitesList} from './SitesList';
import {Alert} from '@material-ui/lab';
import FadeIn from 'react-fade-in/lib/FadeIn';

export function NewPublisher({publisher}) {
  const [loading, setLoading] = useState(null);
  const [assignedAuth, setAssignedAuth] = useState(null);
  const [allNetworks, setAllNetworks] = useState(null);
  const [assignedNetwork, setAssignedNetwork] = useState(null);
  const domains = useStore(state => state.domains);
  const auth = useStore(state => state.auth);
  const isPublisherWithDomain =
    domains.filter(domain => domain.publisherId === publisher.publisherId && domain.siteId)
      ?.length > 0;

  return (
    <Grid container spacing={2}>
      <LoginStep
        publisher={publisher}
        assignedAuth={assignedAuth}
        setAssignedAuth={setAssignedAuth}
      />

      {assignedAuth && (
        <NetworksList
          setLoading={setLoading}
          loading={loading}
          publisher={publisher}
          assignedAuth={assignedAuth}
          allNetworks={allNetworks}
          setAllNetworks={setAllNetworks}
          assignedNetwork={assignedNetwork}
          setAssignedNetwork={setAssignedNetwork}
        />
      )}

      {assignedAuth &&
        allNetworks &&
        publisher.gam_auth_id === assignedAuth?.id &&
        assignedNetwork && <SitesList assignedNetwork={assignedNetwork} publisher={publisher} />}

      {assignedAuth &&
        allNetworks &&
        publisher.gam_auth_id === assignedAuth?.id &&
        (isPublisherWithDomain || auth.type < 5) &&
        assignedNetwork && (
          <>
            <OrderStatus setLoading={setLoading} loading={loading} publisher={publisher} />
            {publisher.order_generator_job_id && (
              <FadeIn>
                <Alert severity="info">
                  You can continue completing next onboarding steps now, we will continue Ad Manager
                  configuration in the background.
                </Alert>
              </FadeIn>
            )}
          </>
        )}
    </Grid>
  );
}
