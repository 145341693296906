import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {Skeleton} from '@material-ui/lab';
import useTicketsStatus from '../../../hooks/useTicketsStatus';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {Typography} from '@material-ui/core';
import getBackgroundColorByStatus from './helpers/bgColorByStatus';

const useStyles = makeStyles(theme => {
  return {
    tooltip: {
      //maxWidth: 'none',
      maxWidth: 500,
      padding: theme.spacing(2),
    },
    countWrapper: {
      background: theme.palette.background.default,
      borderRadius: 4,
      padding: theme.spacing(0.8),
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      textAlign: 'center',
    },
    statusWrapper: {
      fontWeight: 600,
      fontSize: 10,
      background: theme.palette.graphColors.themeLightGrayOpaque,
      padding: theme.spacing(0.5),
      textAlign: 'center',
      textTransform: 'uppercase',
      borderRadius: 4,
    },
    icon: {
      position: 'relative',
      top: 2,
      marginRight: theme.spacing(1),
    },
  };
});

export const TicketsQuickStats = () => {
  const classes = useStyles();
  const {tickets} = useTicketsStatus();
  const theme = useTheme();

  return (
    <div>
      {!tickets && <Skeleton height={28} width={25} />}

      {tickets && (
        <Tooltip
          arrow
          classes={{tooltip: classes.tooltip}}
          interactive
          title={
            <React.Fragment>
              <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                {!tickets.success && tickets.error}

                {tickets.tickets?.issues?.map((issue, index) => {
                  return (
                    <>
                      <Grid item xs={2} key={index}>
                        <div className={classes.statusWrapper}>{issue.key}</div>
                      </Grid>
                      <Grid item xs={7} key={index}>
                        <img
                          src={issue.fields.issuetype.iconUrl}
                          alt={issue.fields.issuetype.name}
                          className={classes.icon}
                        />
                        <Typography variant={'caption'}>{issue.fields.summary}</Typography>
                      </Grid>
                      <Grid item xs={3} key={index}>
                        <div
                          className={classes.statusWrapper}
                          style={{
                            background:
                              theme.palette.graphColors[
                                getBackgroundColorByStatus(issue.fields.status.name)
                              ],
                            color: 'white',
                          }}
                        >
                          {issue.fields.status.name}
                        </div>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </React.Fragment>
          }
        >
          <div>
            {tickets.error && (
              <ReportProblemIcon
                fontSize={'inherit'}
                style={{
                  color: 'red',
                  top: 2,
                  position: 'relative',
                }}
              />
            )}
            {tickets.success && (
              <div className={classes.countWrapper}>{tickets.tickets.issues.length}</div>
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
