import React, {useEffect, useState, useCallback} from 'react';
import {Grid, Button, Typography, CircularProgress} from '@material-ui/core';
import useStore from '../../../store/store';
import {APICall} from '../../../api/api';
import {Alert, AlertTitle} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';
import adManagerLogo from '../../../components/img/ad-manager-logo.svg';
import {JobProgressBar} from './JobProgressBar';
import usePublishers from '../../../hooks/usePublishers';
import useOrderGeneratorStatus from '../../../hooks/useOrderGeneratorStatus';
import {HistoricalDataStatus} from './HistoricalDataStatus';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 10,
    marginBottom: 5,
    marginTop: 5,
  },
  adManagerLogo: {
    height: 24,
    marginLeft: 15,
  },
}));

export function OrderStatus({publisher, loading, setLoading}) {
  const [error, setError] = useState(null);
  const auth = useStore(state => state.auth);
  const classes = useStyles();
  const setState = useStore(state => state.setState);
  const {getPublishers} = usePublishers();
  const {orderGeneratorError, jobStatus} = useOrderGeneratorStatus(publisher);

  const generateOrder = async () => {
    setLoading(true);
    setError(null);

    let generateOrder = await APICall({
      endpoint: 'generateOrders',
      params: publisher.publisherId,
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    if (!generateOrder.success) {
      setError(`Error starting order generation. Please try again later.`);
    } else {
      // Complete first SS onboarding step
      setState('completedStep', 1);
      getPublishers();
    }

    setLoading(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          {!publisher.order_generator_job_id && (
            <Grid item xs={12}>
              <Button
                color={'primary'}
                variant={'contained'}
                style={{marginTop: 40, fontWeight: 600}}
                onClick={generateOrder}
                disabled={loading}
                fullWidth
                className={classes.button}
                startIcon={
                  loading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <img
                      src={adManagerLogo}
                      className={classes.adManagerLogo}
                      alt="Google Ad Manager"
                    />
                  )
                }
              >
                {loading
                  ? `Please wait, starting order generation.`
                  : `Initialize Setupad Self-Serve`}
              </Button>
            </Grid>
          )}

          {jobStatus?.taskStatus === 'error' && (
            <Grid item xs={12}>
              <Button
                color={'primary'}
                variant={'outlined'}
                style={{marginRight: 40, fontWeight: 600}}
                onClick={generateOrder}
                disabled={loading}
                fullWidth
                className={classes.button}
                startIcon={
                  <img
                    src={adManagerLogo}
                    className={classes.adManagerLogo}
                    alt="Google Ad Manager"
                  />
                }
              >
                Run Setupad Self-Serve initialization again
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            {publisher.order_generator_job_id && (
              <Typography
                variant={'h6'}
                align={'center'}
                gutterBottom
                style={{fontWeight: 300, marginBottom: 14}}
              >
                {jobStatus?.statusMessage}
              </Typography>
            )}
          </Grid>

          {publisher.order_generator_job_id && (
            <>
              <Grid xs={12}>
                <JobProgressBar
                  percentage={jobStatus?.taskProgress}
                  message={jobStatus?.lastEntry.value}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {(error || orderGeneratorError) && (
        <Alert severity="error">
          <AlertTitle>{error || orderGeneratorError}</AlertTitle>
        </Alert>
      )}

      {jobStatus?.taskStatus !== 'success' && <HistoricalDataStatus publisher={publisher} />}
    </>
  );
}
