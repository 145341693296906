import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import ConnectAnalytics from '.././components/img/conectAnalytics.png';
import ReactGA from 'react-ga4';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  collapseContainer: {
    width: '100%',
  },
  media: {
    height: 200,
  },
  wrapper: {
    flexGrow: 1,
    paddingTop: 30,
  },
}));

export const Tools = React.memo(props => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xl" className={classes.viewWrapper}>
      <Grid container className={classes.wrapper} spacing={3} direction={'row'}>
        <Grid item xs={12} sm={4} md={6} lg={4} xl={2}>
          <Card className={classes.root} elevation={1}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image="https://www.omgchrome.com/wp-content/uploads/2015/06/web-store-icon.jpg"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Setupad Chrome Extension
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Use your current ClientUI login details after download. See banner statistics
                  directly on your website and report unwanted ads.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="big"
                color="primary"
                href={
                  'https://chrome.google.com/webstore/detail/setupad/fjcgkkkiimmoijkamkmpffokeninmhhh'
                }
                target={'_blank'}
                onClick={() => {
                  ReactGA.event({
                    category: 'Tools',
                    action: 'click',
                    label: 'Chrome extension link clicked',
                  });
                }}
              >
                Install from Chrome Web Store
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {props.login.google_analytics_enabled === 1 && (
          <Grid item xs={12} sm={4} md={6} lg={4} xl={2}>
            <Card className={classes.root} elevation={1}>
              <CardActionArea>
                <CardMedia className={classes.media} image={ConnectAnalytics} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Google Analytics Integration
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Add your website's GA data to Setupad the dashboard
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="big"
                  color="primary"
                  href={'/google-analytics'}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Tools',
                      action: 'click',
                      label: 'Analytics Integration link',
                    });
                  }}
                >
                  Connect Google Analytics
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
});
