import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import lscache from 'lscache';
import LogoDarkTheme from './../components/img/setupad-logo-UA-white.svg';
import LogoWhiteTheme from './../components/img/setupad-logo-UA-darkblue.svg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles/index';
import {APICall} from '../api/api';

const useStyles = makeStyles(theme => ({
  loader: {
    position: 'absolute',
    zIndex: 99999,
    top: '0',
    width: '100%',
    left: 0,
  },
  additionalButtons: {
    margin: theme.spacing(1, 0, 1),
  },
}));

function Login(props) {
  const classes = useStyles();
  const [credentials, setCredentials] = useState({
    _username: '',
    _password: '',
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [credentialsChanged, setCredentialsChanged] = useState(null);

  useEffect(() => {
    let auth = lscache.get('auth') ? lscache.get('auth') : false;
    if (auth && auth.token) {
      props.history.push(
        props.location.state && props.location.state.from ? props.location.state.from : '/home'
      );
    } else {
      props.postAuth();
    }
  }, []);

  let onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      let response = await APICall({
        endpoint: 'authenticate',
        options: {
          method: 'post',
          body: JSON.stringify({
            username: credentials._username,
            password: credentials._password,
          }),
          headers: {
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });
      if (response.token) {
        // Use Local Storage to store auth data
        lscache.set('auth', response);
        props.postAuth();
        props.history.push(
          props.location.state && props.location.state.from ? props.location.state.from : '/home'
        );
      } else {
        setError(response.error);
      }
      setLoading(false);
    } catch (error) {
      setError(`Error. Your IP might be blocked for too many requests, try again in an hour.`);
      setLoading(false);
    }
  };

  let handleInputChange = event => {
    setError(null);
    const {value, name} = event.target;
    credentials[name] = value;
    setCredentials(credentials);
    setCredentialsChanged(true);
  };

  return (
    <>
      {loading && <LinearProgress variant="query" className={classes.loader} position={'fixed'} />}

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '80vh'}}
      >
        <Container component="main" maxWidth="xs" minHeight="100%" style={{padding: 50}}>
          <div>
            <img
              src={props.theme === 'dark' ? LogoDarkTheme : LogoWhiteTheme}
              style={{width: '100%'}}
            />
            <form action="/" method="POST" noValidate onSubmit={onSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="username"
                label="Username"
                name="_username"
                autoComplete="user"
                value={credentials.username}
                onChange={handleInputChange}
                autoFocus
                InputLabelProps={{shrink: true}}
              />
              <TextField
                style={{marginTop: '8px'}}
                variant="outlined"
                margin="normal"
                fullWidth
                name="_password"
                label="Password"
                type="password"
                id="password"
                autoCmplete="current-password"
                value={credentials.password}
                onChange={handleInputChange}
                InputLabelProps={{shrink: true}}
              />
              <Button
                className={classes.additionalButtons}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || !credentialsChanged}
              >
                Sign In
              </Button>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Link href="https://setupad.com/sign-up/" target={'_blank'} variant="body2">
                    {"Don't have an account?"}
                  </Link>
                </Grid>
              </Grid>

              {error && (
                <Box mt={2}>
                  <Typography variant="body2" color="error" align="center">
                    {error}
                  </Typography>
                </Box>
              )}

              {/*<Box mt={2}>*/}
              {/*<Typography variant="body2" color="textSecondary" align="center">*/}
              {/*Previous reporting interface can be found <Link href="https://reports.setupad.com/" target={"_blank"} variant="body2">{"here"}</Link>*/}
              {/*</Typography>*/}
              {/*</Box>*/}
            </form>
          </div>
        </Container>
      </Grid>
    </>
  );
}

export default Login;
