import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AnalyticsIcon from './../components/img/ic_analytics.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import {APICall} from '../api/api';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import FadeIn from 'react-fade-in';
import {Skeleton} from '@material-ui/lab';
import AnalyticsPropertySelect from './../components/subcomponents/AnalyticsPropertySelect';
import {useGoogleLogin} from '@react-oauth/google';
import googleLogo from './../components/img/Google__G__Logo.svg';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Computer from '@material-ui/icons/Computer';
import IconButton from '@material-ui/core/IconButton';
import ReactGA from 'react-ga4';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import LockIcon from '@material-ui/icons/Lock';
import useStore from '../store/store';
import usePersistentStore from '../store/persistentStore';

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    // background: '#efefef',
    padding: 50,
    borderRadius: '4px',
    border: '1px solid #7979793b',
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
  listItem: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  fabProgress: {
    position: 'absolute',
    // top: -6,
    // left: -6,
    zIndex: 1,
  },
}));

export const GoogleAnalytics = React.memo(props => {
  const classes = useStyles();

  const [accountDomains, setAccountDomains] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingDomain, setLoadingDomain] = React.useState(false);
  const setState = useStore(state => state.setState);
  const googleAuth = usePersistentStore(state => state.googleAuth);
  const setPersistentState = usePersistentStore(state => state.setState);

  useEffect(() => {
    async function loadData() {
      await getAssignedGoogleProperties();

      // Get and update analytics profiles if googleAuth is defined in persistent state
      if (googleAuth) {
        let profiles = await getAnalyticsProperties(googleAuth.user.id);
        // Update profiles in google auth on success, on failure delete auth object
        if (profiles.success) {
          setPersistentState('googleAuth', {
            ...usePersistentStore.getState().googleAuth,
            profiles: profiles.data,
          });
        } else {
          setPersistentState('googleAuth', false);
        }
      }
    }

    loadData();
  }, []);

  const getAssignedGoogleProperties = async () => {
    let assignedGoogleProperties = await APICall({
      endpoint: `getAssignedGoogleProperties`,
      options: {
        method: 'get',
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });

    let accountDomains = [];
    // Set account domains from login props
    props.login.menu.menu[1].children.map(item => {
      accountDomains.push({
        // Find assigned google properties from query by domain id
        assignedProperty: assignedGoogleProperties.filter(
          property => property.domainId === item.path[2]
        ),
        id: item.path[2],
        name: item.data.menu.alias,
      });
    });

    setAccountDomains(accountDomains);
    setLoading(false);
    setLoadingDomain(false);
  };

  const getAnalyticsProperties = async googleId => {
    let query = await APICall({
      endpoint: `getAnalyticsProperties`,
      params: `${googleId}`,
      options: {
        method: 'get',
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });
    return query;
  };

  const googleLoginHook = useGoogleLogin({
    flow: 'auth-code',
    scope: 'profile email https://www.googleapis.com/auth/analytics.readonly',
    onSuccess: tokenResponse => getGoogleAuth(tokenResponse),
  });

  const getGoogleAuth = async response => {
    let googleAuth;
    setLoading(true);
    setPersistentState('googleAuth', false);

    if (!response || !response.code) {
      googleAuth = {
        success: false,
        error: 'No response from Google',
      };
    }

    if (response.code) {
      ReactGA.event({
        category: 'Google analytics',
        action: 'click',
        label: `login`,
      });

      let query = await APICall({
        endpoint: 'googleAuth',
        options: {
          method: 'post',
          body: JSON.stringify({
            googleCode: response,
          }),
          headers: {
            Authorization: `Bearer ${props.login.token}`,
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });

      googleAuth = query;
    }

    googleAuth.analyticsProperties = await getAnalyticsProperties(googleAuth.user.id);
    setPersistentState('googleAuth', googleAuth);
    setLoading(false);
  };

  const handleAssignedPropertyChange = async (domainId, googleAuthId, property) => {
    setLoading(true);

    ReactGA.event({
      category: 'Google analytics',
      action: 'click',
      label: `assign property`,
    });

    let query = await APICall({
      endpoint: 'assignGoogleProperty',
      options: {
        method: 'post',
        body: JSON.stringify({
          domainId: domainId,
          googleAuthId: googleAuthId,
          property: property,
        }),
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });

    setState('completedStep', 5);
    await getAssignedGoogleProperties();
  };

  const disconnectProperty = async domainId => {
    setLoadingDomain(domainId);

    ReactGA.event({
      category: 'Google analytics',
      action: 'click',
      label: 'disconnect property',
    });

    await APICall({
      endpoint: 'disconnectGoogleProperty',
      params: domainId,
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });

    await getAssignedGoogleProperties();
  };

  return (
    <Container component="main" maxWidth="xl" className={classes.viewWrapper}>
      <FadeIn>
        <form noValidate autoComplete="off">
          <Grid
            container
            className={classes.wrapper}
            spacing={2}
            direction="row"
            alignItems={'center'}
          >
            <Grid item xs={8}>
              {!googleAuth && (
                <Typography variant={'h6'}>
                  Log In with Google to assign Analytics properties to Domains
                </Typography>
              )}

              {googleAuth && !googleAuth.error && (
                <>
                  <Typography>You are now logged in as:</Typography>
                  <Chip
                    style={{
                      border: 'none',
                      marginRight: 25,
                      paddingRight: 10,
                      borderRadius: 0,
                      marginBottom: 15,
                      marginTop: 15,
                    }}
                    className={classes.hideOnMobile}
                    icon={<Avatar style={{marginRight: 0}} src={googleAuth.user.picture} />}
                    label={
                      <Typography style={{lineHeight: 1}}>
                        {googleAuth.user.name}
                        <br />
                        <Typography variant={'caption'} color={'textSecondary'}>
                          {googleAuth.user.email}
                        </Typography>
                      </Typography>
                    }
                    variant="outlined"
                  />
                </>
              )}
            </Grid>

            <Grid item xs={4} style={{textAlign: 'right', paddingRight: '18px'}}>
              <Button
                startIcon={<img src={googleLogo} alt={'Google Analytics'} />}
                onClick={googleLoginHook}
                variant={'contained'}
              >
                {!googleAuth?.success ? 'Log In with Google' : 'Change Google account'}
              </Button>

              {!googleAuth.success && <Typography color={'error'}>{googleAuth.error}</Typography>}
            </Grid>

            <Grid item xs={12}>
              <Alert
                severity={'success'}
                //variant={"filled"}
                icon={<LockIcon fontSize="inherit" />}
                //  style={{borderRadius: 0, background: 'red'}}
              >
                <AlertTitle>Your data is safe with us!</AlertTitle>
                Linking your Google Analytics account allows Setupad to access the assigned
                properties' data and use it for analytical purposes within Setupad services.
              </Alert>
            </Grid>

            <Grid item xs={12}>
              {/*<Divider/>*/}
              <Typography>Account domains:</Typography>
              <List>
                {accountDomains &&
                  accountDomains.map(domain => {
                    return (
                      <ListItem disableRipple button className={classes.listItem} component={Link}>
                        <ListItemIcon>
                          {domain.assignedProperty.length !== 0 && (
                            <Tooltip
                              title={`Disconnect Google Analytics property ${domain.assignedProperty[0].property.label}`}
                            >
                              <>
                                {props.login.username !== 'demo' && (
                                  <IconButton
                                    onClick={() =>
                                      disconnectProperty(domain.assignedProperty[0].domainId)
                                    }
                                  >
                                    {loadingDomain === domain.assignedProperty[0].domainId && (
                                      <CircularProgress
                                        size={30}
                                        color={'secondary'}
                                        className={classes.fabProgress}
                                      />
                                    )}
                                    <HighlightOffIcon />
                                  </IconButton>
                                )}
                              </>
                            </Tooltip>
                          )}
                          {domain.assignedProperty.length === 0 && (
                            <IconButton>
                              <Computer />
                            </IconButton>
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={<Typography variant={'h6'}>{domain.name}</Typography>}
                          secondary={
                            domain.assignedProperty.length !== 0 ? (
                              <Typography>
                                <img
                                  src={AnalyticsIcon}
                                  style={{
                                    paddingRight: 10,
                                    height: 20,
                                    top: 6,
                                    position: 'relative',
                                  }}
                                />
                                {domain.assignedProperty[0].property.ga4 && (
                                  <Chip
                                    label={'GA4'}
                                    size={'small'}
                                    variant="outline"
                                    style={{marginRight: 8}}
                                  />
                                )}
                                {domain.assignedProperty[0].property.label}
                              </Typography>
                            ) : (
                              'Not assigned to Analytics property'
                            )
                          }
                        />

                        {googleAuth && !googleAuth.error && (
                          <AnalyticsPropertySelect
                            domain={domain}
                            googleAuth={googleAuth}
                            handleAssignedPropertyChange={handleAssignedPropertyChange}
                          />
                        )}

                        {loading && (
                          <Grid item xs={6}>
                            <Skeleton style={{width: '100%'}} height={50} />
                          </Grid>
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>

            {loading && !accountDomains && (
              <div style={{width: '100%', textAlign: 'center'}}>
                <CircularProgress color="primary" />
              </div>
            )}
          </Grid>
        </form>
      </FadeIn>
    </Container>
  );
});
