import React, {useState} from 'react';
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Collapse,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import MatUiLink from '@material-ui/core/Link/index';
import {APICall} from '../../../api/api';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {Alert, AlertTitle} from '@material-ui/lab';
import useStore from '../../../store/store';

// Images imports
import GAM_admin from '../../img/MCM/GAM_admin.png';
import GAM_code from '../../img/MCM/GAM_code.png';
import GAM_email from '../../img/MCM/GAM_email.png';
import GAS_admin from '../../img/MCM/GAS_admin.png';
import GAS_email from '../../img/MCM/GAS_email.png';

const useStyles = makeStyles(theme => ({
  listWrapper: {
    border: '1px solid #7979793b',
    //  padding: 10,
    borderRadius: 6,
    marginBottom: 10,
    width: '100%',
  },
}));

export function NewMCM({publisher, getPublishers, login}) {
  const [fields, setFields] = useState({
    name: '',
    email: '',
    networkCode: '',
  });
  const [dialogImage, setDialogImage] = React.useState(null);
  const classes = useStyles();
  const [data, setData] = useState(false);
  const [option, setOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const setState = useStore(state => state.setState);

  const inviteChildPublisher = () => {
    try {
      setError(null);
      setLoading(true);
      APICall({
        endpoint: 'inviteChildPublisher',
        params: publisher.publisherId,
        options: {
          method: 'POST',
          body: JSON.stringify({...fields, invite_type: option}),
          headers: {
            Authorization: `Bearer ${login.token}`,
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      }).then(res => {
        setData(res);
        if (res.success) {
          getPublishers();
          setState('completedStep', 2);
        } else {
          setError(res.error);
        }
        setLoading(false);
      });
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setFields({
      ...fields,
      [field]: value.trim(),
    });
  };

  const changeOption = selected => {
    setFields({
      name: '',
      email: '',
      networkCode: '',
    });
    setOption(selected === option ? null : selected);
  };

  return (
    <>
      <Dialog open={dialogImage} onClose={() => setDialogImage(null)} maxWidth={'xl'}>
        <DialogContent>
          <img src={dialogImage} alt="dialogImage" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogImage(null)} color={'primary'}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.listWrapper}>
        <ListItem button onClick={() => changeOption(1)}>
          <ListItemText
            secondary={
              <Typography style={{fontWeight: 'bold'}}>
                You already have a Google Ad Manager account with{' '}
                <MatUiLink
                  target={'_blank'}
                  style={{cursor: 'pointer'}}
                  onClick={e => {
                    setDialogImage(GAM_admin);
                    e.stopPropagation();
                  }}
                >
                  Administrator access.
                </MatUiLink>{' '}
              </Typography>
            }
            primary={'Option 1:'}
          />
          {option === 1 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={option === 1} timeout={500} style={{width: '100%'}}>
          <Grid container spacing={2} style={{padding: '0 16px 32px 16px'}}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="Google Ad Manager email"
                autoFocus
                value={fields.email}
                helperText={
                  <MatUiLink
                    style={{cursor: 'pointer'}}
                    target={'_blank'}
                    onClick={e => {
                      setDialogImage(GAM_email);
                      e.stopPropagation();
                    }}
                  >
                    Go to Admin -> Access & authorization
                  </MatUiLink>
                }
                onChange={event => handleChange('email', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="name"
                label="Company name or Name Surname *"
                value={fields.name}
                onChange={event => handleChange('name', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="name"
                label="Google Ad Manager Network code *"
                value={fields.networkCode}
                helperText={
                  <MatUiLink
                    style={{cursor: 'pointer'}}
                    target={'_blank'}
                    onClick={e => {
                      setDialogImage(GAM_code);
                      e.stopPropagation();
                    }}
                  >
                    Go to Admin -> Global Settings -> Network settings
                  </MatUiLink>
                }
                onChange={event => handleChange('networkCode', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={inviteChildPublisher}
                disabled={
                  loading || !fields.name || !fields.email || (option === 1 && !fields.networkCode)
                }
                startIcon={loading ? <CircularProgress size={16} /> : undefined}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </div>

      <div className={classes.listWrapper}>
        <ListItem button onClick={() => changeOption(2)}>
          <ListItemText
            secondary={
              <Typography style={{fontWeight: 'bold'}}>
                You have a Google AdSense account, but not Google Ad Manager.
              </Typography>
            }
            primary={'Option 2:'}
          />
          {option === 2 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={option === 2} timeout={500} style={{width: '100%'}}>
          <Grid container spacing={2} style={{padding: '0 16px 32px 16px'}}>
            <Grid item xs={12}>
              <Typography>
                Fill in your Google AdSense credentials. Check, if you have{' '}
                <MatUiLink
                  target={'_blank'}
                  style={{cursor: 'pointer'}}
                  onClick={e => {
                    setDialogImage(GAS_admin);
                    e.stopPropagation();
                  }}
                >
                  Admin access
                </MatUiLink>{' '}
                and within 24 hours you will receive an invitation to create Google Ad Manager
                account.
              </Typography>
              <TextField
                fullWidth
                id="email"
                label="Google AdSense email *"
                value={fields.email}
                helperText={
                  <MatUiLink
                    target={'_blank'}
                    style={{cursor: 'pointer'}}
                    onClick={e => {
                      setDialogImage(GAS_email);
                      e.stopPropagation();
                    }}
                  >
                    Go to Account -> Settings -> Personal settings
                  </MatUiLink>
                }
                onChange={event => handleChange('email', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="name"
                label="Company name or Name Surname *"
                value={fields.name}
                onChange={event => handleChange('name', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={inviteChildPublisher}
                disabled={
                  loading || !fields.name || !fields.email || (option === 1 && !fields.networkCode)
                }
                startIcon={loading ? <CircularProgress size={16} /> : undefined}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </div>

      <div className={classes.listWrapper}>
        <ListItem button onClick={() => changeOption(3)}>
          <ListItemText
            primary={'Option 3:'}
            secondary={
              <Typography style={{fontWeight: 'bold'}}>
                You don’t have a Google AdSense or Google Ad Manager account.
              </Typography>
            }
          />
          {option === 3 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={option === 3} timeout={500} style={{width: '100%'}}>
          <Grid container spacing={2} style={{padding: '0 16px 32px 16px'}}>
            <Grid item xs={12}>
              <Typography>
                Send us your credentials and within 24 hours you will receive an invitation to
                create a Google Ad Manager account.
              </Typography>
              <TextField
                fullWidth
                id="email"
                label="Email address *"
                value={fields.email}
                onChange={event => handleChange('email', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="name"
                label="Company name or Name Surname *"
                value={fields.name}
                onChange={event => handleChange('name', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={inviteChildPublisher}
                disabled={
                  loading || !fields.name || !fields.email || (option === 1 && !fields.networkCode)
                }
                startIcon={loading ? <CircularProgress size={16} /> : undefined}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </div>

      {error && (
        <Grid item xs={12}>
          <Alert severity={'error'} style={{marginTop: 15}}>
            <AlertTitle>Error id: {login.fos_user_id}</AlertTitle>
            Please try again or contact your account executive.
          </Alert>
        </Grid>
      )}
    </>
  );
}
