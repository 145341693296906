export const options = [
  {
    title: 'WordPress',
    content: [
      {
        type: 'step',
        text:
          'Go to your WordPress admin page -> Plugins and install "301 Redirects – Easy Redirect Manager" plugin',
      },
      {
        type: 'step',
        text:
          'Once installed and enabled, find plugin settings page in WordPress admin sidebar Settings -> “301 Redirects',
      },
      {
        type: 'step',
        text: `Create a new redirect rule. In the "Redirect From" field enter:`,
      },
      {
        type: 'code',
        text: 'ads.txt',
      },
      {
        type: 'step',
        text: 'Set "Redirect To" field to:',
      },
      {
        type: 'code',
        text: 'https://wb.setupad.com/api/getAdsTXT/{accountId}/{mode}/{domainId}',
      },
      {
        type: 'step',
        text:
          'Click "Save" and come back to the Client UI ads.txt page to see if the redirect is working correctly. Your domain should show "Redirected to Setupad".',
      },
      {
        type: 'step',
        text:
          'If the redirect does not work, try deleting the ads.txt file from your public_html FTP folder. Also, make sure there are no ads.txt installed in the WordPress.',
      },
      {
        type: 'step',
        text:
          'Note, any redirection plugin should work as long as it provides a 301 redirect option.',
      },
    ],
  },
  ,
  {
    title: 'Cloudflare',
    content: [
      {
        type: 'step',
        text: 'Go to the Cloudflare panel and select your website',
      },
      {
        type: 'step',
        text: 'Click "Redirect rules" -> "Create Rule"',
      },
      {
        type: 'step',
        text: 'Set Rule name to "Setupad Ads.txt Redirect"',
      },
      {
        type: 'step',
        text: 'Select "Custom filter expression"',
      },
      {
        type: 'step',
        text: `For the filter "Field" select "URI Path"`,
      },
      {
        type: 'step',
        text: `For the filter "Operator" select "Contains"`,
      },
      {
        type: 'step',
        text: `For the filter "Value" use "/ads.txt"`,
      },
      {
        type: 'step',
        text: 'Select "Static" URL redirect type',
      },
      {
        type: 'step',
        text: 'Enter redirect "URL":',
      },
      {
        type: 'code',
        text: 'https://wb.setupad.com/api/getAdsTXT/{accountId}/{mode}/{domainId}',
      },
      {
        type: 'step',
        text: 'Set redirect "Status code" to "301"',
      },
      {
        type: 'step',
        text: 'Click "Save"',
      },
    ],
  },
  {
    title: 'Apache',
    content: [
      {
        type: 'step',
        text:
          'Look for the .htaccess file in the root directory of your site. If it doesn’t exist, create one.',
      },
      {
        type: 'step',
        text:
          'Create a backup of the file. Then check if the original file does not already have the following lines.',
      },
      {
        type: 'code',
        text: `Options +FollowSymLinks\r\nRewriteEngine on`,
      },
      {
        type: 'step',
        text: 'Add the following line to the file:',
      },
      {
        type: 'code',
        text:
          'Redirect 301 /ads.txt https://wb.setupad.com/api/getAdsTXT/{accountId}/{mode}/{domainId}',
      },
      {
        type: 'step',
        text: 'Save changes.',
      },
      {
        type: 'step',
        text:
          'Test if the redirect works by going to Your-Domain.com/ads.txt and ensuring that the URL and the content have changed to the hosted ads.txt.',
      },
    ],
  },
  {
    title: 'Nginx',
    content: [
      {
        type: 'step',
        text:
          'Locate your Nginx site configuration. On Unix this is commonly in /etc/nginx/conf/sites-enabled On Windows, look for the conf directory in the directory nginx was installed',
      },
      {
        type: 'step',
        text: 'Create a backup of the file, then add the following line:',
      },
      {
        type: 'code',
        text: `rewrite ^/ads.txt$ https://wb.setupad.com/api/getAdsTXT/{accountId}/{mode}/{domainId} permanent;`,
      },
      {
        type: 'code',
        text:
          'server {\r\n [...]\r\n server_name yourdomain.com www.yourdomain.com;\r\n rewrite ^/ads.txt$ https://wb.setupad.com/api/getAdsTXT/{accountId}/{mode}/{domainId} permanent;\r\n [...] \r\n}',
      },
      {
        type: 'step',
        text: 'Save the file and restart Nginx.',
      },
      {
        type: 'step',
        text:
          'Test that the redirect works by going to Your-Domain.com/ads.txt and ensuring that the URL and the content have changed to the hosted ads.txt.',
      },
    ],
  },
  {
    title: 'Windows Server',
    content: [
      {
        type: 'step',
        text:
          'Ensure the rewrite module is installed. You can find documentation here: https://learn.microsoft.com/en-us/iis/extensions/url-rewrite-module/using-the-url-rewrite-module',
      },
      {
        type: 'step',
        text: 'Open web.config in the root of your site',
      },
      {
        type: 'step',
        text: `Create a backup of the file, then add the following configuration in the file:`,
      },
      {
        type: 'code',
        text:
          '<configuration>\r\n &nbsp;&nbsp;<system.webServer>\r\n &nbsp;&nbsp;&nbsp;<rewrite>\r\n &nbsp;&nbsp;&nbsp;&nbsp;<rules>\r\n &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<rule name="Setupad">\r\n &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<match url="ads.txt" />\r\n &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<action type="Redirect" redirectType="Permanent" url="https://wb.setupad.com/api/getAdsTXT/{accountId}/{mode}/{domainId}" />\r\n &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</rule>\r\n &nbsp;&nbsp;&nbsp;&nbsp;</rules>\r\n &nbsp;&nbsp;&nbsp;</rewrite>\r\n &nbsp;&nbsp;</system.webServer>\r\n </configuration>',
      },
      {
        type: 'step',
        text: 'Save the file, build and deploy your website.',
      },
      {
        type: 'step',
        text:
          'Test that the redirect works by going to Your-Domain.com/ads.txt and ensuring that the URL and the content have changed to the hosted ads.txt',
      },
    ],
  },
  {
    title: 'Node.js / Express',
    content: [
      {
        type: 'step',
        text: 'Open the node.js file where your routing is configured done.',
      },
      {
        type: 'step',
        text: 'Configure ads.txt get route similarly:',
      },
      {
        type: 'code',
        text: `app.get("/ads.txt", function(req, res) {\r\n&nbsp;&nbsp;res.redirect(301, "https://wb.setupad.com/api/getAdsTXT/{accountId}/{mode}/{domainId}");\r\n});`,
      },
      {
        type: 'step',
        text: 'Save the file, build and deploy your website.',
      },
      {
        type: 'step',
        text:
          'Test that the redirect works by going to Your-Domain.com/ads.txt and ensuring that the URL and the content have changed to the hosted ads.txt',
      },
    ],
  },
];
