import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {steps} from './steps';
import mediumZoom from 'medium-zoom';

function ImageZoom({zoom, src, alt, background}) {
  const zoomRef = React.useRef(zoom.clone({background}));

  function attachZoom(image) {
    zoomRef.current.attach(image);
  }

  return (
    <img
      src={src}
      alt={alt}
      ref={attachZoom}
      style={{
        width: '100%',
        boxShadow: '5px 5px 70px rgba(0,0,0,0.3)',
        marginTop: 15,
        width: '100%',
        borderRadius: 4,
      }}
    />
  );
}

export function Guide({hasGAM}) {
  const zoom = React.useRef(mediumZoom({background: '#000', margin: 48}));

  return (
    <Grid container spacing={2} style={{padding: 30}}>
      <Grid item xs={12}>
        <Typography variant={'h4'}>What's next?</Typography>
      </Grid>
      {steps[hasGAM ? 'hasGAM' : 'noGAM'].map((step, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Typography
              //variant={'h6'}
              color={'primary'}
              style={{
                display: 'inline-block',
                border: `1px solid #1AB6C5`,
                lineHeight: '24px',
                borderRadius: 90,
                width: 28,
                textAlign: 'center',
                height: 28,
                marginRight: 7,
              }}
            >
              {index + 1}
            </Typography>
            <Typography display={'inline'}>{step[0]}</Typography>
            {console.log(step[1])}
            {step?.[1]?.map(imgUrl => (
              <ImageZoom
                zoom={zoom.current}
                src={require(`./images/${imgUrl}`)}
                alt={`Step ${index + 1}`}
              />
            ))}
          </Grid>
        );
      })}
    </Grid>
  );
}
