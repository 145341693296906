import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles/index';
import {APICall} from '../api/api';
import Alert from '@material-ui/lab/Alert';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import useStore from '../store/store';

const useStyles = makeStyles(theme => ({
  loader: {
    position: 'absolute',
    zIndex: 99999,
    top: '0',
    width: '100%',
    left: 0,
  },
  additionalButtons: {
    margin: theme.spacing(1, 0, 1),
  },
}));

function ChangePassword(props) {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const setState = useStore(state => state.setState);

  let onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      let response = await APICall({
        endpoint: 'remindPassword',
        params: email,
      });
      if (response.success) {
        setState('completedStep', 6);
        setSuccess(true);
      } else {
        setError(response.error);
      }
      setLoading(false);
    } catch (error) {
      setError('Error, try again');
      setLoading(false);
    }
  };

  let handleInputChange = event => {
    setError(null);
    setEmail(event.target.value);
  };

  return (
    <>
      {loading && <LinearProgress variant="query" className={classes.loader} position={'fixed'} />}

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '80vh'}}
      >
        <Container
          component="main"
          maxWidth="xs"
          minHeight="100%"
          style={{padding: 50, border: '1px solid #7979793b'}}
        >
          <div>
            <Box mt={2} style={{textAlign: 'center'}}>
              {/* <VpnKeyIcon style={{fontSize: 120}} /> */}
              <img
                src={'https://setupad.com/wp-content/themes/setupad/assets/img/contact.svg'}
                style={{width: '45%', marginBottom: 35}}
              />
            </Box>
            <form action="/" method="POST" noValidate onSubmit={onSubmit}>
              <Typography variant={'h6'} align={'center'} gutterBottom>
                Enter your email to reset your password
              </Typography>
              <TextField
                style={{marginTop: '15px'}}
                //  variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                name="email"
                label="Email address"
                type="email"
                id="email"
                value={email}
                onChange={handleInputChange}
              />
              {!success && (
                <Button
                  disabled={loading}
                  className={classes.additionalButtons}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Send
                </Button>
              )}

              {error && (
                <Box mt={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}

              {success && (
                <Box mt={2}>
                  <Alert severity="success">
                    Password reset link has been sent. Note the link is only valid for one hour.
                    Check your spam or junk folder if you cannot find it.
                  </Alert>
                </Box>
              )}

              {/*<Box mt={2}>*/}
              {/*<Typography variant="body2" align="center">*/}
              {/*Or cantact us contact@setupad.com*/}
              {/*</Typography>*/}
              {/*</Box>*/}
            </form>
          </div>
        </Container>
      </Grid>
    </>
  );
}

export default ChangePassword;
