import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Typography,
  TextField,
  Tooltip,
  Paper,
  Checkbox,
  Avatar,
} from '@material-ui/core';
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles/index';
import {countries} from 'country-flags-svg';
import Select from 'react-select';
import useStore from '../../store/store';
import {FixedSizeList as List} from 'react-window';

const StyledTableCell = withStyles(theme => ({
  head: {
    background: theme.palette.graphColors.themeDarkGray,
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    fontWeight: 'bold',
    padding: 8,
  },
  body: {
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
  flagImg: {
    width: '24px',
    top: 2,
    marginRight: 7,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  table: {
    maxHeight: '70vh',
    borderRadius: 4,
  },
  flagImg: {
    height: 24,
    border: 4,
  },
  smallAvatar: {
    width: theme.spacing(4.5),
    height: theme.spacing(3),

    float: 'left',
    marginLeft: -7,
    border: `2px solid ${theme.palette.graphColors.themeDarkGray}`,
    fontSize: 9,
    backgroundColor: theme.palette.graphColors.themeDarkGray,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    borderRadius: 5,
  },
}));

export function CMPvendorsTable({vendorsList, data, setData}) {
  const theme = useTheme();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [filteredList, setFilteredList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const territorialScopeList = useStore(state => state.territorialScopeList);
  const environmentsList = useStore(state => state.environmentsList);

  const [filters, setFilters] = useState(null);

  useEffect(() => {
    let list = [...vendorsList];

    // Enabled or disable vendor first, based on the enabledVendorIds and disabledVendorIds
    list.forEach(vendor => {
      if (
        (vendor.enabledByDefault && !data.disabledVendorIds.includes(vendor.id)) ||
        data.enabledVendorIds.includes(vendor.id)
      ) {
        vendor.enabled = true;
      } else if (data.disabledVendorIds.includes(vendor.id)) {
        vendor.enabled = false;
      }
    });

    // Filter by name
    if (filters?.searchQuery?.length > 0) {
      list = list.filter(vendor =>
        vendor.name.toLowerCase().includes(filters?.searchQuery.toLowerCase())
      );
    }

    // Filter by international transfers
    if (filters?.internationalTransfers) {
      if (filters?.internationalTransfers.value === 1) {
        list = list.filter(vendor => !vendor.internationalTransfers);
      }

      if (filters?.internationalTransfers.value === 2) {
        list = list.filter(vendor => vendor.internationalTransfers);
      }
    }

    // Filter by territorial scope
    if (filters?.territorialScope) {
      list = list.filter(v =>
        filters.territorialScope
          ?.map(({value}) => value)
          .every(scope => v.territorialScope.includes(scope))
      );
    }

    // Filter environment scope
    if (filters?.environment) {
      list = list.filter(v =>
        filters.environment?.map(({value}) => value).every(scope => v.environments.includes(scope))
      );
    }

    setFilteredList(list);
  }, [filters, vendorsList, data]);

  // Reset page when filters change
  useEffect(() => {
    setPage(0);
  }, [filters]);

  const toggleVendor = (vendor, checked) => {
    // Add to enabled array if user wants to enable vendor ands its not enabled by default
    let update = {...data};

    if (checked) {
      if (!vendor.enabledByDefault) update.enabledVendorIds.push(vendor.id);
      update.disabledVendorIds = update.disabledVendorIds.filter(id => id !== vendor.id);
    }

    // Remove from enabled array if user wants to disable vendor
    if (!checked) {
      if (vendor.enabledByDefault) update.disabledVendorIds.push(vendor.id);
      update.enabledVendorIds = update.enabledVendorIds.filter(id => id !== vendor.id);
    }

    setData(update);
  };

  const selectStyles = {
    container: provided => ({...provided, minWidth: '100%', marginTop: 0}),
    input: provided => ({...provided, color: theme.palette.text.primary}),
    singleValue: provided => ({...provided, color: theme.palette.text.primary, fontSize: 12}),
    multiValue: provided => ({
      ...provided,
      backgroundColor: `none`,
      padding: 2,
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: theme.palette.text.primary,
      fontSize: 12,
    }),
    control: provided => ({
      ...provided,
      minWidth: '100%',
      marginTop: 0,
      background: 'inherit',
      border: 0,
      // This line disable the blue border
      boxShadow: 'none',
    }),
    menu: provided => ({...provided, background: theme.palette.graphColors.themeDarkGray}),
    menuList: provided => ({...provided, height: '10!important'}),
    option: (provided, state) => {
      return {
        ...provided,
        cursor: 'pointer',
        color: 'inherit',
        opacity: state.isFocused ? 1 : 0.8,
        backgroundColor: state.isFocused ? 'rgba(199, 199, 199, .05)' : 'none',
        '&:active': {
          background: 'none',
          opacity: 1,
        },
        '&:hover': {
          backgroundColor: 'none',
          opacity: 1,
        },
      };
    },
  };

  const MenuList = ({children}) => {
    const height = 45;
    const containerHeight = children.length * height > 300 ? 300 : children.length * height;

    return (
      <List height={containerHeight} itemCount={children.length} itemSize={height}>
        {({index, style}) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  function renderTeritorialScope(territorialScope) {
    const maxItems = 3;
    const remaining = territorialScope.length - maxItems;

    return (
      <>
        {territorialScope.slice(0, maxItems).map(code => {
          let country = countries.find(country => country.iso2 === code);
          if (!country) country = {name: code}; // handle "Other" country
          return (
            <Tooltip
              arrow
              title={<Typography key={country.name}>{country.name}</Typography>}
              key={country.name}
              interactive
            >
              <Avatar alt={country.name} src={country?.flag} className={classes.smallAvatar} />
            </Tooltip>
          );
        })}
        {remaining > 0 && (
          <Tooltip
            arrow
            title={territorialScope.slice(maxItems).map(code => {
              let country = countries.find(country => country.iso2 === code);
              if (!country) return null;
              return <Typography key={country.name}>{country.name}</Typography>;
            })}
          >
            <Avatar className={classes.smallAvatar} color={'primary'}>
              +{remaining}
            </Avatar>
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <TableContainer className={classes.table}>
      <Table stickyHeader component={Paper}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{padding: 20}}>
              Opt-in
            </StyledTableCell>
            <StyledTableCell align="center">Name</StyledTableCell>
            <StyledTableCell align="center">Id</StyledTableCell>
            <StyledTableCell align="center">Territorial scope</StyledTableCell>
            <StyledTableCell align="center">Environment</StyledTableCell>
            <StyledTableCell align="center">
              International Transfers outside of the EU/EEA
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <StyledTableCell align="center">
              {/* Disable opt-in/disable for all, for now as per Elgars request.
              Upon reanabling, test the functionality as it never was live. */}
              {/* <Checkbox
                checked={filteredList.every(vendor => vendor.enabled)}
                color={'primary'}
                onChange={({target: {checked}}) => {
                  if (checked) {
                    setFilteredList(filteredList.map(vendor => ({...vendor, enabled: true})));
                  } else {
                    setFilteredList(filteredList.map(vendor => ({...vendor, enabled: false})));
                  }
                }}
              /> */}
            </StyledTableCell>
            <StyledTableCell align="center">
              <TextField
                fullWidth
                placeholder={'Search'}
                inputProps={{
                  style: {fontSize: 14, color: theme.palette.text.primary},
                }}
                onChange={({target: {value}}) => setFilters({...filters, searchQuery: value})}
              />
            </StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
            <StyledTableCell align="center">
              <Select
                placeholder={'Filter'}
                components={{MenuList, IndicatorSeparator: null}}
                styles={selectStyles}
                isMulti
                onChange={change => {
                  setFilters({...filters, territorialScope: change});
                }}
                value={filters?.territorialScope}
                options={territorialScopeList?.map(code => {
                  let country = countries.find(country => country.iso2 === code);
                  return {
                    value: code,
                    label: country?.name ?? code,
                  };
                })}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              <Select
                placeholder={'Filter'}
                components={{MenuList, IndicatorSeparator: null}}
                styles={selectStyles}
                isMulti
                onChange={change => {
                  setFilters({...filters, environment: change});
                }}
                value={filters?.environment}
                options={environmentsList?.map(environment => {
                  return {
                    value: environment,
                    label: environment,
                  };
                })}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              <Select
                placeholder={'Filter'}
                components={{MenuList, IndicatorSeparator: null}}
                styles={selectStyles}
                onChange={change => {
                  setFilters({...filters, internationalTransfers: change});
                }}
                value={filters?.internationalTransfers}
                options={[
                  {
                    value: 2,
                    label: 'Yes',
                  },
                  {
                    value: 1,
                    label: 'No',
                  },
                  {
                    value: 0,
                    label: 'All',
                  },
                ]}
              />
            </StyledTableCell>
          </TableRow>

          {filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(vendor => (
            <TableRow key={vendor.id}>
              <StyledTableCell align="center">
                <Checkbox
                  labelId="darkTheme"
                  checked={vendor.enabled}
                  color={'primary'}
                  onChange={({target: {checked}}) => toggleVendor(vendor, checked)}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{textDecoration: 'underline dotted'}}>
                {vendor.name}
              </StyledTableCell>
              <StyledTableCell align="center">{vendor.id}</StyledTableCell>
              <StyledTableCell align="center">
                {renderTeritorialScope(vendor.territorialScope)}
              </StyledTableCell>
              <StyledTableCell align="center">{vendor.environments.join(',')}</StyledTableCell>{' '}
              <StyledTableCell align="center">
                {vendor.internationalTransfers ? 'Yes' : 'No'}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={6}
              count={filteredList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {'aria-label': 'vendors per page'},
                native: true,
              }}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={({target}) => {
                setRowsPerPage(parseInt(target.value));
                setPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
