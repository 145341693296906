export const steps = {
  hasGAM: [
    [
      `You will receive an invite from Setupad. You can accept the invite as long as you have the following permission for Role under “Manage People”: Edit User, Roles, and Teams. Click on the URL in the email`,
      ['1_Click_on_following_link_in_Email.png'],
    ],
    [
      `Go to Admin - Multiple Customer Management (MCM) -
    click on SIA Setupad`,
      ['2_Click_On_SIA_Setupad_Parent_Publisher.png'],
    ],
    [`Click Accept`, ['3_Click_Accept.png']],
    [
      `Go to Admin - Multiple Customer Management (MCM) -
    status should be approved`,
      ['4_Approved.png'],
    ],
  ],
  noGAM: [
    [
      'You will receive an email inviting you to create a GAM account and stating that Setupad has requested to manage your ad inventory.',
      ['1.png'],
    ],
    [
      `By clicking 'View request', you will be asked to fill out the following information:`,
      ['2.png', '3.png', '4.png', '5.png'],
    ],
    [
      `After submitting all the information, you should receive a
      confirmation email. The approval usually takes 2-10 days.`,
      ['6.png'],
    ],
  ],
};
