import React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import {Alert, Skeleton} from '@material-ui/lab';
import {useTheme, makeStyles} from '@material-ui/core/styles/index';
import useStore from '../../store/store';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  ListItemText,
  ListItem,
  ListItemIcon,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
  IconButton,
  withStyles,
} from '@material-ui/core';

import {FileCopy as FileCopyIcon, ExpandLess, ExpandMore} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  favIcon: {
    height: 20,
    width: 20,
    position: 'relative',
    top: 3,
    backgroundColor: 'white',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '80%',
    borderRadius: 4,
    display: 'inline-block',
    marginRight: 8,
  },
  alert: {padding: '0px 14px', marginRight: 5},
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    background: theme.palette.graphColors.themeDarkGray,
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
  body: {
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
}))(TableCell);

export function InventoryItem({domain, checkedDomain, index, open, setOpen, forceOpen}) {
  const theme = useTheme();
  const auth = useStore(state => state.auth);
  const classes = useStyles();

  if (forceOpen) {
    setOpen(domain);
  }

  return (
    <>
      <ListItem
        key={index}
        button
        style={{borderTop: `1px solid ${theme.palette.graphColors.themeLightGrayOpaque}`}}
        onClick={() => {
          if (checkedDomain && checkedDomain?.length) {
            setOpen(open === domain ? null : domain);
          }
        }}
      >
        <ListItemIcon>
          <div
            className={classes.favIcon}
            style={{
              backgroundImage: `url(https://www.google.com/s2/favicons?domain=https://${domain}&sz=256)`,
              top: -1,
            }}
          />
        </ListItemIcon>

        <ListItemText primary={domain} />

        {checkedDomain?.length === 0 && (
          <Alert className={classes.alert} severity={'error'}>
            <Typography>0 tags</Typography>
          </Alert>
        )}

        {checkedDomain?.length > 0 && (
          <Alert className={classes.alert} severity={'success'}>
            <Typography>{checkedDomain.length} tags</Typography>
          </Alert>
        )}

        {!checkedDomain && (
          <Skeleton
            className={classes.alert}
            variant={'rect'}
            width={235}
            height={44}
            style={{
              borderRadius: 4,
              marginRight: 15,
            }}
          />
        )}

        <IconButton disabled={!checkedDomain || !checkedDomain?.length}>
          {open === domain ? <ExpandLess /> : checkedDomain ? <ExpandMore /> : ``}

          {!checkedDomain && <CircularProgress size={'1.2rem'} disableShrink />}
        </IconButton>
      </ListItem>

      <Collapse in={open === domain} timeout={0} style={{width: '100%'}}>
        <Grid container spacing={2} style={{padding: 18, paddingTop: 10}}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{fontWeight: 'bold'}}>Account ID</StyledTableCell>
                  <StyledTableCell style={{fontWeight: 'bold'}}>Tag name</StyledTableCell>
                  <StyledTableCell style={{fontWeight: 'bold'}}>Placement ID</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkedDomain?.map((row, i) => (
                  <TableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      {auth.accountId}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.tagName}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.tagId}
                      <Tooltip title={'Copy to clipboard'}>
                        <IconButton
                          size={'small'}
                          style={{marginRight: 8, top: -4, float: 'right'}}
                          color={'primary'}
                        >
                          <FileCopyIcon onClick={() => copyToClipboard(row.tagId)} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Collapse>
    </>
  );
}
