import React, {useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import {makeStyles, useTheme} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RealtimeTable from './RealtimeTable';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import ReactGA from 'react-ga4';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import useRealtimeData from '../../hooks/useRealtimeData';
import useStore from '../../store/store';
import {TimezonePicker} from './TimezonePicker';

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    zIndex: 1,
  },
  pulse: {
    color: theme.palette.graphColors.revenue,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: `1px solid ${theme.palette.graphColors.revenue}`,
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4)',
      opacity: 0,
    },
  },
  customTooltip: {
    maxWidth: 'none',
    background: theme.palette.background.paper,
    boxShadow: `0 0 60px rgba(0,0,0,.2)`,
  },
  drawerPaper: {
    width: '100%',
  },
}));

const Realtime = React.memo(({isMobile}) => {
  const classes = useStyles();
  const theme = useTheme();
  const {realtimeData} = useRealtimeData();
  const [dialogOpen, setDialogOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileViewAnchor, setMobileViewAnchor] = React.useState(null);
  const [sort, setSort] = useState('Revenue');
  const timezone = useStore(state => state.timezone);

  const changeSort = key => {
    setMobileViewAnchor(null);
    setSort(key);
  };

  return (
    <>
      <Drawer
        anchor={'right'}
        classes={{
          paper: isMobile ? classes.drawerPaper : undefined,
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(!dialogOpen)}
        fullScreen={fullScreen}
        maxWidth={'lg'}
      >
        <>
          {!realtimeData && (
            <Typography variant={'subtitle'} color={'textSecondary'}>
              Loading Todays statistics
            </Typography>
          )}
          {realtimeData && realtimeData?.today && (
            <React.Fragment>
              <Paper elevation={0} style={{padding: isMobile ? 20 : 40}}>
                <Typography variant={'h5'}>Today</Typography>
                <Tooltip title={`Close (ESC)`}>
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                    }}
                    onClick={() => {
                      setDialogOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>

                {isMobile && (
                  <Grid container style={{marginBottom: 20}} alignItems="center">
                    <Grid item xs={12}>
                      <>
                        <Button
                          className={classes.button}
                          style={{marginTop: 16}}
                          variant={'outlined'}
                          fullWidth
                          endIcon={<ExpandMoreIcon />}
                          onClick={e => setMobileViewAnchor(e.currentTarget)}
                        >
                          {sort}
                        </Button>
                        <Menu
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          classes={{paper: classes.drawerPaper}}
                          anchorEl={mobileViewAnchor}
                          keepMounted
                          open={Boolean(mobileViewAnchor)}
                          onClose={() => setMobileViewAnchor(null)}
                        >
                          <MenuItem onClick={() => changeSort('Revenue')}>Revenue</MenuItem>
                          <MenuItem onClick={() => changeSort('Impressions')}>Impresions</MenuItem>
                          <MenuItem onClick={() => changeSort('eCPM')}>eCPM</MenuItem>
                          <MenuItem onClick={() => changeSort('Viewability')}>Viewability</MenuItem>
                          <MenuItem onClick={() => changeSort('CTR')}>CTR</MenuItem>
                        </Menu>
                      </>
                    </Grid>
                  </Grid>
                )}

                <TimezonePicker isMobile={true} />

                <TableContainer>
                  <Table className={classes.table} size={'small'} component={Paper}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Hour</TableCell>
                        {(!isMobile || sort === 'Revenue') && (
                          <TableCell
                            align="center"
                            style={{borderBottom: `3px solid ${theme.palette.graphColors.revenue}`}}
                          >
                            Revenue
                          </TableCell>
                        )}
                        {(!isMobile || sort === 'Impressions') && (
                          <TableCell
                            align="center"
                            style={{
                              borderBottom: `3px solid ${theme.palette.graphColors.themeDarkGray}`,
                            }}
                          >
                            Impressions
                          </TableCell>
                        )}
                        {(!isMobile || sort === 'eCPM') && (
                          <TableCell
                            align="center"
                            style={{borderBottom: `3px solid ${theme.palette.graphColors.eCPM}`}}
                          >
                            eCPM
                          </TableCell>
                        )}
                        {(!isMobile || sort === 'Viewability') && (
                          <TableCell
                            align="center"
                            style={{
                              borderBottom: `3px dashed ${theme.palette.graphColors.viewability}`,
                            }}
                          >
                            Viewability
                          </TableCell>
                        )}
                        {(!isMobile || sort === 'CTR') && (
                          <TableCell
                            align="center"
                            style={{borderBottom: `3px dashed ${theme.palette.graphColors.CTR}`}}
                          >
                            CTR
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {realtimeData.today.map(row => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{row.dateRange}</TableCell>
                          {(!isMobile || sort === 'Revenue') && (
                            <TableCell align="center">{row.rev.toFixed(2)}€</TableCell>
                          )}
                          {(!isMobile || sort === 'Impressions') && (
                            <TableCell align="center">{row.imp}</TableCell>
                          )}
                          {(!isMobile || sort === 'eCPM') && (
                            <TableCell align="center">
                              {row.imp <= 0 ? 0 : ((row.rev / row.imp) * 1000).toFixed(2)}€
                            </TableCell>
                          )}
                          {(!isMobile || sort === 'Viewability') && (
                            <TableCell align="center">
                              {!row.viewability ? 0 : row.viewability.toFixed()}%
                            </TableCell>
                          )}
                          {(!isMobile || sort === 'CTR') && (
                            <TableCell align="center">
                              {!row.ctr ? 0 : row.ctr.toFixed(2)}%
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell align="center" style={{borderBottom: 'none'}}>
                          <Typography variant={'h6'}>{timezone}</Typography>
                        </TableCell>
                        {(!isMobile || sort === 'Revenue') && (
                          <TableCell align="center" style={{borderBottom: 'none'}}>
                            <Typography variant="body" style={{fontWeight: 600}}>
                              {realtimeData.today
                                .reduce((rev, hour) => rev + hour.rev, 0)
                                .toFixed(2)}
                              €
                            </Typography>
                          </TableCell>
                        )}
                        {(!isMobile || sort === 'Impressions') && (
                          <TableCell align="center" style={{borderBottom: 'none'}}>
                            <Typography variant="body" style={{fontWeight: 600}}>
                              {realtimeData.today.reduce((imp, hour) => imp + hour.imp, 0)}
                            </Typography>
                          </TableCell>
                        )}
                        {(!isMobile || sort === 'eCPM') && (
                          <TableCell align="center" style={{borderBottom: 'none'}}>
                            <Typography variant="body" style={{fontWeight: 600}}>
                              {(
                                (realtimeData.today
                                  .reduce((rev, hour) => rev + hour.rev, 0)
                                  .toFixed(2) /
                                  realtimeData.today.reduce((imp, hour) => imp + hour.imp, 0)) *
                                1000
                              ).toFixed(2)}
                              €
                            </Typography>
                          </TableCell>
                        )}
                        {(!isMobile || sort === 'Viewability') && (
                          <TableCell align="center" style={{borderBottom: 'none'}}>
                            <Typography variant="body" style={{fontWeight: 600}}>
                              {(
                                realtimeData.today.reduce(
                                  (viewability, hour) => viewability + hour.viewability,
                                  0
                                ) / realtimeData.today.length
                              ).toFixed(0)}
                              %
                            </Typography>
                          </TableCell>
                        )}
                        {(!isMobile || sort === 'CTR') && (
                          <TableCell align="center" style={{borderBottom: 'none'}}>
                            <Typography variant="body" style={{fontWeight: 600}}>
                              {(
                                realtimeData.today.reduce((ctr, hour) => ctr + hour.ctr, 0) /
                                realtimeData.today.length
                              ).toFixed(2)}
                              %
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              <RealtimeTable isMobile={isMobile} realtimeData={realtimeData} />
            </React.Fragment>
          )}
        </>
      </Drawer>
      {!realtimeData && !isMobile && (
        <>
          <IconButton size="small" color={'primary'}>
            <WhatshotIcon color={!realtimeData?.error ? 'secondary' : 'error'} />
            <CircularProgress className={classes.progress} color={'secondary'} />
          </IconButton>
        </>
      )}
      {isMobile && !realtimeData && (
        <Skeleton width={'100%'} height={56} style={{top: '-16px', position: 'relative'}} />
      )}
      {isMobile && realtimeData && !realtimeData?.error && (
        <>
          <Button
            style={{marginBottom: 18}}
            fullWidth
            variant={'contained'}
            color={'secondary'}
            onClick={() => {
              setDialogOpen(true);
              ReactGA.event({
                category: 'View Realtime',
                action: 'click',
              });
            }}
          >
            Today{' '}
            {!realtimeData?.error && realtimeData
              ? `${realtimeData?.today?.reduce((rev, hour) => rev + hour.rev, 0).toFixed(2)}€`
              : 'Loading'}
          </Button>
        </>
      )}
    </>
  );
});

export default React.memo(Realtime);
