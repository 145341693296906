import create from 'zustand';
import {persist} from 'zustand/middleware';

const usePersistentStore = create(
  persist(
    set => ({
      // Control visible metrics in AccountPropertiesSelection
      enabledMetrics: {
        viewability: true,
        requests: true,
        revenue: true,
        ecpm: true,
      },

      googleAuth: false,
      preferredTimezone: null,

      // Control default tab in Home page (Hourly, Daily, Prices, etc)
      defaultTab: 0,

      changeEnabledMetric: (metric, value) =>
        set(state => ({
          enabledMetrics: {
            ...state.enabledMetrics,
            [metric]: value,
          },
        })),

      setDefaultTab: tab =>
        set(() => ({
          defaultTab: tab,
        })),

      // Global state change function, two params - first is key of state, second is data
      setState: (key, data) => {
        set(() => ({
          [key]: data,
        }));
      },
    }),
    {}
  ),
  {
    name: 'client-ui-persistent-storage-v8',
  }
);

export default usePersistentStore;
