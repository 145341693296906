import {useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import useStore from '../../store/store';
import breakpoints from './breakpoints';
import lscache from 'lscache';
import {useLocation} from 'react-router-dom';

// Component itself renders nothing, but it's used to control the state of the popups

export function PopupsLogic() {
  const setState = useStore(state => state.setState);
  const auth = useStore(state => state.auth);
  const isMobile = useMediaQuery({query: breakpoints.mobile});
  const location = useLocation();

  useEffect(() => {
    // Sync global state with local storage
    // For when user refreshes
    if (auth) {
      lscache.set('auth', auth);
    }
  }, [auth]);

  useEffect(() => {
    // Remove any existing popup
    setState('visiblePopup', null);

    // Stop logic if user is: not logged in OR user logged in via WB OR user is on mobile OR user is demo account or menu structure is not received
    if (!auth || auth.wb_auth || auth.username === 'demo' || auth.menu.error) return;

    // If user has not accepted privacy policy and has no custom T&C, show the privacy policy popup
    if (auth.has_custom_tos === 0 && auth.standard_tos_accepted === 0) {
      return setState('visiblePopup', 'policy');
    }

    // If onboarding enabled and user has not completed it and user is not using mobile, start onboarding
    if (
      auth.onboarding_enabled === 1 &&
      !auth.onboarding_completed &&
      !isMobile &&
      //!location?.pathname.includes('knowledge-base') &&
      !location?.pathname.includes('prebid-panel')
    ) {
      return setState('visiblePopup', 'onboarding');
    }

    // if (auth.ads_txt_popup === 1) {
    //   return setState('visiblePopup', 'adstxt');
    // }
  }, [auth, isMobile, setState, location]);

  return null;
}
