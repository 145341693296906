import React, {useCallback, useEffect, useState} from 'react';
import {Grid, Typography, TextField, Button, makeStyles, Tooltip} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import adManagerLogo from '../../../components/img/ad-manager-logo.svg';
import {Skeleton} from '@material-ui/lab';
import {APICall} from '../../../api/api';
import useStore from '../../../store/store';
import useRefreshMenu from '../../../hooks/useRefreshMenu';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '10px 15px',
    margin: 7,
    border: '1px solid #7979793b',
    marginBottom: 15,
    borderRadius: 4,
    minHeight: 58,
    '&:hover': {
      background: theme.palette.graphColors.sorted,
    },
  },
  adManagerLogo: {
    height: 22,
    top: 4,
    position: 'relative',
    marginRight: 15,
  },
  headingStyle: {
    fontWeight: 600,
    marginBottom: 8,
    display: 'block',
  },
}));

export function NewDomainDialog({publisher, loading, setLoading, sites, importDomain}) {
  const classes = useStyles();
  const auth = useStore(state => state.auth);
  const [url, setUrl] = useState('');
  const [cmpStatus, setCmpStatus] = useState(null);
  const [adsTxtStatus, setAdsTxtStatus] = useState(null);
  const [urlConfirmed, setUrlConfirmed] = useState(false);
  const [siteCreationError, setSiteCreationError] = useState(null);
  const {refreshMenu} = useRefreshMenu();

  const fetchAdsTxtStatus = useCallback(
    async url => {
      let adsTXTdata;
      setAdsTxtStatus(null);
      try {
        adsTXTdata = await APICall({
          endpoint: 'checkAdsTxt',
          params: `${auth.accountId}/${url}/null`,
          options: {
            method: 'get',
            headers: {
              Authorization: `Bearer ${auth.token}`,
              'Cache-Control': 'max-age=0, no-cache',
              'Content-Type': 'application/json',
            },
            importance: 'low',
          },
        });
      } catch (e) {
        adsTXTdata = {success: false, error: e.message};
      }

      setAdsTxtStatus(adsTXTdata);
    },
    [auth.accountId, auth.token]
  );

  const getCurrentCMPstatus = useCallback(
    async url => {
      // Check current CMP status for domain
      setCmpStatus(null);
      try {
        let currentCMPstatus = await APICall({
          endpoint: 'checkCMPbyUrl',
          params: `${url}`,
          options: {
            method: 'get',
            headers: {
              Authorization: `Bearer ${auth.token}`,
              'Cache-Control': 'max-age=0, no-cache',
              'Content-Type': 'application/json',
            },
          },
        });

        let results =
          currentCMPstatus.success === false || !currentCMPstatus.cmp
            ? null
            : JSON.parse(currentCMPstatus.cmp);

        setCmpStatus({
          success: results ? true : false,
          data: results,
          isSetupadCMP: results ? results.cmpId === 379 : false,
        });
      } catch (e) {
        setCmpStatus({
          success: false,
        });
      }
    },
    [auth.token]
  );

  useEffect(() => {
    if (urlConfirmed) {
      // Check ads.txt status
      fetchAdsTxtStatus(url);
      // Check CMP status
      getCurrentCMPstatus(url);
      // Set site creation error to null
      setSiteCreationError(null);
    }
  }, [urlConfirmed, fetchAdsTxtStatus, getCurrentCMPstatus, url]);

  const createSiteAndDomain = async () => {
    setLoading(true);
    setSiteCreationError(null);
    try {
      let createSiteAndDomain = await APICall({
        endpoint: 'createSiteAndDomain',
        options: {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Cache-Control': 'max-age=0, no-cache',
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            publisherId: publisher.publisherId,
            url: url,
          }),
        },
      });

      if (!createSiteAndDomain.success) {
        throw new Error(createSiteAndDomain.error);
      } else {
        // Refresh menu
        refreshMenu();
      }
    } catch (e) {
      setSiteCreationError(e.message);
      setUrlConfirmed(false);
    }
    setLoading(false);
  };

  // Check if such url has already been created in Ad Manager
  const siteInGam = sites?.find(site => site.url === url);

  return (
    <Grid
      container
      spacing={1}
      className={classes.wrapper}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography className={classes.headingStyle}>
          <img src={adManagerLogo} className={classes.adManagerLogo} alt="Google Ad Manager" />
          Create site in Ad Manager and import it to Setupad
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          fullWidth
          label={siteInGam ? `Site is already created in your GAM!` : `Site URL`}
          value={url}
          autoFocus
          onChange={({target}) => setUrl(target.value)}
          disabled={loading || urlConfirmed}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          title={!url.includes('.') || url.length < 4 ? 'Please enter domain URL first' : ''}
        >
          <span>
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth
              disabled={loading || !url.includes('.') || url.length < 4}
              onClick={() => (siteInGam ? importDomain(siteInGam) : setUrlConfirmed(!urlConfirmed))}
            >
              {urlConfirmed ? 'Reset' : siteInGam ? 'Import to Setupad' : 'Confirm'}
            </Button>
          </span>
        </Tooltip>
      </Grid>

      {urlConfirmed && (
        <>
          <Grid item xs={12}>
            <Typography className={classes.headingStyle} variant={'caption'}>
              Ads.txt status
            </Typography>
            {!adsTxtStatus && (
              <Skeleton variant="rect" width={'100%'} height={50} style={{borderRadius: 4}} />
            )}
            {adsTxtStatus?.success && (
              <>
                {!adsTxtStatus.currentAdsTXT.success && (
                  <Alert severity={'error'}>Ads.txt not found on {url}/ads.txt</Alert>
                )}
                {adsTxtStatus.currentAdsTXT.success && (
                  <Alert severity={'success'}>
                    {url}/ads.txt - {adsTxtStatus.currentAdsTXT.rows.length}{' '}
                    {adsTxtStatus.currentAdsTXT?.rows.length === 1 ? 'line' : 'lines'} found
                  </Alert>
                )}
              </>
            )}
            {adsTxtStatus?.error && <Alert severity={'error'}>Failed to load {url}/ads.txt</Alert>}
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.headingStyle} variant={'caption'}>
              Consent Management Platform (CMP) status
            </Typography>
            {!cmpStatus && (
              <Skeleton variant="rect" width={'100%'} height={50} style={{borderRadius: 4}} />
            )}
            {cmpStatus && !cmpStatus.success && <Alert severity={'error'}>CMP not detected</Alert>}
            {cmpStatus && cmpStatus.success && cmpStatus.isSetupadCMP && (
              <Alert className={classes.alert} severity={'success'}>
                Setupad CMP detected
              </Alert>
            )}
            {cmpStatus && cmpStatus.success && !cmpStatus.isSetupadCMP && (
              <Alert className={classes.alert} severity={'info'}>
                CMP detected
              </Alert>
            )}
          </Grid>

          <Grid item xs={12}>
            <Tooltip
              arrow
              title={!url.includes('.') || url.length < 4 ? 'Please enter domain URL first' : ''}
            >
              <span>
                <Button
                  variant={'text'}
                  color={'primary'}
                  fullWidth
                  disabled={loading || !adsTxtStatus}
                  onClick={() => createSiteAndDomain()}
                >
                  Create site and domain
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </>
      )}

      {siteCreationError && (
        <Grid item xs={12}>
          <Alert severity={'error'}>{siteCreationError}</Alert>
        </Grid>
      )}
    </Grid>
  );
}
