import React, {useEffect, useState} from 'react';
import {
  Button,
  Typography,
  makeStyles,
  Container,
  Grid,
  Backdrop,
  IconButton,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {Close, OpenInBrowser} from '@material-ui/icons';
import {APICall} from '../../api/api';
import useStore from '../../store/store';
import LinkIcon from '@material-ui/icons/Link';
import copyToClipboard from 'copy-to-clipboard';
import ReactGA from 'react-ga4';

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    borderRadius: '4px',
    border: '1px solid #7979793b',
    marginTop: 20,
    background: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.name === 'darkTheme' ? '#fff' : 'inherit',
    backgroundColor:
      theme.name === 'darkTheme' ? 'rgba(13, 53, 69, 0.9)' : 'rgb(255 255 255 / 95%)',
    backgroundSize: `200% 200%`,
    animation: '$scaleUpDown 30s ease infinite',
  },
}));

export const ShareDialog = ({activeMetrics, publisherStats, calculated}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [shareResult, setShareResult] = useState(null);
  const [nameDisabled, setNameDisabled] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const selectionLabel = useStore(state => state.selectionLabel);
  const period = useStore(state => state.period);
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);

  // Handle closing of the modal
  useEffect(() => {
    setError(null);
    setNameDisabled(null);
    setState('disableTypingForAccountSelector', open ? true : false);
  }, [open]);

  const shareGraph = async () => {
    try {
      setLoading(true);
      let response = await APICall({
        endpoint: 'shareGraph',
        options: {
          method: 'POST',
          body: JSON.stringify({
            graphData: {
              activeMetrics,
              publisherStats,
              period,
              calculated,
              name: nameDisabled ? undefined : selectionLabel,
            },
          }),
          headers: {
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        },
      });
      setShareResult(response);
      setLoading(null);

      // Preload OG image
      const img = new Image();
      img.src = `https://yield.setupad.com/api/screenshot/get?id=${response.identifier}`;
    } catch (e) {
      setLoading(null);
      setError(e.message);
    }
  };

  return (
    <>
      <Button
        // style={{marginLeft: 10}}
        className={classes.button}
        size={'small'}
        variant={'outlined'}
        color={'primary'}
        endIcon={<LinkIcon />}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
          ReactGA.event({
            category: 'Shared graph',
            action: 'click',
            label: auth?.username,
          });
        }}
      >
        Share this graph
      </Button>

      {open && (
        <Backdrop className={classes.backdrop} open={open}>
          <Container maxWidth={'sm'}>
            <IconButton
              style={{
                float: 'right',
                border: '1px solid rgba(255,255,255,0.3)',
              }}
              onClick={() => {
                setOpen(false);
              }}
              color={'inherit'}
            >
              <Close />
            </IconButton>
            <Grid
              container
              spacing={4}
              direction="row"
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid item xs={12}>
                <Typography
                  variant={'h4'}
                  align={'center'}
                  gutterBottom
                  style={{fontWeight: 'bold'}}
                >
                  Share revenue results
                </Typography>
                <Typography variant={'body2'} align={'center'} gutterBottom>
                  Generate a link to share your results. No personal data will be shared, only the
                  currently visible view.
                </Typography>
              </Grid>

              <img
                height={'200px'}
                src={
                  'https://setupad.com/wp-content/themes/setupad/assets/img/landing/setupad-prebid-adsense-main.svg'
                }
              />

              <Grid item xs={12} style={{textAlign: 'center'}}></Grid>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'center'}}>
              {!shareResult && (
                <>
                  <TextField
                    fullWidth
                    variant={'filled'}
                    label="Edit name"
                    value={selectionLabel}
                    autoFocus
                    disabled={nameDisabled || loading}
                    onChange={e => {
                      setState('selectionLabel', e.target.value);
                    }}
                  />

                  <div>
                    <FormControlLabel
                      style={{
                        paddingTop: 20,
                      }}
                      control={
                        <Checkbox
                          onChange={e => setNameDisabled(e.target.checked)}
                          name="first"
                          checked={nameDisabled}
                          color={'primary'}
                        />
                      }
                      label={<Typography>Don't include name</Typography>}
                    />
                  </div>
                  <Button
                    className={classes.button}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                      shareGraph();
                    }}
                    endIcon={loading ? <CircularProgress size={16} /> : <LinkIcon />}
                    disabled={loading}
                  >
                    Share with link
                  </Button>
                </>
              )}
              {shareResult?.success && (
                <>
                  <TextField
                    fullWidth
                    variant={'filled'}
                    label="Share link"
                    value={`https://yield.setupad.com/results/${shareResult.identifier}`}
                    onClick={() => {
                      copyToClipboard(
                        `https://yield.setupad.com/results/${shareResult.identifier}`
                      );
                    }}
                  />
                  <Button
                    className={classes.button}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                      copyToClipboard(
                        `https://yield.setupad.com/results/${shareResult.identifier}`
                      );
                    }}
                    endIcon={<LinkIcon />}
                    disabled={!shareResult?.success}
                  >
                    Copy link
                  </Button>
                  <Button
                    className={classes.button}
                    variant={'contained'}
                    color={'primary'}
                    target={'_blank'}
                    href={`https://yield.setupad.com/results/${shareResult.identifier}`}
                    endIcon={<OpenInBrowser />}
                    disabled={!shareResult?.success}
                  >
                    Open link
                  </Button>
                </>
              )}
              <Button
                className={classes.button}
                style={{marginLeft: 15}}
                color={'primary'}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>

              {error && (
                <Grid item xs={12} style={{textAlign: 'center', marginTop: 20}}>
                  <Typography color={'error'}>{error}</Typography>
                </Grid>
              )}
            </Grid>
          </Container>
        </Backdrop>
      )}
    </>
  );
};
