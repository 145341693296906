// MCM status mapped to info from this page:
// https://developers.google.com/ad-manager/api/reference/v202108/CompanyService.AccountStatus
import React from 'react';

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import TimerIcon from '@material-ui/icons/Timer';
import {Typography, Tooltip, makeStyles} from '@material-ui/core';

const iconStyle = {
  top: 2,
  position: 'relative',
};

const iconStyleSiteStatuses = {
  top: 3,
  marginLeft: 4,
  position: 'relative',
  fontSize: 16,
};

const siteStatuses = {
  DRAFT: {
    severity: 'info',
    icon: <TimerIcon color={'primary'} style={iconStyleSiteStatuses} fontSize={'inherit'} />,
  },
  UNCHECKED: {
    severity: 'info',
    icon: <TimerIcon color={'primary'} style={iconStyleSiteStatuses} fontSize={'inherit'} />,
    text: `- The website is going through the verification process. This may take up to 24 hours.`,
  },
  APPROVED: {
    severity: 'success',
    icon: <CheckIcon color={'secondary'} style={iconStyleSiteStatuses} fontSize={'inherit'} />,
  },
  DISAPPROVED: {
    severity: 'error',
    icon: <ReportProblemIcon color={'error'} style={iconStyleSiteStatuses} fontSize={'inherit'} />,
  },
  REQUIRES_REVIEW: {
    severity: 'error',
    icon: <TimerIcon color={'primary'} style={iconStyleSiteStatuses} fontSize={'inherit'} />,
  },
  UNKNOWN: {
    severity: 'error',
  },
};

// https://developers.google.com/ad-manager/api/reference/v202308/CompanyService.AccountStatus
const mcmStatusDecoder = {
  UNKNOWN: {
    severity: 'error',
    color: 'error',
    text: 'Uknown GAM API error',
    icon: <CheckCircleIcon color={'primary'} style={iconStyle} fontSize={'inherit'} />,
  },
  INVITED: {
    severity: 'info',
    color: 'primary',
    text: 'The publisher has not acted on the invite from the Setupad.',
    icon: <TimerIcon color={'primary'} style={iconStyle} fontSize={'inherit'} />,
  },
  DECLINED: {
    severity: 'error',
    color: 'error',
    text: '	The publisher has declined the invite.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  PENDING_GOOGLE_APPROVAL: {
    severity: 'info',
    color: 'primary',
    text: `The publisher has accepted the invite, and is awaiting Google's policy compliance verifications.`,
    icon: <TimerIcon color={'primary'} style={iconStyle} fontSize={'inherit'} />,
  },
  APPROVED: {
    severity: 'success',
    color: 'secondary',
    text:
      'The publisher accepted the invite, and Google found it to be compliant with its policies, i.e. no policy violations were found, and the publisher can be served ads.',
    icon: <CheckCircleIcon color={'secondary'} style={iconStyle} fontSize={'inherit'} />,
  },
  DISAPPROVED_POLICY_VIOLATION: {
    severity: 'error',
    color: 'error',
    text:
      'The publisher accepted the invite, but was disapproved by Google for violating its policies.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  DISAPPROVED_DUPLICATE_ACCOUNT: {
    severity: 'error',
    color: 'error',
    text:
      'The publisher accepted the invite, but was disapproved by Google for being a duplicate of another account.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  DISAPPROVED_INELIGIBLE: {
    severity: 'error',
    color: 'error',
    text: 'The publisher accepted the invite, but was disapproved as ineligible by Google.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  EXPIRED: {
    severity: 'warning',
    color: 'error',
    text:
      'The invite was sent to the publisher more than 90 days ago, due to which it has been deactivated.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  INACTIVE: {
    severity: 'error',
    color: 'error',
    text:
      'Either the publisher disconnected from the Setupad network, or the Setupad network withdrew the invite.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  DEACTIVATED_BY_AD_MANAGER: {
    severity: 'error',
    color: 'error',
    text:
      'The association between the Setupad and publishers was deactivated by Google Ad Manager.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  CLOSED_POLICY_VIOLATION: {
    severity: 'error',
    color: 'error',
    text: 'Disapproved by Google for violating its policies.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  CLOSED_INVALID_ACTIVITY: {
    severity: 'error',
    color: 'error',
    text: 'Accepted the invite, but was disapproved by Google for invalid activity.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
  CLOSED_BY_PUBLISHER: {
    severity: 'error',
    color: 'error',
    text: 'Publisher closed their own account.',
    icon: <ReportProblemIcon color={'error'} style={iconStyle} fontSize={'inherit'} />,
  },
};

const getColorSiteStatus = status => {
  switch (status) {
    case 'APPROVED':
      return 'success';
    case 'DRAFT':
      return 'info';
    case 'DISAPPROVED':
      return 'error';
    default:
      return 'info';
  }
};

const useStyles = makeStyles(theme => ({
  siteDetail: {
    fontSize: 12,
    borderRadius: 4,
    background: theme.palette.graphColors.themeLightGrayOpaque,
    display: 'inline-block',
    padding: '3px 6px',
    marginRight: 5,
    position: 'relative',
  },
  errorIcon: {
    top: 0,
    color: 'red',
    position: 'absolute',
    fontSize: 26,
  },
}));

const SiteStatus = ({site}) => {
  const classes = useStyles();
  return (
    <>
      <Tooltip
        arrow
        title={
          site.disapprovalReasons
            ? `${site.disapprovalReasons.map(reason => reason.details?.toLowerCase()).join(',')}`
            : 'Site status from Google Ad Manager '
        }
      >
        <div style={{position: 'relative'}}>
          <Typography className={classes.siteDetail}>
            {site.approvalStatus.replace('_', ' ')} {siteStatuses[site.approvalStatus]?.text}
          </Typography>
          {siteStatuses[site.approvalStatus]?.severity === 'error' && (
            <ReportProblemIcon className={classes.errorIcon} />
          )}
        </div>
      </Tooltip>
    </>
  );
};

export {mcmStatusDecoder, siteStatuses, getColorSiteStatus, SiteStatus};
