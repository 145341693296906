import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import {APICall} from '../api/api';
import Alert from '@material-ui/lab/Alert';
import FadeIn from 'react-fade-in';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ImageIcon from '@material-ui/icons/Image';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import Link from '@material-ui/core/Link';
import useStore from '../store/store';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999999999,
    color: '#fff',
  },
  wrapper: {
    flexGrow: 1,
    // background: '#efefef',
    padding: 50,
    borderRadius: '4px',
    border: '1px solid #7979793b',
  },
  paper: {
    padding: theme.spacing(3, 2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  viewWrapper: {
    padding: 50,
    //  border: '1px solid #7979793b',
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  videoResponsive: {
    marginTop: 20,
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  },
  videoResponsiveIframe: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Payments = props => {
  const classes = useStyles();

  const [data, setData] = React.useState(null);
  const [expanded, setExpanded] = React.useState(null);
  const setState = useStore(state => state.setState);

  useEffect(() => {
    async function loadData() {
      let reportedAds = await APICall({
        endpoint: 'extensionReports',
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${props.login.token}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      setData(reportedAds);
    }

    loadData();
  }, []);

  const getReasonLabel = reason => {
    let label;

    if (reason === 1) {
      label = 'Low Quality';
    }

    if (reason === 2) {
      label = 'Fake products/services';
    }

    if (reason === 3) {
      label = 'Misleading';
    }

    if (reason === 4) {
      label = 'Unwanted brands';
    }

    return label;
  };
  return (
    <Container component="main" maxWidth="xl" className={classes.viewWrapper}>
      {!data && (
        <div style={{width: '100%', textAlign: 'center'}}>
          <CircularProgress color="primary" />
        </div>
      )}

      {data && !data.success && (
        <Alert severity="error">Failed to load Reported Ads, please try again.</Alert>
      )}

      {data && data.success && data.reports.length === 0 && (
        <Grid container className={classes.wrapper} spacing={3}>
          <Grid item xs={12}>
            <Typography>
              <strong>Install Setupad extension </strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>
              Setupad extension can be helpful to ensure Setupad tags are running on their
              respective placements and monitor their performance. With this extension, you can also
              report unwanted (e.g., competitor's) ads.
            </Typography>
            <Typography>
              1. To use the extension, you need to use{' '}
              <Link target={'_blank'} href="https://www.google.com/chrome/">
                Google Chrome web browser.
              </Link>
            </Typography>
            <Typography>
              2. To install the extension, go to the{' '}
              <Link
                target={'_blank'}
                href="https://chrome.google.com/webstore/detail/setupad/fjcgkkkiimmoijkamkmpffokeninmhhh"
                onClick={() => setState('completedStep', 4)}
              >
                Google Chrome Web Store.
              </Link>
            </Typography>
            <Typography>
              3. Download the extension and sign in using your Setupad login credentials.
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.videoResponsive}>
              <iframe
                className={classes.videoResponsiveIframe}
                src="https://www.youtube.com/embed/P6xp_6Xz8fQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Grid>
        </Grid>
      )}

      {data && data.success && data.reports.length > 0 && (
        <FadeIn>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{fontWeight: 'bold'}}>Report ID</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>Status</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>Screenshot</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>Reason</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>SSP</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>Ad Placement</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>Click URL</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>Target URL</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.reports.map(row => {
                  return (
                    <>
                      <TableRow
                        key={row.id}
                        // onClick={() =>
                        //   expanded === row.id ? setExpanded(null) : setExpanded(row.id)
                        // }
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Tooltip title={row.closed === 1 ? `Blocked` : `Will be blocked soon`}>
                            {row.closed === 1 ? (
                              <CheckCircleIcon color={'secondary'} />
                            ) : (
                              <GpsNotFixedIcon color={'error'} />
                            )}
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Tooltip
                            title={
                              <>
                                <img
                                  style={{width: '50vw'}}
                                  src={`https://dev.setupad.com/extension/${encodeURIComponent(
                                    row.bannerImage
                                  )}`}
                                />
                              </>
                            }
                          >
                            <IconButton>
                              <ImageIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>{' '}
                        <TableCell component="th" scope="row">
                          {row.reason ? getReasonLabel(parseInt(row.reason)) : `Other`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.winner}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {' '}
                          <Chip label={row.placement} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <IconButton href={row.clickUrl} target={'_blank'}>
                            <LinkIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Tooltip
                            title={
                              <>
                                <img
                                  style={{width: '20vw'}}
                                  src={`https://dev.setupad.com/extension/${encodeURIComponent(
                                    row.landingImage
                                  )}`}
                                />
                              </>
                            }
                          >
                            <IconButton href={row.resolvedUrl} target={'_blank'}>
                              <LinkIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {moment(row.time).format('YYYY-MM-DD')}
                        </TableCell>
                      </TableRow>
                      {/*<Backdrop open={expanded === row.id} className={classes.backdrop}>*/}
                      {/*<ReactJson theme="twilight" collapsed={1} src={JSON.parse(row.eventData)} />*/}
                      {/*</Backdrop>*/}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </FadeIn>
      )}
    </Container>
  );
};

export default React.memo(Payments);
