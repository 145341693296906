let selfServe = 'https://client.setupad.com/PrebidSelfServeTermsandConditions.pdf';
let wrapper = 'https://client.setupad.com/Terms-and-Conditions-agreement-v1-3-2022_05_24.pdf';
let adapter = 'https://client.setupad.com/AdapterTermsandConditions.pdf';

const getTermsAndConditionsFileUrl = accountType => {
  let url = wrapper;
  if (accountType >= 5 && accountType < 8) {
    url = selfServe;
  } else if (accountType === 0) {
    url = adapter;
  }

  return url;
};

export default getTermsAndConditionsFileUrl;
