import React, {useState, useEffect} from 'react';
import {useTheme, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 160,
    margin: '0 auto',
    lineHeight: 0,
    paddingBottom: 50,
    textAlign: 'center',
  },
  square: {
    position: 'relative',
    height: 54,
    width: 54,
    display: 'inline-block',
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    transition: 'border 100ms linear',
  },
  slantedSquare1: {
    position: 'absolute',
    height: 74,
    width: 74,
    display: 'inline-block',
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    //border: '1px solid red',
    transform: 'rotate(45deg)',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    top: 15,
    left: -37,
    transition: 'border 100ms linear',
  },
  slantedSquare2: {
    position: 'absolute',
    height: 74,
    width: 74,
    display: 'inline-block',
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    //border: '1px solid red',
    transform: 'rotate(-45deg)',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    top: 15,
    left: 15,
    transition: 'border 100ms linear',
  },
  circle: {
    position: 'absolute',
    borderRadius: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    zIndex: 2,
    transition: 'background-color 100ms linear',
  },
}));

const PlaceholderOverhaul = props => {
  const theme = useTheme();
  const classes = useStyles();

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const border = `1px solid ${theme.palette.primary.main}`;
  const invisibleBorder = `1px solid rgba(0,0,0,0)`;

  let squares = new Array(4).fill(undefined);
  let activatedBorders = new Array(24).fill(undefined).map(() => Math.random() > 0.9);

  let activatedCirclesByBorder = {
    1: [1, 2, 5],
    2: [2, 5, 3, 8, 10, 13],
    3: [4, 9, 3, 8, 10, 13],
    4: [1, 4, 9],
    5: [1, 3, 8, 10, 13],
    6: [2, 5, 4, 9],
    7: [2, 5, 6],
    8: [6, 7, 14],
    9: [3, 8, 10, 13, 7, 14],
    10: [2, 5, 3, 8, 10, 13],
    11: [2, 5, 7, 14],
    12: [6, 3, 8, 10, 13],
    13: [4, 9, 3, 8, 10, 13],
    14: [3, 8, 10, 13, 11, 16],
    15: [12, 11, 16],
    16: [4, 9, 12],
    17: [4, 9, 11, 16],
    18: [3, 8, 10, 13, 12],
    19: [3, 8, 10, 13, 7, 14],
    20: [7, 14, 15],
    21: [11, 16, 15],
    22: [3, 8, 10, 13, 11, 16],
    23: [3, 8, 10, 13, 15],
    24: [7, 14, 11, 16],
  };

  let activatedCircles = [];
  activatedBorders.map((borderActive, i) => {
    if (borderActive) {
      activatedCircles = [...activatedCircles, ...activatedCirclesByBorder[i + 1]];
    }
  });

  let bordersPainted = -7;
  let renderedCircles = [1, 2, 3, 4];
  let circlesPainted = 0;

  return (
    <div className={classes.wrapper}>
      {squares.map(i => {
        bordersPainted = bordersPainted + 6;
        return (
          <div
            className={classes.square}
            style={{
              borderTop: activatedBorders[bordersPainted + 1] ? border : invisibleBorder,
              borderRight: activatedBorders[bordersPainted + 2] ? border : invisibleBorder,
              borderBottom: activatedBorders[bordersPainted + 3] ? border : invisibleBorder,
              borderLeft: activatedBorders[bordersPainted + 4] ? border : invisibleBorder,
            }}
          >
            <div
              className={classes.slantedSquare1}
              style={{
                display: activatedBorders[bordersPainted + 5] ? 'block' : 'none',
              }}
            />
            <div
              className={classes.slantedSquare2}
              style={{
                display: activatedBorders[bordersPainted + 6] ? 'block' : 'none',
              }}
            />
            {renderedCircles.map(i => {
              let size = 10;
              let circleOffset = -6;
              circlesPainted = circlesPainted + 1;

              return (
                <div
                  className={classes.circle}
                  style={{
                    top: i === 1 || i === 2 ? circleOffset : undefined,
                    left: i === 1 || i === 4 ? circleOffset : undefined,
                    bottom: i === 3 || i === 4 ? circleOffset : undefined,
                    right: i === 2 || i === 3 ? circleOffset : undefined,

                    width: size,
                    height: size,

                    background: activatedCircles.includes(circlesPainted)
                      ? theme.palette.primary.main
                      : theme.palette.background.paper,
                  }}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default PlaceholderOverhaul;
