import React from 'react';
import {
  Button,
  ButtonBase,
  Backdrop,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ReactGA from 'react-ga4';
import {DateRangePicker} from 'materialui-daterange-picker';
import useStore from '../../store/store';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'inline-block',
    margin: theme.spacing(0.5),
    borderRadius: 4,
    background: 'none',
  },
  mobilePeriodPickerPaper: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '90px',
  },
  datePickerOutlined: {
    padding: '4px 16px',
  },
  datePickerRoot: {
    fontSize: '0.8125rem',
    lineHeight: 1.75,
    textTransform: 'uppercase',
  },
  datePickerRootActive: {
    background: theme.palette.primary.main,
    color: 'white',
    fontSize: '0.8125rem',
    lineHeight: 1.75,
    textTransform: 'uppercase',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export function PeriodPicker({isMobile}) {
  const classes = useStyles();

  const [rangePickerOpen, setRangePickerOpen] = React.useState(false);
  const [mobilePeriodPicker, setMobilePeriodPicker] = React.useState(null);

  const setState = useStore(state => state.setState);
  const period = useStore(state => state.period);
  const startingDay = useStore(state => state.startingDay);
  const calculated = useStore(state => state.calculated);

  const changePeriod = range => {
    ReactGA.event({
      category: 'period picker',
      action: 'click',
      label: range.label ? range.label : 'custom',
    });

    setRangePickerOpen(false);

    setState('period', {
      startDate: moment(range.startDate).format('YYYY-MM-DD'),
      endDate: moment(range.endDate).format('YYYY-MM-DD'),
      label: range.label,
    });

    setState('calculated', null);

    // Calculate and change comparison period as well
    let daysDifference = moment(range.endDate).diff(moment(range.startDate), 'days');

    // Check if we need to compare single day only
    daysDifference = daysDifference === 0 ? 1 : daysDifference + 1;

    setState('comparisonPeriod', {
      startDate: moment(range.startDate).subtract(daysDifference, 'days').format('YYYY-MM-DD'),
      endDate: moment(range.endDate).subtract(daysDifference, 'days').format('YYYY-MM-DD'),
    });
  };

  return (
    <>
      {isMobile && (
        <>
          <Button
            variant={'outlined'}
            fullWidth
            onClick={e => setMobilePeriodPicker(e.currentTarget)}
            endIcon={<CalendarTodayIcon />}
          >
            {period.label && isMobile
              ? period.label
              : period.label
              ? `Custom period`
              : `${period.startDate} - ${period.endDate}`}
          </Button>
          <Backdrop
            className={classes.backdrop}
            open={false}
            onClick={() => setMobilePeriodPicker(null)}
          >
            <Menu
              anchorEl={mobilePeriodPicker}
              keepMounted
              open={Boolean(mobilePeriodPicker)}
              onClose={() => setMobilePeriodPicker(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{paper: classes.mobilePeriodPickerPaper}}
            >
              {period.label !== 'Yesterday' && (
                <Tooltip
                  title={
                    parseInt(moment.tz('Europe/Vilnius').format('HH')) < 7
                      ? 'Yesterdays data will be available after 7AM EEST'
                      : ''
                  }
                >
                  <MenuItem
                    disabled={parseInt(moment.tz('Europe/Vilnius').format('HH')) < 7}
                    onClick={() => {
                      changePeriod({
                        label: 'Yesterday',
                        startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                        endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                      });
                      setMobilePeriodPicker(null);
                    }}
                  >
                    Yesterday{' '}
                    {parseInt(moment.tz('Europe/Vilnius').format('HH')) < 7
                      ? '(will be available after 7AM EEST)'
                      : ''}
                  </MenuItem>
                </Tooltip>
              )}
              {period.label !== 'Last 7 days' && (
                <MenuItem
                  onClick={() => {
                    changePeriod({
                      label: 'Last 7 days',
                      startDate: moment(startingDay).subtract(7, 'days').format('YYYY-MM-DD'),
                      endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
                    });
                    setMobilePeriodPicker(null);
                  }}
                >
                  Last 7 days
                </MenuItem>
              )}
              {period.label !== 'Last 30 days' && (
                <MenuItem
                  onClick={() => {
                    changePeriod({
                      label: 'Last 30 days',
                      startDate: moment(startingDay).subtract(30, 'days').format('YYYY-MM-DD'),
                      endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
                    });
                    setMobilePeriodPicker(null);
                  }}
                >
                  Last 30 days
                </MenuItem>
              )}
              {period.label !== 'This month' && (
                <MenuItem
                  onClick={() => {
                    changePeriod({
                      label: 'This month',
                      startDate: moment(startingDay).startOf('month').format('YYYY-MM-DD'),
                      endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
                    });
                    setMobilePeriodPicker(null);
                  }}
                >
                  This month
                </MenuItem>
              )}
              {period.label !== 'Last month' && (
                <MenuItem
                  onClick={() => {
                    changePeriod({
                      label: 'Last month',
                      startDate: moment()
                        .subtract(1, 'months')
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                      endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
                    });
                    setMobilePeriodPicker(null);
                  }}
                >
                  Last month
                </MenuItem>
              )}
              {period.label !== 'All history' && (
                <MenuItem
                  onClick={() => {
                    changePeriod({
                      label: 'All history',
                      startDate: moment().subtract(10, 'year'),
                      endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
                    });
                    setMobilePeriodPicker(null);
                  }}
                >
                  All history
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setRangePickerOpen(true);
                  setMobilePeriodPicker(null);
                }}
              >
                Custom period
              </MenuItem>
            </Menu>
          </Backdrop>
        </>
      )}

      {!isMobile && (
        <>
          <Tooltip
            title={
              parseInt(moment.tz('Europe/Vilnius').format('HH')) < 7
                ? 'Yesterdays data will be available after 7AM EEST'
                : ''
            }
          >
            <div style={{display: 'inline-block'}}>
              <Button
                disabled={parseInt(moment.tz('Europe/Vilnius').format('HH')) < 7}
                className={classes.button}
                variant={period.label === 'Yesterday' ? 'contained' : 'outlined'}
                color={period.label === 'Yesterday' ? 'primary' : ``}
                size="small"
                onClick={() => {
                  changePeriod({
                    label: 'Yesterday',
                    startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                    endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                  });
                }}
              >
                Yesterday
              </Button>
            </div>
          </Tooltip>

          <Button
            //disabled={!calculated}
            className={classes.button}
            variant={period.label === 'Last 30 days' ? 'contained' : 'outlined'}
            color={period.label === 'Last 30 days' ? 'primary' : ``}
            size="small"
            onClick={() => {
              changePeriod({
                label: 'Last 30 days',
                startDate: moment(startingDay).subtract(30, 'days').format('YYYY-MM-DD'),
                endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
              });
            }}
          >
            Last 30 days
          </Button>

          <Button
            //disabled={!calculated}
            className={classes.button}
            variant={period.label === 'This month' ? 'contained' : 'outlined'}
            color={period.label === 'This month' ? 'primary' : ``}
            size="small"
            onClick={() => {
              changePeriod({
                label: 'This month',
                startDate: moment(startingDay).startOf('month').format('YYYY-MM-DD'),
                endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
              });
            }}
          >
            This month
          </Button>
          <Button
            //disabled={!calculated}
            className={classes.button}
            variant={period.label === 'Last month' ? 'contained' : 'outlined'}
            color={period.label === 'Last month' ? 'primary' : ``}
            size="small"
            onClick={() => {
              changePeriod({
                label: 'Last month',
                startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
              });
            }}
          >
            Last month
          </Button>
          <Button
            //disabled={!calculated}
            className={classes.button}
            variant={period.label === 'All history' ? 'contained' : 'outlined'}
            color={period.label === 'All history' ? 'primary' : ``}
            size="small"
            onClick={() => {
              changePeriod({
                label: 'All history',
                startDate: moment(startingDay).subtract(10, 'year'),
                endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
              });
            }}
          >
            All history
          </Button>
        </>
      )}

      <ButtonBase>
        <Select
          classes={{
            root: !period.label ? classes.datePickerRootActive : classes.datePickerRoot,
            outlined: classes.datePickerOutlined,
          }}
          style={{left: isMobile ? 0 : 8, display: isMobile ? 'none' : 'flex'}}
          variant={isMobile ? 'filled' : 'outlined'}
          IconComponent={isMobile ? CalendarTodayIcon : undefined}
          color={'primary'}
          renderValue={() =>
            period.label && isMobile
              ? period.label
              : period.label
              ? `Custom period`
              : `${period.startDate} - ${period.endDate}`
          }
          displayEmpty
          open={rangePickerOpen}
          ////disabled={!calculated}
          onOpen={() => setRangePickerOpen(!rangePickerOpen)}
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
              dense: true,
            },
          }}
        >
          <DateRangePicker
            initialDateRange={period}
            open={true}
            // If it's after 7AM in Europe/Vilnius (EEST), then last available day is yesterday, else it's day before yesterday
            maxDate={
              parseInt(moment.tz('Europe/Vilnius').format('HH')) > 7
                ? moment().subtract(1, 'day')
                : moment().subtract(2, 'days')
            }
            definedRanges={
              !isMobile
                ? [
                    // {
                    //   label: 'Yesterday',
                    //   startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                    //   endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                    // },
                    {
                      label: 'Last week',
                      startDate: moment()
                        .subtract(1, 'week')
                        .startOf('isoWeek')
                        .format('YYYY-MM-DD'),
                      endDate: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'),
                    },
                    {
                      label: 'Last 7 days',
                      startDate: moment(startingDay).subtract(7, 'days').format('YYYY-MM-DD'),
                      endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
                    },
                    {
                      label: 'Last 30 days',
                      startDate: moment(startingDay).subtract(31, 'days').format('YYYY-MM-DD'),
                      endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
                    },
                    {
                      label: 'This month',
                      startDate: moment(startingDay).startOf('month').format('YYYY-MM-DD'),
                      endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
                    },
                    {
                      label: 'Last month',
                      startDate: moment()
                        .subtract(1, 'months')
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                      endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
                    },
                    {
                      label: 'This year',
                      startDate: moment(startingDay).startOf('year').format('YYYY-MM-DD'),
                      endDate: moment(startingDay)
                        .subtract(1, 'day')
                        .endOf('month')
                        .format('YYYY-MM-DD'),
                    },
                    {
                      label: 'Last year',
                      startDate: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
                      endDate: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
                    },
                    {
                      label: 'All history',
                      startDate: moment(startingDay).subtract(10, 'year'),
                      endDate: moment(startingDay).subtract(1, 'day').format('YYYY-MM-DD'),
                    },
                  ]
                : []
            }
            toggle={() => setRangePickerOpen(!rangePickerOpen)}
            onChange={range => {
              changePeriod(range);
            }}
          />
        </Select>
      </ButtonBase>
    </>
  );
}
